import {
    FETCH_ENROLLMENTS_FOR_ATTENTION_FAILURE,
    FETCH_ENROLLMENTS_FOR_ATTENTION_REQUEST,
    FETCH_ENROLLMENTS_FOR_ATTENTION_SUCCESS
} from '../../actions/enrollment/fetchEnrollmentsForAttention';

const initialState = {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    payload: null,
    errorCode: null,
};

export default function fetchEnrollmentsForAttention(state = initialState, action) {

    switch (action.type) {
    case FETCH_ENROLLMENTS_FOR_ATTENTION_REQUEST:
        return {
            ...state,
            isFetching: true,
            isFetched: false,
            isFailed: false,
            payload: null,
            errorCode: null,
        };

    case FETCH_ENROLLMENTS_FOR_ATTENTION_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetched: true,
            isFailed: false,
            payload: action.payload,
            errorCode: null,
        };

    case FETCH_ENROLLMENTS_FOR_ATTENTION_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            isFailed: true,
            payload: null,
            errorCode: action.errorCode,
        };
    default:
        return state;
    }
}