import React from 'react';
import { Message, Segment } from 'semantic-ui-react';

const RegistrationSuccess = () => {

    return (
        <Segment>
            <Message success id='register-success'>
                <Message.Header>Вітаємо, реєстрація пройшла успішно!</Message.Header>
                <p>На вказану адресу електронної пошти ми надіслали вам лист.</p>
                <p>Будь ласка, відкрийте свою пошту для завершення реєстрації.</p>
            </Message>
        </Segment>
    );
};

export default RegistrationSuccess;