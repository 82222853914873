import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Divider, Header, Icon, Input, List, Popup, Responsive, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import t from '../../services/Translate';
import Stats from '../Stats';
import { isRegionAdmin } from '../../services/AuthService';
import PropTypes from 'prop-types';

/**
 * @property activeRegularEnrollmentsCount
 * @property activePrivilegedEnrollmentsCount
 * @property confirmedRegularEnrollmentsCount
 * @property confirmedPrivilegedEnrollmentsCount
 */
class LocationListAdminSection extends Component {

    state = { search: '' };

    render() {

        const { search } = this.state;
        const { locations } = this.props;

        let filteredLocations;

        if (!_.isEmpty(search)) {
            filteredLocations = _.filter(locations, (location) => {
                const searchLower = search.toLowerCase();

                const nameLower = !_.isEmpty(location.name) ? location.name.toLowerCase() : '';
                const nameMatches = nameLower.includes(searchLower);

                const descLower = !_.isEmpty(location.description) ? location.description.toLowerCase() : '';
                const descMatches = descLower.includes(searchLower);

                return nameMatches || descMatches;
            });
        } else {
            filteredLocations = locations;
        }

        const locationsFound = filteredLocations && filteredLocations.length > 0;

        return (
            <>
                <Header as='h2'>{t('locations')}</Header>
                <Divider/>

                <Input placeholder='Пошук...' onChange={(_, { value }) => this.setState( { search: value })}/>

                {!locationsFound &&
                <Header as='h4'>{t('locations')} не знайдено</Header>
                }

                {locationsFound &&
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Назва</Table.HeaderCell>
                            <Table.HeaderCell>Опис</Table.HeaderCell>
                            {isRegionAdmin() &&
                            <Table.HeaderCell>Адміністратор(и)</Table.HeaderCell>
                            }
                            <Responsive
                                as={Table.HeaderCell}
                                {...Responsive.onlyComputer}
                                width={2}>В черзі</Responsive>
                            <Responsive
                                as={Table.HeaderCell}
                                {...Responsive.onlyComputer}>Підтверджено</Responsive>
                            <Table.HeaderCell width={2}>Операції</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {_.map(filteredLocations, ({ id, name, description, stats, needsUpdating, emails }) => (
                            <Table.Row key={id} warning={needsUpdating}>
                                <Table.Cell>
                                    <Link to={`/admin/locations/${id}`}>{name}</Link>
                                    {needsUpdating &&
                                    <Popup
                                        trigger={<Icon name='exclamation circle' color='orange'/>}
                                        content='Є зміни'/>
                                    }
                                </Table.Cell>
                                <Table.Cell>{description}</Table.Cell>
                                {isRegionAdmin() &&
                                <Table.Cell><List items={emails}/></Table.Cell>
                                }
                                <Responsive
                                    as={Table.Cell}
                                    {...Responsive.onlyComputer}>
                                    <Stats
                                        regularCount={stats.activeRegularEnrollmentsCount}
                                        regularText='Всього дітей в черзі які очікують прийняття'
                                        privilegedCount={stats.activePrivilegedEnrollmentsCount}
                                        privilegedText='Всього дітей в черзі які очікують прийняття та мають пільгу'
                                    />
                                </Responsive>
                                <Responsive
                                    as={Table.Cell}
                                    {...Responsive.onlyComputer}>
                                    <Stats
                                        regularCount={stats.confirmedRegularEnrollmentsCount}
                                        regularText='Всього дітей прийнято'
                                        privilegedCount={stats.confirmedPrivilegedEnrollmentsCount}
                                        privilegedText='Всього дітей прийнято за пільгою'
                                    />
                                </Responsive>
                                <Table.Cell>
                                    <Link
                                        to={`/admin/locations/${id}`}>
                                        <Icon name='edit outline'/>редагувати
                                    </Link>
                                </Table.Cell>
                            </Table.Row>
                        ))
                        }
                    </Table.Body>
                </Table>
                }
            </>
        );
    }
}

LocationListAdminSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    locations: PropTypes.array.isRequired,
};

export default connect()(LocationListAdminSection);
