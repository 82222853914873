// react
import React, { Component } from 'react';
// semantic
import { Button, Header, Icon, Tab, Table } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';
// utils
import t from '../../services/Translate';
// components
import ApplyToGroupModal from '../enrollment/ApplyToGroupModal';
import { fetchGroupsByLocationId } from '../../actions/group/fetchGroupsByLocationId';
import LargeDimmer from '../helpers/Dimmer';

const initialState = {
    selectedGroup: {},
    isDialogOpen: false,
};

/**
 * @property numberOfConfirmedRegularChildren
 * @property group.numberOfConfirmedPrivilegedChildren
 * @property group.numberOfEnrollments
 */

class LocationViewGroupListUserSection extends Component {

    state = { ...initialState };

    componentDidMount() {
        this.fetchGroups();
    }

    fetchGroups = () =>
        this.props.dispatch(fetchGroupsByLocationId(this.props.location.id));

    onSelectGroup = (group) =>
        this.setState({
            selectedGroup: group,
            isDialogOpen: true,
        });

    onCancelEnroll = () => {
        this.setState({ ...initialState });

        this.fetchGroups();
    };

    render() {

        const { groups, location, isFetching, isFetched } = this.props;

        const enabledGroups = groups.filter(group => group.enabled);

        if (isFetching) {
            return (<LargeDimmer text="Завантаження даних"/>);
        }

        if (isFetched && _.isEmpty(enabledGroups)) {
            return (<Header as='h4'>{t('noGroups')}, зверніться будь ласка за інформацією до адміністрації навчального закладу.</Header>);
        }

        const { selectedGroup, isDialogOpen } = this.state;

        return (
            <>
                <ApplyToGroupModal
                    group={selectedGroup}
                    location={location}
                    isDialogOpen={isDialogOpen}
                    onCancelEnroll={this.onCancelEnroll}
                />

                <Tab.Pane>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('groupName')}</Table.HeaderCell>
                                <Table.HeaderCell>Роки</Table.HeaderCell>
                                <Table.HeaderCell>{t('groupSize')}</Table.HeaderCell>
                                <Table.HeaderCell>Подано заяв</Table.HeaderCell>
                                <Table.HeaderCell>Прийнято заяв</Table.HeaderCell>
                                <Table.HeaderCell/>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {enabledGroups.map(group => {
                                const confirmedCountTotal = group.numberOfConfirmedRegularChildren
                                    + group.numberOfConfirmedPrivilegedChildren;

                                const outstanding = group.maxChildren - confirmedCountTotal;

                                return (
                                    <Table.Row key={group.id}>
                                        <Table.Cell textAlign='left'>
                                            <Icon name='users'/> {group.name}
                                            {group.restricted &&
                                            <div>Набір з {group.dateStart} по {group.dateEnd}</div>
                                            }
                                        </Table.Cell>
                                        <Table.Cell
                                            textAlign='left'>{group.period} — {group.period + 1}</Table.Cell>
                                        <Table.Cell textAlign='left'>
                                            <b>всього {group.maxChildren}</b> з
                                            яких {group.maxPrivilegedChildren} пільгових місць,
                                            <b> ще вільно {outstanding} місць </b>
                                        </Table.Cell>
                                        <Table.Cell textAlign='left'>
                                            <b>{group.numberOfEnrollments}</b> з
                                            яких {group.numberOfEnrollments - confirmedCountTotal} очікують
                                        </Table.Cell>
                                        <Table.Cell textAlign='left'>
                                            {confirmedCountTotal}&nbsp;з яких&nbsp;
                                            {group.numberOfConfirmedPrivilegedChildren} за пільгою
                                        </Table.Cell>
                                        <Table.Cell textAlign='right'>
                                            {group.open &&
                                            <Button onClick={() => this.onSelectGroup(group)}>Подати заявку</Button>
                                            }
                                            {!group.open && 'Набір припинений'}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Tab.Pane>

            </>

        );
    }
}

LocationViewGroupListUserSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state.fetchGroupsByLocationId;
}

export default connect(mapStateToProps)(LocationViewGroupListUserSection);