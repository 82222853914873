// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// libs
import PropTypes from 'prop-types';
// semantic
import { Button, Form, Header, Label, Message, Modal } from 'semantic-ui-react';
// actions
import foodPrivilegeEnrollmentActions from '../../../actions/food-privilege-enrollment';
// services
// components
import LargeDimmer from '../../helpers/Dimmer';
import FoodPrivilegeDropdown from './FoodPrivilegeDropdown';
import DatePicker from 'react-datepicker';
import { DATE_PICKER_FORMAT } from '../../../utils/settings';
import uk from 'date-fns/locale/uk';
import { getFieldError, isFieldError, PRIVILEGE_DATE_CONFLICT } from '../../../utils/validation';
import { formatDateToLocalDateString } from '../../../utils/dateTime';

const initialState = {
    privilegeId: null,
    dateStart: null,
    dateEnd: null,
};

class AddFoodPrivilegeDialog extends Component {

    state = initialState;

    componentDidUpdate(prevProps) {
        const { isSaved } = this.props;

        if (isSaved && !prevProps.isSaved) {
            this.onComplete();
        }
    }

    reset = () => {
        this.setState(initialState);
        this.props.dispatch(foodPrivilegeEnrollmentActions.reset());
    };

    onComplete = () => {
        this.reset();
        this.props.onComplete();
    };

    onCancel = () => {
        this.reset();
        this.props.onClose();
    };

    handleSubmitClick = () => {
        const enrollmentId = this.props.enrollment.id;
        const privilegeId = this.state.privilegeId;

        const dateStart = formatDateToLocalDateString(this.state.dateStart);
        const dateEnd = formatDateToLocalDateString(this.state.dateEnd);

        this.props.dispatch(foodPrivilegeEnrollmentActions.save({ enrollmentId, privilegeId, dateStart, dateEnd }));
    };

    handleChange = (event, { name, value }) => {
        this.setState({ [name]: value });
    };

    onDateStartChange = dateStart => {
        this.setState({ dateStart });
    };

    onDateEndChange = dateEnd => {
        this.setState({ dateEnd });
    };

    render() {

        const { isSaving, isOpened, enrollment } = this.props;

        return (
            <Modal size='small' open={isOpened} centered={false}>

                {isSaving && <LargeDimmer text='Збереження даних'/>}

                <Modal.Header content='Додати пільгу на харчування'/>
                <Modal.Content>
                    <Header as='h4'>
                        {enrollment.lastName + ' ' + enrollment.firstName + ' ' + enrollment.middleName}
                    </Header>
                    <Form>
                        <Form.Field>
                            <label>Пільга на харчування</label>
                            <FoodPrivilegeDropdown
                                regionId={enrollment.location.regionId}
                                onChange={this.handleChange}
                            />
                            {isFieldError(this.props, 'privilegeId') &&
                            <>
                                <br/>
                                <Label color='red' pointing>{getFieldError(this.props, 'privilegeId')}</Label>
                            </>
                            }
                        </Form.Field>
                        <Form.Field>
                            <label>Початок дії</label>
                            <DatePicker
                                dateFormat={DATE_PICKER_FORMAT}
                                selected={this.state.dateStart}
                                onChange={this.onDateStartChange}
                                locale={uk}
                            />
                            {isFieldError(this.props, 'dateStart') &&
                            <>
                                <br/>
                                <Label color='red' pointing>{getFieldError(this.props, 'dateStart')}</Label>
                            </>
                            }
                        </Form.Field>
                        <Form.Field>
                            <label>Закінчення дії</label>
                            <DatePicker
                                dateFormat={DATE_PICKER_FORMAT}
                                selected={this.state.dateEnd}
                                onChange={this.onDateEndChange}
                                locale={uk}
                            />
                            {isFieldError(this.props, 'dateEnd') &&
                            <>
                                <br/>
                                <Label color='red' pointing>{getFieldError(this.props, 'dateEnd')}</Label>
                            </>
                            }
                        </Form.Field>
                    </Form>
                    {this.props.errorCode && this.props.errorCode === PRIVILEGE_DATE_CONFLICT &&
                    <Message negative>Період конфліктує з існуючим періодом пільги</Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.handleSubmitClick}
                        content='Зберегти'
                        icon='plus'
                        labelPosition='left'
                        primary
                    />
                    <Button
                        onClick={this.onCancel}
                        content='Відміна'
                        icon='plus'
                        labelPosition='left'
                        secondary
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

AddFoodPrivilegeDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    enrollment: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return state.foodPrivilegeEnrollment;
}

export default connect(mapStateToProps)(AddFoodPrivilegeDialog);