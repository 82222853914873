// react
import React, { Component } from 'react';
// libs
import Toast from '../helpers/Toasts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Label, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
// actions
import { createEnrollmentReset, doCreateEnrollment } from '../../actions/enrollment/enrollmentCreate';
// components
import ChildFormComponent from '../child/ChildFormComponent';
import LargeDimmer from '../helpers/Dimmer';
import {
    enrollmentUpdateError,
    getConflictingEnrollmentDetailsFromResponse,
    getFieldError,
    isEnrollmentUpdateError,
    isFieldError
} from '../../utils/validation';
import { Redirect } from 'react-router-dom';
import GroupDropdownSection from '../group/GroupDropdownSection';
import Notification from '../helpers/Notification';
import { DATE_PICKER_FORMAT } from '../../utils/settings';
import { getParentEmailField } from '.';
import { DEFAULT_CHILD_STATE } from '../child';

class EnrollmentCreate extends Component {

    state = {
        id: null,
        created: null,
        groupId: null,
        prioritized: null,
        ...DEFAULT_CHILD_STATE,
    };

    componentWillUnmount() {
        this.props.dispatch(createEnrollmentReset());
    }

    componentDidMount() {
        const { match } = this.props;

        if (match.params.groupId) {
            const groupId = parseInt(match.params.groupId, 10);
            this.setState({ groupId });
        }

        this.setState({ created: new Date() });
    }

    componentDidUpdate(prevProps) {

        const { isCreated } = this.props;

        if (isCreated && !prevProps.isCreated) {
            Toast('success', 'Заявка успішно додана!');
        }
    }

    onCreatedDateChange = created => {
        this.setState({ created });
    };

    onDateChange = birthDate => {
        this.setState({ birthDate });
    };

    onChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    onCreate = () => {
        this.props.dispatch(doCreateEnrollment({ ...this.state }));
    };

    render() {
        const props = this.props;
        const { isCreating, isCreated, createdEnrollment, match } = props;

        if (isCreated) {
            return (<Redirect
                to={`/admin/locations/${createdEnrollment.locationId}/enrollments/${createdEnrollment.id}`}/>);
        }

        if (isCreating) {
            return (<LargeDimmer text='Збереження даних'/>);
        }

        return (
            <>
                {isEnrollmentUpdateError(props) &&
                <Notification.Error content={
                    <>
                        <div>{enrollmentUpdateError(props)}</div>
                        <div>{getConflictingEnrollmentDetailsFromResponse(props)}</div>
                    </>
                }/>
                }
                <Segment>
                    <ChildFormComponent
                        headerText='Створення заявки'
                        submitButtonText='Зберегти'
                        onSubmit={this.onCreate}
                        onDateChange={this.onDateChange}
                        onChange={this.onChange}
                        parentEmail={getParentEmailField(this)}
                        value={{ ...this.state }}
                        {...props}>
                        <Form.Field required={true}>
                            <label>Дата створення заявки</label>

                            <DatePicker
                                placeholderText='день-місяць-рік'
                                dateFormat={DATE_PICKER_FORMAT}
                                selected={this.state.created}
                                onChange={this.onCreatedDateChange}
                                locale={uk}/>

                            {isFieldError(props, 'created') &&
                            <>
                                <br/>
                                <Label color='red' pointing>{getFieldError(this.props, 'created')}</Label>
                            </>
                            }
                        </Form.Field>

                        <GroupDropdownSection
                            onChange={this.onChange}
                            locationId={parseInt(match.params.locationId, 10)}
                            groupId={this.state.groupId}
                            disabled={false}
                        />
                        {isFieldError(this.props, 'groupId') &&
                        <Label color='red' pointing>{getFieldError(this.props, 'groupId')}</Label>}

                    </ChildFormComponent>
                </Segment>
            </>
        );

    }
}

EnrollmentCreate.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(store) {
    return store.enrollmentCreate;
}

export default connect(mapStateToProps)(EnrollmentCreate);