import {
    START_GET_LOCATION,
    COMPLETE_GET_LOCATION,
    FAIL_GET_LOCATION
} from '../../actions/location/locationView';

const initialState = {
    isFetching: false,
    isFetchFailed: false,
    isFetched: false,
    errorCode: null,
    location: null
};

export default function locationView(state = initialState, action) {

    switch (action.type) {
    case START_GET_LOCATION:
        return { ...initialState };
    case COMPLETE_GET_LOCATION:
        return {
            isFetched: true,
            location: action.location,
        };
    case FAIL_GET_LOCATION:
        return {
            isFetchFailed: true,
            errorCode: action.errorCode
        };
    default:
        return state;
    }
}