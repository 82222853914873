// react
import React, { Component } from 'react';
// semantic
import { Header, Tab, } from 'semantic-ui-react';
// libs
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
// actions
import { doGetLocation } from '../../actions/location/locationView';
// components
import LocationViewInfoUserSection from './LocationViewInfoUserSection';
import LocationViewGroupListUserSection from './LocationViewGroupListUserSection';
import t from '../../services/Translate';
import LargeDimmer from '../helpers/Dimmer';
import Notification from '../helpers/Notification';

class LocationView extends Component {

    componentDidMount() {
        this.props.dispatch(doGetLocation(this.getLocationId()));
    }

    getLocationId = () => this.props.match.params.id;

    getPanes = () => {
        return [
            {
                menuItem: t('locationGroups'),
                render: () => <LocationViewGroupListUserSection location={this.props.location}/>
            },
            {
                menuItem: 'Детальна інформація',
                render: () => <LocationViewInfoUserSection location={this.props.location}/>
            },
        ];
    };

    render() {

        const { isFetching, isFetchFailed, isFetched, location } = this.props;

        if (isFetchFailed) {
            return <Notification.Error/>;
        }

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (!isFetched) {
            return null;
        }

        return (

            <>
                <Header as='h2'>{location.name}</Header>
                <Tab panes={this.getPanes()}/>
            </>
        );
    }
}

LocationView.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.locationView;
}

export default connect(mapStateToProps)(LocationView);