// react
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// semantic
import { Button, Confirm, Header, Segment } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// actions
import { doDeleteGroup } from '../../actions/group/deleteGroup';
import Toast from '../helpers/Toasts';
import { isGroupNotEmpty } from '../../utils/validation';
import t from '../../services/Translate';
import LargeDimmer from '../helpers/Dimmer';

class DeleteGroup extends Component {

    state = { confirmOpen: false };

    componentDidUpdate(prevProps) {

        const { isDeleteFailed, isDeleted, group } = this.props;

        if(isDeleteFailed && !prevProps.isDeleteFailed) {
            if (isGroupNotEmpty(this.props)) {
                Toast('error', 'Помилка, ще існують активні заявки');
            } else {
                Toast('error', 'Помилка');
            }
            this.onClose();
        }

        if(isDeleted && !prevProps.isDeleted) {
            this.props.history.push('/admin/locations/' + group.locationId);
        }
    }

    onDelete = () =>
        this.props.dispatch(doDeleteGroup(this.props.group.id));

    onConfirmDelete = () =>
        this.setState({ confirmOpen:  true});

    onClose = () =>
        this.setState({ confirmOpen:  false});

    render() {

        const { isDeleting } = this.props;

        if (isDeleting) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        return (
            <Segment>
                <Confirm
                    content='Цю операцію не можна скасувати, ви впевнені?'
                    cancelButton='Ні'
                    confirmButton="Видалити"
                    open={this.state.confirmOpen}
                    onConfirm={this.onDelete}
                    onCancel={this.onClose}/>
                <Header as='h2'>{t('deleteGroup')}</Header>
                <Button onClick={this.onConfirmDelete} basic color='red'>Видалити</Button>
            </Segment>
        );
    }
}

DeleteGroup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    group: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state.deleteGroup;
}

export default withRouter(connect(mapStateToProps)(DeleteGroup));