import axios from 'axios';
import { REQUEST_SETTINGS } from '../utils/settings';
import { clearAccountDetails } from './accountDetails';

export const START_LOGOUT = 'START_LOGOUT';
export const COMPLETE_LOGOUT = 'COMPLETE_LOGOUT';
export const FAIL_LOGOUT = 'FAIL_LOGOUT';
export const REDIRECT = 'REDIRECT';
export const REDIRECTED = 'REDIRECTED';

function startLogout() {
    return { type: START_LOGOUT };
}

function completeLogout() {
    return { type: COMPLETE_LOGOUT };
}

function failLogout() {
    return { type: FAIL_LOGOUT };
}

export function doLogout(history) {

    return async function (dispatch) {

        dispatch(startLogout());

        return axios
            .post('/api/logout', {}, REQUEST_SETTINGS)
            .then(() => {
                dispatch(clearAccountDetails());
                dispatch(completeLogout(dispatch));
                history.push('/');
            })
            .catch(() => dispatch(failLogout()));
    };
}

export function startRedirect() {

    return async function (dispatch) {
        dispatch(clearAccountDetails());
        dispatch({ type: REDIRECT });
    };
}

export function completeRedirect(history) {
    return async function (dispatch) {
        dispatch(clearAccountDetails());
        dispatch({ type: REDIRECTED });
        if(history.location.pathname !== '/login') {
            history.push('/login');
        }
    };
}