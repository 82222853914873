import { toast } from 'react-toastify';

const defaultConfig = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

const Toast = (type = 'success', description = '', config = defaultConfig) => {
    toast[type](`${description}`, { ...config });
};

export default Toast;