import store from '../store';

export const ROLE_USER = 'USER';
export const ROLE_LOCATION_ADMIN = 'LOCATION_ADMIN';
export const ROLE_REGION_ADMIN = 'REGION_ADMIN';
export const ROLE_EDITOR = 'ROLE_EDITOR';
export const ROLE_GROUP_ADMIN = 'GROUP_ADMIN';

const CREATE_GROUP_PERMISSION_REGION_ADMIN = 'REGION_ADMIN';
const CREATE_GROUP_PERMISSION_LOCATION_ADMIN = 'LOCATION_ADMIN';
const CREATE_GROUP_PERMISSION_ALL = 'ALL';

function getAccountDetails() {
    return store.getState().accountDetails;
}

function getRole() {
    return store.getState().accountDetails.role;
}

export function isAuthenticated() {
    return getAccountDetails().authenticated;
}

export function isInRole(roles) {
    return isAuthenticated() && roles.includes(getRole());
}

export function isLocationAdmin() {
    return isInRole(ROLE_LOCATION_ADMIN);
}

export function isGroupAdmin() {
    return isInRole(ROLE_GROUP_ADMIN);
}

export function isRegionAdmin() {
    return isInRole(ROLE_REGION_ADMIN);
}

export function isUser() {
    return isInRole(ROLE_USER);
}

export function isEditor() {
    return isInRole(ROLE_EDITOR);
}

export function canCreateGroup(createGroupPermission) {

    if (CREATE_GROUP_PERMISSION_REGION_ADMIN === createGroupPermission && isRegionAdmin()) {
        return true;
    }

    if (CREATE_GROUP_PERMISSION_LOCATION_ADMIN === createGroupPermission && isLocationAdmin()) {
        return true;
    }

    if (CREATE_GROUP_PERMISSION_ALL === createGroupPermission && (isLocationAdmin() || isRegionAdmin())) {
        return true;
    }

    return false;
}