import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { getResponseData } from '../utils';
import _ from 'lodash';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const CREATE_GROUP_RESET = 'CREATE_GROUP_RESET';


export function doCreateGroup({ locationId, name, period, maxChildren, maxPrivilegedChildren, groupAgeId }) {

    const group = { locationId, name, period, maxChildren, maxPrivilegedChildren, groupAgeId };

    return async function (dispatch) {

        dispatch(createGroupRequest(group));

        return axios
            .post('/api/groups', _.pickBy(group, _.identity), REQUEST_SETTINGS)
            .then(() => dispatch(createGroupSuccess(group)))
            .catch(error => dispatch(createGroupFailure(error)));
    };

    function createGroupRequest() {
        return { type: CREATE_GROUP_REQUEST };
    }

    function createGroupSuccess(group) {
        return { type: CREATE_GROUP_SUCCESS, group };
    }

    function createGroupFailure(res) {

        const { errorCode, fieldErrors } = getResponseData(res);

        return { type: CREATE_GROUP_FAILURE, errorCode, fieldErrors };
    }
}

export function doResetGroupCreation() {
    return { type: CREATE_GROUP_RESET };
}