import { ADD_ATTACHMENT, DELETE_ATTACHMENT, FETCH_ATTACHMENTS } from './attachments-action';
import { FAILURE, REQUEST, SUCCESS } from '../../shared/action-type';
import { IAttachment } from '../../shared/attachment.model';
import { Action, State } from '../../shared/store.model';

const initialState: State<IAttachment> = {
    loading: false,
    completed: false,
    entities: [],
    errorCode: null,
};

export default (state: State<IAttachment> = initialState,
                action: Action<IAttachment>): State<IAttachment> => {
    switch (action.type) {
        case REQUEST(ADD_ATTACHMENT):
        case REQUEST(FETCH_ATTACHMENTS):
        case REQUEST(DELETE_ATTACHMENT):
            return {
                ...state,
                loading: true,
                completed: false,
                errorCode: null,
                actionType: action.type,
            };
        case SUCCESS(FETCH_ATTACHMENTS):
        case SUCCESS(ADD_ATTACHMENT):
        case SUCCESS(DELETE_ATTACHMENT):
            return {
                ...state,
                loading: false,
                completed: true,
                entities: action.entities,
                errorCode: null,
                actionType: action.type,
            };
        case FAILURE(FETCH_ATTACHMENTS):
        case FAILURE(ADD_ATTACHMENT):
        case FAILURE(DELETE_ATTACHMENT):
            return {
                ...state,
                loading: false,
                completed: false,
                errorCode: action.errorCode,
                actionType: action.type,
            };
        default:
            return state;
    }
}