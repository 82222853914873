import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import foodFactsReportActions from '../../actions/presence-facts/food-report';
import FactReportFilter from './FactReportFilter';
import { formatDateToLocalDateString } from '../../utils/dateTime';
import FoodFactReportTable from './FoodFactReportTable';
import DownloadFoodReport from './DownloadFoodReport';

class FoodFactReportView extends Component {

    state = {
        dateFrom: '',
        dateTo: '',
        subRegionIds: [],
        locationIds: [],
        groupIds: [],
        groupAgeIds: [],
    };

    getParams() {
        const { dateFrom, dateTo, page } = this.state;
        return {
            regionId: this.props.regionId,
            ...this.state,
            dateFrom: formatDateToLocalDateString(dateFrom),
            dateTo: formatDateToLocalDateString(dateTo),
            page: page || 1
        };
    }

    fetchFoodFactsReport() {
        this.props.dispatch(foodFactsReportActions.fetchAll(this.getParams()));
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.fetchFoodFactsReport();
    }

    onFilterUpdated = filter => {
        this.setState(filter);
    };

    onPageChanged = page => {
        this.setState({ page });
    };

    render() {
        const { regionId } = this.props;
        return (
            <>
                <FactReportFilter regionId={regionId} onFilterUpdated={this.onFilterUpdated} isFoodReport/>
                <FoodFactReportTable onPageChanged={this.onPageChanged}/>
                <DownloadFoodReport params={this.getParams()}/>
            </>
        );
    }
}

FoodFactReportView.propTypes = {
    dispatch: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
};

export default connect()(FoodFactReportView);