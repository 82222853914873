import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';

export const FETCH_GROUPS_BY_LOCATION_ID_REQUEST = 'FETCH_GROUPS_BY_LOCATION_ID_REQUEST';
export const FETCH_GROUPS_BY_LOCATION_ID_SUCCESS = 'FETCH_GROUPS_BY_LOCATION_ID_SUCCESS';
export const FETCH_GROUPS_BY_LOCATION_ID_FAILURE = 'FETCH_GROUPS_BY_LOCATION_ID_FAILURE';

export function fetchGroupsByLocationId(locationId) {

    return async function (dispatch) {

        dispatch(fetchGroupsByLocationIdRequest());

        return axios
            .get(`/api/locations/${locationId}/groups`, createGetParams())
            .then(res => dispatch(fetchGroupsByLocationIdSuccess(res)))
            .catch(error => dispatch(fetchGroupsByLocationIdFailure(error)));
    };

    function fetchGroupsByLocationIdRequest() {
        return { type: FETCH_GROUPS_BY_LOCATION_ID_REQUEST };
    }

    function fetchGroupsByLocationIdSuccess(res) {
        return { type: FETCH_GROUPS_BY_LOCATION_ID_SUCCESS, groups: res.data };
    }

    function fetchGroupsByLocationIdFailure(error) {

        const { errorCode } = getResponseData(error);

        return { type: FETCH_GROUPS_BY_LOCATION_ID_FAILURE, errorCode };
    }
}