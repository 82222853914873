import {
    createFetchEntityAction,
    createResetEntityAction,
    createSaveEntityAction,
    createUpdateEntityAction
} from '../generic/entityAction';

export const LOCATION = 'LOCATION';

export default {
    fetchLocation: createFetchEntityAction(locationId => `/api/locations/${locationId}`, LOCATION),
    saveLocation: createSaveEntityAction(() => '/api/locations', LOCATION),
    updateLocation: createUpdateEntityAction(() => '/api/locations', LOCATION),
    reset: createResetEntityAction(LOCATION),
};