// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// libs
import PropTypes from 'prop-types';
// semantic
import { Button, Form, Label, Modal } from 'semantic-ui-react';
// actions
import locationActions from '../../../actions/location';
// services
import t from '../../../services/Translate';
// components
import LargeDimmer from '../../helpers/Dimmer';
import { getFieldError, isFieldError } from '../../../utils/validation';
import SubRegionDropdown from '../../subregion/SubRegionDropdown';

const initialState = {
    name: '',
    description: '',
    subRegionId: null,
};

class CreateLocationDialog extends Component {

    state = initialState;

    componentDidUpdate(prevProps) {
        const { isSaved } = this.props;

        if (isSaved && !prevProps.isSaved) {
            this.onComplete();
        }
    }

    reset = () => {
        this.setState(initialState);
        this.props.dispatch(locationActions.reset());
    }

    onComplete = () => {
        this.reset();
        this.props.onComplete();
    };

    onCancel = () => {
        this.reset();
        this.props.onClose();
    };

    handleSubmitClick = event => {
        event.preventDefault();

        const { regionId } = this.props;
        const { name, description, subRegionId } = this.state;
        this.props.dispatch(locationActions.saveLocation({ regionId, name, description, subRegionId }));
    };

    handleChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    render() {

        const { isSaving, regionId, isOpened } = this.props;
        const { name, description, subRegionId } = this.state;

        return (
            <Modal size='small' open={isOpened} centered={false}>

                {isSaving && <LargeDimmer text='Збереження даних'/>}

                <>
                    <Modal.Header content={t('createLocation')}/>
                    <Modal.Content>
                        <Form>
                            <Form.Input
                                label={t('locationName')}
                                placeholder={t('locationName')}
                                onChange={this.handleChange}
                                name='name'
                                value={name}
                                error={isFieldError(this.props, 'name')}
                            />
                            {isFieldError(this.props, 'name') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'name')}</Label>}

                            <SubRegionDropdown
                                regionId={regionId}
                                value={subRegionId}
                                onChange={this.handleChange}/>

                            <Form.TextArea
                                label={t('locationDescription')}
                                placeholder={t('locationDescription')}
                                onChange={this.handleChange}
                                name='description'
                                value={description}
                                error={isFieldError(this.props, 'description')}
                            />
                            {isFieldError(this.props, 'description') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'description')}</Label>}
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={this.handleSubmitClick}
                            content={t('createLocation')}
                            icon='plus'
                            labelPosition='left'
                            primary
                        />
                        <Button
                            onClick={this.onCancel}
                            content='Відміна'
                            icon='plus'
                            labelPosition='left'
                            secondary
                        />
                    </Modal.Actions>
                </>
            </Modal>
        );
    }
}

CreateLocationDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return state.location;
}

export default connect(mapStateToProps)(CreateLocationDialog);