import {
    UPDATE_ENROLLMENT_FAILURE,
    UPDATE_ENROLLMENT_REQUEST,
    UPDATE_ENROLLMENT_RESET,
    UPDATE_ENROLLMENT_SUCCESS,
} from '../../actions/enrollment/enrollmentStatusUpdate';

const initialState = {
    isUpdating: false,
    isUpdateFailed: false,
    isUpdated: false,
    errorCode: null,
};

export default function enrollmentStatusUpdate(state = initialState, action) {

    switch (action.type) {
    case UPDATE_ENROLLMENT_REQUEST:
        return {
            ...state,
            isUpdating: true,
            isUpdateFailed: false,
            isUpdated: false,
            errorCode: null,
        };
    case UPDATE_ENROLLMENT_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            isUpdateFailed: false,
            isUpdated: true,
            errorCode: null,
        };
    case UPDATE_ENROLLMENT_FAILURE:
        return {
            ...state,
            isUpdating: false,
            isUpdateFailed: true,
            isUpdated: false,
            errorCode: action.errorCode,
        };
    case UPDATE_ENROLLMENT_RESET:
        return initialState;
    default:
        return state;
    }
}