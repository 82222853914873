import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popup, Search } from 'semantic-ui-react';
import enrollmentSearchActions from '../../actions/enrollment/searchEnrollments';
import PropTypes from 'prop-types';

class EnrollmentSearch extends Component {

    state = {
        value: ''
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const value = this.state.value;
        const locationId = this.props.locationId;
        if (prevState.value !== value && value && value.length >= 3) {
            this.props.dispatch(enrollmentSearchActions.searchEnrollments({ phrase: value, locationId }));
        }
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ value });
    };

    handleValueChange = (e, data) => {
        const enrollmentId = data.result.id;
        const locationId = this.props.locationId;

        this.props.history.push(`/admin/locations/${locationId}/enrollments/${enrollmentId}`);
    };

    render() {
        const { payload, isSaving } = this.props;

        const isLoading = isSaving;

        const results = (payload || []).map(result => {
            return {
                id: result.id,
                title: result.lastName + ' ' + result.firstName + ' ' + result.middleName,
                description: result.documentId
            };
        });

        return (
            <Popup
                position='top right'
                trigger={<Search
                    loading={isLoading}
                    onResultSelect={this.handleValueChange}
                    onSearchChange={this.handleSearchChange}
                    results={results}
                    value={this.state.value}
                    minCharacters={3}
                    noResultsMessage='Нічого не знайдено'
                    aligned='right'
                />}
                content='Для пошуку введіть перші 3 літери прізвища або будь-яку частину номера свідоцтва про народження'/>

        );
    }
}

EnrollmentSearch.propTypes = {
    dispatch: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
    return state.enrollmentSearch;
}

export default withRouter(connect(mapStateToProps)(EnrollmentSearch));