import { COMPLETE_LOGIN, COMPLETE_PREFETCH, FAIL_LOGIN, START_LOGIN, START_PREFETCH, } from '../actions/login';

import { COMPLETE_LOGOUT, FAIL_LOGOUT, REDIRECT, REDIRECTED, START_LOGOUT } from '../actions/logout';

export default function authentication(state = {
    isFetching: false,
    isLoginFailed: false,
    isLoggedIn: false,
    isPreFetching: false,
    isPreFetched: false,
    blocked: false,
    errorCode: null,
}, action) {

    switch (action.type) {
    case START_PREFETCH:
        return {
            ...state,
            blocked: false,
            isPreFetching: true,
        };
    case COMPLETE_PREFETCH:
        return {
            ...state,
            isPreFetching: false,
            isPreFetched: true,
            blocked: action.blocked,
        };
    case START_LOGIN:
        return {
            ...state,
            isFetching: true,
            isLoginFailed: false,
            isLoggedIn: false,
            errorCode: null,
        };
    case COMPLETE_LOGIN:
        return {
            ...state,
            isFetching: false,
            isLoginFailed: false,
            isLoggedIn: true,
            errorCode: null,
        };
    case FAIL_LOGIN:
        return {
            ...state,
            isFetching: false,
            isLoginFailed: true,
            isLoggedIn: false,
            errorCode: action.errorCode,
        };
    case START_LOGOUT:
        return {
            ...state,
            isFetching: true,
            isLogoutFailed: false,
        };
    case FAIL_LOGOUT:
        return {
            ...state,
            isFetching: false,
            isLogoutFailed: true
        };
    case REDIRECT:
        return {
            shouldRedirect: true,
        };
    case COMPLETE_LOGOUT:
    case REDIRECTED:
        return {};
    default:
        return state;
    }
}