import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';
import { transform } from '.';

export const START_GET_CHILD = 'START_GET_CHILD';
export const COMPLETE_GET_CHILD = 'COMPLETE_GET_CHILD';
export const FAIL_GET_CHILD = 'FAIL_GET_CHILD';
export const RESET_GET_CHILD = 'RESET_GET_CHILD';

export function resetGetChild() {
    return { type: RESET_GET_CHILD };
}

function startGetChild() {
    return { type: START_GET_CHILD };
}

function completeGetChild(res) {
    return { type: COMPLETE_GET_CHILD, child: transform(res.data) };
}

function failGetChild(error) {

    const { errorCode } = getResponseData(error);

    return { type: FAIL_GET_CHILD, errorCode };
}

export function doGetChild(childUuid) {

    return async function (dispatch) {

        dispatch(startGetChild());

        return axios
            .get(`/api/children/${childUuid}`, createGetParams())
            .then(res => dispatch(completeGetChild(res)))
            .catch(error => dispatch(failGetChild(error)));
    };
}