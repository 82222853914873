import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { getChildFromState, getResponseData } from '../utils';
import { loadAccountDetails } from '../accountDetails';

export const START_ADD_CHILD = 'START_ADD_CHILD';
export const COMPLETE_ADD_CHILD = 'COMPLETE_ADD_CHILD';
export const FAIL_ADD_CHILD = 'FAIL_ADD_CHILD';
export const RESET_ADD_CHILD = 'RESET_ADD_CHILD';

export function resetAddChild() {
    return { type: RESET_ADD_CHILD };
}

function startAddChild() {
    return { type: START_ADD_CHILD };
}

function completeAddChild(res) {
    return { type: COMPLETE_ADD_CHILD, child: res.data};
}

function failAddChild(res) {

    const { errorCode, fieldErrors } = getResponseData(res);

    return { type: FAIL_ADD_CHILD, errorCode, fieldErrors };
}

export function doAddChild(state) {

    return async function (dispatch) {

        dispatch(startAddChild());

        return axios
            .post('/api/children', getChildFromState(state), REQUEST_SETTINGS)
            .then((res) => {
                dispatch(completeAddChild(res));
                dispatch(loadAccountDetails());
            })
            .catch(res => dispatch(failAddChild(res)));
    };
}