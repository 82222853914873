import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import LargeDimmer from '../helpers/Dimmer';
import Toast from '../helpers/Toasts';
import { fetchFetchUserEnrollments } from './enrollments-user-action';
import { Enrollment, EnrollmentStatus } from '../../shared/enrollment.model';
import { State } from '../../shared/store.model';
import { Dispatch } from 'redux';
import { formatTimestampToDateTimeString } from '../../utils/dateTime';
import { getEnrollmentStatusName } from '../../utils/enrollmentUtils';
import { EXPLORER_URL } from '../../utils/settings';
import EnrollmentStatusUpdate from '../enrollment/EnrollmentStatusUpdate';
import { CANCELLED } from '../../const/variables';
import { isUser } from '../../services/AuthService';
import { RoleType } from '../../shared/account.model';

interface EnrollmentProps extends State<Enrollment> {
    dispatch: Dispatch<any>
}

const isCancelButtonAvailable = (enrollment: Enrollment): boolean => {
    const { enrollmentStatus, createdBy } = enrollment;
    return enrollmentStatus !== EnrollmentStatus.CANCELLED &&
        enrollmentStatus !== EnrollmentStatus.REJECTED &&
        isUser() &&
        createdBy === RoleType.USER;
};

class UserEnrollments extends Component<EnrollmentProps> {

    fetchUserEnrollments = () => {
        this.props.dispatch(fetchFetchUserEnrollments());
    };

    componentDidMount() {
        this.fetchUserEnrollments();
    }

    componentDidUpdate(prevProps: EnrollmentProps) {
        const { errorCode } = this.props;

        if (errorCode && !prevProps.errorCode) {
            this.setState({ file: null });
            Toast('error', 'Помилка завантаження даних');
        }
    }

    render() {

        const { entities, loading } = this.props;

        if (!entities) {
            return null;
        }

        if (loading) {
            return (<LargeDimmer text='Завантаження даних'/>);
        }

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Номер</Table.HeaderCell>
                        <Table.HeaderCell>ПІБ</Table.HeaderCell>
                        <Table.HeaderCell>Навчальній заклад</Table.HeaderCell>
                        <Table.HeaderCell>Дата подачі</Table.HeaderCell>
                        <Table.HeaderCell>Статус</Table.HeaderCell>
                        <Table.HeaderCell>Номер в черзі</Table.HeaderCell>
                        <Table.HeaderCell>Дії</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {entities.map(enrollment => {
                        return (
                            <Table.Row key={enrollment.id}>
                                <Table.Cell>{enrollment.id}</Table.Cell>
                                <Table.Cell>
                                    {enrollment.lastName} {enrollment.firstName} {enrollment.middleName}
                                </Table.Cell>
                                <Table.Cell>
                                    {enrollment.locationName}, {enrollment.groupName}
                                    <br/>
                                    <a
                                        href={`${EXPLORER_URL}/#/events/key/${enrollment.uuid}`}
                                        target='_blank' rel='noopener noreferrer'>
                                        Перевірити на блокчейн
                                    </a>
                                </Table.Cell>
                                <Table.Cell>{formatTimestampToDateTimeString(enrollment.created)}</Table.Cell>
                                <Table.Cell>
                                    {getEnrollmentStatusName(enrollment.enrollmentStatus)}

                                    {enrollment.enrollmentStatus === EnrollmentStatus.REJECTED &&
                                        <>
                                            <br/>
                                            {enrollment.statusComment}
                                        </>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {enrollment.enrollmentStatus !== EnrollmentStatus.REJECTED &&
                                    enrollment.enrollmentStatus !== EnrollmentStatus.CANCELLED &&
                                    enrollment.order}
                                </Table.Cell>
                                <Table.Cell>
                                    {isCancelButtonAvailable(enrollment) &&
                                    <EnrollmentStatusUpdate
                                        enrollment={enrollment}
                                        enrollmentStatus={CANCELLED}
                                        onDone={this.fetchUserEnrollments}
                                        size='small'/>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}

function mapStateToProps(state: any) {
    return state.userEnrollments;
}

export default connect(mapStateToProps)(UserEnrollments);