import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { getResponseData } from '../utils';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';
export const SEND_EMAIL_RESET = 'SEND_EMAIL_RESET';

export function sendPasswordResetEmail(email) {

    return async function (dispatch) {

        dispatch(sendPasswordResetEmailRequest());

        return axios
            .post('/api/password-reset', email, REQUEST_SETTINGS)
            .then(res => dispatch(sendEmailSuccess(res)))
            .catch(res => dispatch(sendPasswordResetEmailFail(res)));
    };
}

function sendPasswordResetEmailRequest() {
    return { type: SEND_EMAIL_REQUEST };
}

function sendEmailSuccess() {
    return { type: SEND_EMAIL_SUCCESS };
}

function sendPasswordResetEmailFail(res) {

    const { errorCode, fieldErrors } = getResponseData(res);

    return { type: SEND_EMAIL_FAIL, errorCode, fieldErrors };
}

export function sendPasswordResetEmailReset() {
    return { type: SEND_EMAIL_RESET };
}
