import React from 'react';
import { Form, Icon, Label } from 'semantic-ui-react';
import { getFieldError, isFieldError } from '../../utils/validation';

export const getParentEmailField = (obj) => {
    return (
        <Form.Field>
            <label>Електронна адреса</label>

            <Form.Input
                name='parentEmail'
                value={obj.state.parentEmail}
                placeholder='Введіть адресу електронної пошти'
                error={isFieldError(obj.props, 'parentEmail')}
                iconPosition='left'
                onChange={obj.onChange}>
                <Icon name='at'/>
                <input/>
            </Form.Input>

            {isFieldError(obj.props, 'parentEmail') &&
            <Label color='red' pointing>{getFieldError(obj.props, 'parentEmail')}</Label>}

        </Form.Field>);
};