import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';

export const START_GET_LOCATION = 'START_GET_LOCATION';
export const COMPLETE_GET_LOCATION = 'COMPLETE_GET_LOCATION';
export const FAIL_GET_LOCATION = 'FAIL_GET_LOCATION';

function startGetLocation() {
    return { type: START_GET_LOCATION };
}

function completeGetLocation(res) {
    return { type: COMPLETE_GET_LOCATION, location: res.data };
}

function failGetLocation(res) {
    const { errorCode } = getResponseData(res);
    return { type: FAIL_GET_LOCATION, errorCode };
}

export function doGetLocation(locationId) {

    return async function (dispatch) {

        dispatch(startGetLocation());

        return axios
            .get(`/api/locations/${locationId}`, createGetParams())
            .then(res => dispatch(completeGetLocation(res)))
            .catch(error => dispatch(failGetLocation(error)));
    };
}