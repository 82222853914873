import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LargeDimmer from '../helpers/Dimmer';
import { Dropdown, Form, Icon, Popup, Segment } from 'semantic-ui-react';
import PresenceFacts from './PresenceFacts';
import presenceFactsActions from '../../actions/presence-facts';
import { formatDateToLocalDateString } from '../../utils/dateTime';
import t from '../../services/Translate';

class PresenceFactsView extends Component {

    state = {
        period: null,
        groupId: null,
    };

    fetchPresenceFacts(groupId) {
        this.props.dispatch(presenceFactsActions.fetchAll(groupId));
    }

    componentDidMount() {
        const locationId = this.props.locationId;
        this.props.dispatch(presenceFactsActions.fetchDimensions(locationId));
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.period !== prevState.period) {
            this.setState({ groupId: null });
        }

        const groupId = this.state.groupId;

        if (groupId && (groupId !== prevState.groupId || this.props !== prevProps)) {
            this.fetchPresenceFacts(groupId);
        }
    }

    handleChange = (event, { name, value }) => {
        event.preventDefault();

        this.setState({ [name]: value });
    };

    /**
     * @property periods
     * @property groups
     * @property presenceUpdateDate
     */
    render() {

        const { payload, isFetching, isFetched, locationId } = this.props;

        const { period, groupId } = this.state;

        if (isFetching || !isFetched) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (!payload) {
            return null;
        }

        const periods = payload.periods
            .map(periodObj => {
                const period = periodObj.value;
                return {
                    key: period,
                    value: period,
                    text: period + '-' + (period + 1),
                };
            });

        const now = formatDateToLocalDateString(new Date());

        const updatedGroups = payload.groups
            .filter(group => group.presenceUpdateDate === now)
            .map(group => group.id);

        const groups = payload.groups
            .filter(group => period && group.period === period)
            .map(group => {
                const icon = group.presenceUpdateDate === now ? 'check' : '';
                return {
                    key: group.id,
                    value: group.id,
                    text: group.name,
                    icon,
                };
            });

        return (
            <Segment>
                <Form>
                    <Form.Field>
                        <label>Період</label>
                        <Dropdown
                            name='period'
                            options={periods}
                            placeholder='Вкажіть період'
                            selection
                            fluid
                            value={period}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>
                            {t('group')} &nbsp;
                            {groupId && updatedGroups.includes(groupId) &&
                            <Popup content='Дані збережено' trigger={<Icon name='check' color='green'/>}/>
                            }
                            {groupId && !updatedGroups.includes(groupId) &&
                            <Popup content='Дані не збережено' trigger={<Icon name='warning' color='red'/>}/>
                            }
                        </label>
                        <Dropdown
                            name='groupId'
                            options={groups}
                            placeholder={t('selectGroup')}
                            selection
                            fluid
                            value={groupId}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form>

                {groupId && <PresenceFacts
                    locationId={locationId}
                    groupId={groupId}
                    foodFactTypes={payload.foodFactTypes}/>
                }
            </Segment>
        );
    }
}

PresenceFactsView.propTypes = {
    dispatch: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
    return state.foodReportDimensions;
}

export default connect(mapStateToProps)(PresenceFactsView);