import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header, Label, Segment } from 'semantic-ui-react';
import { resetPassword } from '../actions/resetPassword';
import { getToken } from '../utils/token';
import { isPasswordError, passwordError, } from '../utils/validation';
import Notification from './helpers/Notification';

const ACCOUNT_NOT_ENABLED = 'ACCOUNT_NOT_ENABLED';

class ResetPassword extends Component {

    state = {
        password: '',
        showPassword: false,
        repeatPassword: '',
        showRepeatedPassword: false,
        resetPasswordToken: null,
    };

    componentDidMount() {
        this.setState({
            resetPasswordToken: getToken(this.props.location)
        });
    }

    componentWillReceiveProps(nextProps) {
        const { isPasswordSent, isFetching } = nextProps;
        
        if(isPasswordSent && !isFetching) {
            nextProps.history.push('/login');
        }
    }

    sendPassword = (event) => {
        event.preventDefault();

        const {
            password,
            repeatPassword,
            resetPasswordToken
        } = this.state;
        
        if(resetPasswordToken) {
            this.props.dispatch(resetPassword({
                resetPasswordToken, password, repeatPassword
            }));
        }
    };

    accountNotEnabled = () => {
        const { errorCode } = this.props;

        if (errorCode === ACCOUNT_NOT_ENABLED) {
            return 'Посилання недійсне так як аккаунт було деактивовано, зверніться до адміністратора';
        } else {
            return '';
        }
    };

    isAccountNotEnabled = () => !!this.accountNotEnabled();

    handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    render() {
        const {
            showPassword,
            showRepeatedPassword,
            resetPasswordToken
        } = this.state;

        if (!resetPasswordToken) {
            return (
                <Notification.Error
                    header='Помилка посилання'
                    content='Ви не можете змінити пароль без діючого токена доступу'/>
            );
        } if (this.isAccountNotEnabled()) {
            return (
                <Notification.Error
                    header='Аккаунт деактивовано'
                    content={this.accountNotEnabled()}/>
            );
        } else {
            return (
                <Grid centered>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Segment>
                            <Header as='h2'>Зміна паролю</Header>

                            <Form
                                autoComplete='off'
                                onSubmit={event => event.preventDefault()}>
                                <Form.Field>
                                    <label>Введіть ваш новий пароль</label>
                                    <Form.Input
                                        labelPosition='right'
                                        id='password'
                                        name='password'
                                        error={isPasswordError(this.props)}
                                        onChange={this.handleChange}
                                        type={showPassword ? 'text' : 'password' }>
                                        <input />
                                        <Form.Button
                                            icon='eye'
                                            attached='right'
                                            onClick={() => this.setState({
                                                showPassword: !showPassword
                                            })} />
                                    </Form.Input>

                                    {isPasswordError(this.props) &&
                                    <Label color='red' pointing>
                                        {passwordError(this.props)}
                                    </Label>}

                                    <Label>
                                        Пароль повинен містити мінімум 8 символів,
                                        серед яких хоч одну цифру, спецсимвол, велику та маленьку літери
                                    </Label>
                                </Form.Field>

                                <Form.Field>
                                    <label>Повторіть ваш новий пароль</label>
                                    <Form.Input
                                        labelPosition='right'
                                        id='repeatPassword'
                                        name='repeatPassword'
                                        error={isPasswordError(this.props)}
                                        onChange={this.handleChange}
                                        type={showRepeatedPassword ? 'text' : 'password' }>
                                        <input />
                                        <Form.Button
                                            icon='eye'
                                            attached='right'
                                            onClick={() => this.setState({
                                                showRepeatedPassword: !showRepeatedPassword
                                            })} />
                                    </Form.Input>

                                </Form.Field>

                                <Button
                                    type='button' primary
                                    onClick={this.sendPassword}>Зберегти</Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            );
        }
    }
}

ResetPassword.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.resetPassword;
}

export default connect(mapStateToProps)(ResetPassword);