import { Message } from 'semantic-ui-react';
import React from 'react';

const DEFAULT_NEGATIVE_HEADER = 'Помилка';
const DEFAULT_NEGATIVE_CONTENT = 'Спробуйте повторити запит трохи пізніше';

const Error = ({ header, content }) => (
    <Message negative>
        <Message.Header content={header || DEFAULT_NEGATIVE_HEADER}/>
        <p>{content || DEFAULT_NEGATIVE_CONTENT}</p>
    </Message>
);

const Success = ({ header, content }) => (
    <Message positive>
        <Message.Header content={header}/>
        <p>{content}</p>
    </Message>
);

const Notification = {
    Error, Success
};

export default Notification;