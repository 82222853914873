import { CLEAR_ACCOUNT_DETAILS, COMPLETE_LOAD_ACCOUNT_DETAILS, SET_ACCOUNT_DETAILS } from '../actions/accountDetails';

const initialState = {
    username: null,
    role: null,
    created: null,
    enabled: false,
    uuid: null,
    childrenCount: 0,
    enrollmentsCount: 0,
    maxEnrollments: 0,
    authenticated: false,
    regionName: null,
    isFetched: false,
};

export default function accountDetails(state = initialState, action) {

    switch (action.type) {
    case COMPLETE_LOAD_ACCOUNT_DETAILS:
        return {
            ...state,
            ...action.accountDetails,
            isFetched: true,
        };
    case SET_ACCOUNT_DETAILS:
        return {
            ...state,
            ...action.accountDetails
        };
    case CLEAR_ACCOUNT_DETAILS:
        return {};
    default:
        return state;
    }
}