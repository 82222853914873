import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import foodFactsReportActions from '../../actions/presence-facts/food-report';
import { Button, Icon } from 'semantic-ui-react';

class DownloadFoodReport extends Component {

    downloadReport = () => {
        this.props.dispatch(foodFactsReportActions.download(this.props.params));
    };

    render() {

        const { isFetching } = this.props;

        return (
            <Button
                disabled={isFetching}
                loading={isFetching}
                labelPosition='right'
                size='small'
                floated='right'
                icon
                color='blue'
                onClick={this.downloadReport}>
                Скачати
                <Icon name='file excel'/>
            </Button>
        );
    }
}

DownloadFoodReport.propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state.foodFactsReportXls;
}

export default connect(mapStateToProps)(DownloadFoodReport);