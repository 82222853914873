// react
import React, { Component } from 'react';
// semantic
import { Message, Segment, } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';

class LocationViewInfoUserSection extends Component {

    render() {

        const { description, longDescription } = this.props.location;

        return (
            <Segment>
                <Message size='big' info>
                    <Message.Header>Інформація</Message.Header>
                    <div style={{whiteSpace: 'pre-wrap'}}>{description}</div>
                </Message>

                <Message size='big' info>
                    <Message.Header>Додаткова інформація</Message.Header>
                    <div style={{whiteSpace: 'pre-wrap'}}>{longDescription}</div>
                </Message>
            </Segment>
        );
    }
}

LocationViewInfoUserSection.propTypes = {
    location: PropTypes.object.isRequired
};

export default LocationViewInfoUserSection;
