import {
    SEND_CONFIRMATION_EMAIL_FAIL,
    SEND_CONFIRMATION_EMAIL_INIT,
    SEND_CONFIRMATION_EMAIL_REQUEST,
    SEND_CONFIRMATION_EMAIL_SUCCESS
} from '../../actions/email/resendConfirmationEmail';

const initialState = {
    isFetching: false,
    isSuccess: false,
    isFailed: false,
    errorCode: null,
    fieldErrors: null,
};

export default function resendConfirmationEmail(state = initialState, action) {
    switch (action.type) {
    case SEND_CONFIRMATION_EMAIL_INIT:
        return initialState;
    case SEND_CONFIRMATION_EMAIL_REQUEST:
        return {
            ...state,
            isFetching: true,
            isSuccess: false,
            isFailed: false,
            errorCode: null
        };
    case SEND_CONFIRMATION_EMAIL_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isSuccess: true,
            isFailed: false,
            errorCode: null
        };
    case SEND_CONFIRMATION_EMAIL_FAIL:
        return {
            ...state,
            isFetching: false,
            isSuccess: false,
            isFailed: true,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
        };
    default:
        return state;
    }
}