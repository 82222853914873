import React from 'react';
import { Header, Table } from 'semantic-ui-react';

const ParentInfoTab = ({ enrollment }) => {
    const { accountInfo, account } = enrollment;

    if (!accountInfo) {
        return (<Header as='h5'>Дані про батьків не заповнені</Header>);
    }

    return (
        <Table basic='very' celled collapsing>
            <Table.Body>
                <Table.Row>
                    <Table.Cell collapsing content="Прізвище:"/>
                    <Table.Cell content={accountInfo.lastName}/>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing content={'Ім\'я:'}/>
                    <Table.Cell content={accountInfo.firstName}/>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing content="По батькові:"/>
                    <Table.Cell content={accountInfo.middleName}/>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing content="Телефонний номер:"/>
                    <Table.Cell content={accountInfo.phoneNumber}/>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing content="Додатковий телефонний номер:"/>
                    <Table.Cell content={accountInfo.additionalPhoneNumber}/>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing content="Електронна адреса:"/>
                    <Table.Cell content={account.username}/>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default ParentInfoTab;