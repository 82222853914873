import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import foodPrivilegeActions from '../../../actions/food-privilege';
import { isLocationAdmin } from '../../../services/AuthService';

class FoodPrivilegeDropdown extends Component {

    componentDidMount() {
        const { dispatch, regionId, isFetched, isFetching } = this.props;

        if (isFetched || isFetching) {
            return;
        }

        dispatch(foodPrivilegeActions.fetchAll(regionId));
    }

    render() {
        const { payload, onChange, value, isFetching, isFetched } = this.props;

        const isLoading = isFetching || !isFetched;

        const privileges = payload || [];

        const options = privileges.map(privilege => {
            return {
                key: privilege.id,
                value: privilege.id,
                text: privilege.name
            };
        });

        return (
            <Dropdown
                name='privilegeId'
                options={options}
                placeholder='Оберить пільгу на харчування'
                selection
                loading={isLoading}
                onChange={onChange}
                value={value}
                disabled={!isLocationAdmin() || isLoading}
            />
        );
    }
}

FoodPrivilegeDropdown.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
    value: PropTypes.number
};

function mapStateToProps(state) {
    return state.foodPrivilegeList;
}

export default connect(mapStateToProps)(FoodPrivilegeDropdown);