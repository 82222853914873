import {
    createDeleteEntityAction,
    createFetchEntityAction,
    createResetEntityAction,
    createUpdateEntityAction,
} from '../generic/entityAction';

export const LOCATION_ADMINS_LIST = 'LOCATION_ADMINS_LIST';
export const LOCATION_ADMIN = 'LOCATION_ADMIN';

export default {
    fetchAll: createFetchEntityAction(locationId => `/api/locations/${locationId}/accounts`, LOCATION_ADMINS_LIST),
    add: createUpdateEntityAction(payload => `/api/locations/${payload.locationId}/accounts`, LOCATION_ADMIN),
    delete: createDeleteEntityAction(payload => `/api/locations/${payload.locationId}/accounts`, LOCATION_ADMIN),
    reset: createResetEntityAction(LOCATION_ADMIN),
};