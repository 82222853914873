import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Form } from 'semantic-ui-react';
import groupAgesAction from '../../actions/group-age';
import _ from 'lodash';
import { isLocationAdmin } from '../../services/AuthService';

class GroupAgeDropDown extends Component {

    componentDidMount() {
        const { dispatch, locationId, isFetched, isFetching } = this.props;

        if (isFetched || isFetching) {
            return;
        }

        dispatch(groupAgesAction.fetchAll(locationId));
    }

    render() {
        const { payload, onChange, value, isFetching, isFetched } = this.props;

        if (isFetching || !isFetched || _.isEmpty(payload)) {
            return null;
        }

        const groupAges = payload.map(groupAge => {
            return {
                key: groupAge.id,
                value: groupAge.id,
                text: groupAge.name
            };
        });

        return (
            <Form.Field>
                <label>Вікова група</label>
                <Dropdown
                    name='groupAgeId'
                    options={groupAges}
                    placeholder='Оберить вікову групу'
                    selection
                    fluid
                    onChange={onChange}
                    value={value}
                    disabled={!isLocationAdmin()}
                />
            </Form.Field>
        );
    }
}

GroupAgeDropDown.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
    value: PropTypes.number
};

function mapStateToProps(state) {
    return state.groupAges;
}

export default connect(mapStateToProps)(GroupAgeDropDown);