import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { createGetParams, getResponseData } from '../utils';
import _ from 'lodash';


export const FETCH_REGION_REQUEST = 'FETCH_REGION_REQUEST';
export const FETCH_REGION_SUCCESS = 'FETCH_REGION_SUCCESS';
export const FETCH_REGION_FAILURE = 'FETCH_REGION_FAILURE';

export const UPDATE_REGION_REQUEST = 'UPDATE_REGION_REQUEST';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_FAILURE = 'UPDATE_REGION_FAILURE';


export function fetchRegion(regionId) {

    return async function (dispatch) {

        dispatch(fetchRegionRequest());

        return axios
            .get(`/api/regions/${regionId}`, createGetParams())
            .then(res => dispatch(fetchRegionSuccess(res)))
            .catch(res => dispatch(fetchRegionFailure(res)));
    };

    function fetchRegionRequest() {
        return { type: FETCH_REGION_REQUEST };
    }

    function fetchRegionSuccess(res) {
        const region = res.data;
        return { type: FETCH_REGION_SUCCESS, region };
    }

    function fetchRegionFailure(res) {
        const { errorCode } = getResponseData(res);
        return { type: FETCH_REGION_FAILURE, errorCode };
    }
}

export function updateRegion({ id, editorEmail }) {

    const region = { id, editorEmail };

    return async function (dispatch) {

        dispatch(updateRegionRequest());

        return axios
            .put('/api/regions', _.pickBy(region, _.identity), REQUEST_SETTINGS)
            .then(res => dispatch(updateRegionSuccess(res)))
            .catch(res => dispatch(updateRegionFailure(res)));
    };

    function updateRegionRequest() {
        return { type: UPDATE_REGION_REQUEST };
    }

    function updateRegionSuccess(res) {
        const region = res.data;
        return { type: UPDATE_REGION_SUCCESS, region };
    }

    function updateRegionFailure(res) {
        const { errorCode, errorMessage, fieldErrors } = getResponseData(res);
        return { type: UPDATE_REGION_FAILURE, errorCode, errorMessage, fieldErrors };
    }

}
