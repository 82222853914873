import {
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE
} from '../../actions/group/deleteGroup';

const initialState = {
    isDeleting: false,
    isDeleteFailed: false,
    isDeleted: false,
    errorCode: null
};

export default function deleteGroup(state = initialState, action) {
    switch (action.type) {
    case DELETE_GROUP_REQUEST:
        return { ...initialState };
    case DELETE_GROUP_SUCCESS:
        return {  isDeleted: true };
    case DELETE_GROUP_FAILURE:
        return {
            isDeleteFailed: true,
            errorCode: action.errorCode
        };
    default:
        return state;
    }
}