import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';

export const START_GET_EVENT_LIST = 'START_GET_EVENT_LIST';
export const COMPLETE_GET_EVENT_LIST = 'COMPLETE_GET_EVENT_LIST';
export const FAIL_GET_EVENT_LIST = 'FAIL_GET_EVENT_LIST';

function startGetEventList() {
    return { type: START_GET_EVENT_LIST };
}

function completeGetEventList(res) {
    return {
        type: COMPLETE_GET_EVENT_LIST,
        payload: res.data
    };
}

function failGetEventList(res) {
    const { errorCode } = getResponseData(res);
    return { type: FAIL_GET_EVENT_LIST, errorCode };
}

export function doGetEventList({ page }) {
    return async function (dispatch) {
        dispatch(startGetEventList());
        return axios
            .get('/api/events', createGetParams({ page }))
            .then(res => dispatch(completeGetEventList(res)))
            .catch(error => dispatch(failGetEventList(error)));
    };
}