import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { Button, Header, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import LargeDimmer from '../helpers/Dimmer';
import { fetchRegionsPaginated } from '../../actions/region/fetchRegionsPaginated';

class RegionsTable extends Component {

    componentDidMount() {
        this.fetchRegions();
    }

    fetchRegions = () => {
        this.props.dispatch(fetchRegionsPaginated());
    };

    render() {
        const { payload, isFetching, isFetched } = this.props;

        if (isFetching || !isFetched) {
            return (
                <LargeDimmer text='Завантаження даних'/>
            );
        }

        const { regionsCount, regions } = payload;

        if (regionsCount === 0) {
            return (<Header>Регіонів не знайдено.</Header>);
        }

        return (
            <>
                <Table celled selectable striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content='Назва'/>
                            <Table.HeaderCell content='Опис'/>
                            <Table.HeaderCell content='Операції'/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {regions.map((region) => {
                            return (
                                <Table.Row key={region.id}>
                                    <Table.Cell content={region.name}/>
                                    <Table.Cell content={region.description}/>
                                    <Table.Cell>
                                        <Button
                                            as={Link}
                                            to={{ pathname: `/regions/${region.id}` }}
                                            floated='right'
                                            icon='edit'
                                            size='tiny'
                                            labelPosition='right'
                                            content='Редагувати'
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </>
        );
    }
}

RegionsTable.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.fetchRegionsPaginated;
}

export default connect(mapStateToProps)(RegionsTable);