import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import foodPrivilegeEnrollmentListActions from '../../../actions/food-privilege-enrollment/list';
import _ from 'lodash';
import LargeDimmer from '../../helpers/Dimmer';
import DeleteFoodPrivilegeDialog from './DeleteFoodPrivilegeDialog';

class FoodPrivilegeTable extends Component {

    state = {
        isDeletePrivilegeModalOpened: false,
        selectedRow: null,
    };

    componentDidMount() {
        const { enrollment } = this.props;
        this.props.dispatch(foodPrivilegeEnrollmentListActions.fetchByEnrollmentId(enrollment.id));
    }

    onDeletePrivilege = row => {
        this.setState({
            selectedRow: row,
            isDeletePrivilegeModalOpened: true,
        });
    };

    onCloseDeletePrivilegeDialog = () => {
        this.setState({ isDeletePrivilegeModalOpened: false });
    };

    onCompleteDeletePrivilegeDialog = () => {
        const { enrollment } = this.props;
        this.props.dispatch(foodPrivilegeEnrollmentListActions.fetchByEnrollmentId(enrollment.id));
        this.setState({ isDeletePrivilegeModalOpened: false });
    };

    /**
     * @property privilege
     */
    renderPrivilegeRow = row => {
        return (
            <Table.Row key={row.id}>
                <Table.Cell>{row.privilege.name}</Table.Cell>
                <Table.Cell>{row.dateStart}</Table.Cell>
                <Table.Cell>{row.dateEnd}</Table.Cell>
                <Table.Cell>
                    <Button
                        onClick={() => this.onDeletePrivilege(row)}
                        content='Видалити'
                        negative
                        size='mini'
                    />
                </Table.Cell>
            </Table.Row>
        );
    };

    render() {

        const { payload, isFetching, isFetched } = this.props;

        if (isFetching || !isFetched) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (_.isEmpty(payload)) {
            return null;
        }

        const { selectedRow } = this.state;

        return (
            <>
                <DeleteFoodPrivilegeDialog
                    enrollmentPrivilege={selectedRow}
                    isOpened={this.state.isDeletePrivilegeModalOpened}
                    onClose={this.onCloseDeletePrivilegeDialog}
                    onComplete={this.onCompleteDeletePrivilegeDialog}
                />
                <Table selectable striped celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing content='Назва'/>
                            <Table.HeaderCell collapsing content='Початок дії'/>
                            <Table.HeaderCell collapsing content='Закінчення дії'/>
                            <Table.HeaderCell collapsing content='Дії'/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {payload.map(this.renderPrivilegeRow)}
                    </Table.Body>
                </Table>
            </>
        );
    }
}

FoodPrivilegeTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    enrollment: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state.foodPrivilegeEnrollmentList;
}

export default connect(mapStateToProps)(FoodPrivilegeTable);