import {
    START_APPLY_TO_GROUP,
    COMPLETE_APPLY_TO_GROUP,
    FAIL_APPLY_TO_GROUP,
    RESET_APPLY_TO_GROUP,
} from '../../actions/enrollment/applyToGroup';

const initialState = {
    isAppliedToGroup: false,
    isFetching: false,
    isApplyToGroupFailed: false
};

export default function applyToGroup(state = initialState, action) {

    switch (action.type) {
    case START_APPLY_TO_GROUP:
        return {
            ...state,
            isAppliedToGroup: false,
            isFetching: true,
            isApplyToGroupFailed: false,
            errorCode: null
        };
    case COMPLETE_APPLY_TO_GROUP:
        return {
            ...state,
            isAppliedToGroup: true,
            isFetching: false,
            isApplyToGroupFailed: false,
            errorCode: null
        };
    case FAIL_APPLY_TO_GROUP:
        return {
            ...state,
            isAppliedToGroup: false,
            isFetching: false,
            isApplyToGroupFailed: true,
            errorCode: action.errorCode
        };
    case RESET_APPLY_TO_GROUP:
        return initialState;
    default:
        return state;
    }
}