// react
import connect from 'react-redux/es/connect/connect';

// libs
import PropTypes from 'prop-types';

// actions
import locationAdminActions from '../../actions/location/locationAdmin';

// components
import RemoveLocationAdminDialog from './dialog/RemoveLocationAdminDialog';
import AddLocationAdminForm from './AddLocationAdminForm';

import AdminListSectionBase from '../common/AdminListSectionBase';
import { Header, Segment } from 'semantic-ui-react';
import t from '../../services/Translate';
import React from 'react';

class LocationAdminListSection extends AdminListSectionBase {

    constructor(props) {
        super(props);

        this.fetchAdmins = () => {
            this.props.dispatch(locationAdminActions.fetchAll(this.props.targetId));
        };
    }

    render() {

        const { targetId, targetName } = this.props;

        return (
            <Segment>
                <RemoveLocationAdminDialog
                    username={this.state.email}
                    targetId={targetId}
                    targetName={targetName}
                    isDialogOpen={this.state.isRemoveAdminDialogOpen}
                    onClose={this.onRefresh}
                />

                <Header as='h2' content={t('locationAdmins')}/>

                <AddLocationAdminForm
                    targetId={targetId}
                    targetName={targetName}
                    onAdminAdded={this.onRefresh}
                />

                {this.renderAdminsTable()}

            </Segment>
        );
    }
}

LocationAdminListSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    targetId: PropTypes.number.isRequired,
    targetName: PropTypes.string.isRequired,
};

function mapStateToProps(store) {
    return store.locationAdminList;
}

export default connect(mapStateToProps)(LocationAdminListSection);
