import {
    START_LIST_LOCATIONS,
    COMPLETE_LIST_LOCATIONS,
    FAIL_LIST_LOCATIONS,
} from '../../actions/location/listLocations';

const initialState = {
    isFetching: false,
    isFailed: false,
    regionId: null,
    locations: [],
};

export default function listLocations(state = initialState, action) {

    switch (action.type) {
    case START_LIST_LOCATIONS:
        return {
            ...state,
            isFetching: true,
            isFailed: false
        };
    case COMPLETE_LIST_LOCATIONS:
        return {
            ...action,
            isFetching: false,
            isFailed: false
        };
    case FAIL_LIST_LOCATIONS:
        return {
            ...action,
            isFetching: false,
            isFailed: true,
            locations: []
        };
    default:
        return state;
    }
}