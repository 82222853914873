import React from 'react';
import t from '../services/Translate';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const CAN_NOT_DELETE_NOT_EMPTY_GROUP = 'CAN_NOT_DELETE_NOT_EMPTY_GROUP';

const INVALID_EMAIL = 'INVALID_EMAIL';

const GROUP_MAX_REGULAR_CHILDREN_COUNT_EXCEEDED = 'GROUP_MAX_REGULAR_CHILDREN_COUNT_EXCEEDED';
const GROUP_MAX_PRIVILEGED_CHILDREN_COUNT_EXCEEDED = 'GROUP_MAX_PRIVILEGED_CHILDREN_COUNT_EXCEEDED';
const ENROLLMENT_OUT_OF_TURN_FOR_NORMAL_CHILDREN = 'ENROLLMENT_OUT_OF_TURN_FOR_NORMAL_CHILDREN';
const ENROLLMENT_OUT_OF_TURN_FOR_PRIVILEGED_CHILDREN = 'ENROLLMENT_OUT_OF_TURN_FOR_PRIVILEGED_CHILDREN';

const ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND';
const ACCOUNT_NOT_ENABLED = 'ACCOUNT_NOT_ENABLED';
const INVALID_ACCOUNT_ROLE = 'INVALID_ACCOUNT_ROLE';
const ACCOUNT_IS_ALREADY_ADMIN_FOR_THIS_OBJECT = 'ACCOUNT_IS_ALREADY_ADMIN_FOR_THIS_OBJECT';
const ACCOUNT_IS_NOT_ADMIN_FOR_THIS_OBJECT = 'ACCOUNT_IS_NOT_ADMIN_FOR_THIS_OBJECT';

export const EMAIL_EMPTY = 'EMAIL_EMPTY';

const USER_EXISTS = 'USER_EXISTS';

const PASSWORDS_NOT_EQUAL = 'PASSWORDS_NOT_EQUAL';
const PASSWORD_EMPTY = 'PASSWORD_EMPTY';
const PASSWORD_TOO_LONG = 'PASSWORD_TOO_LONG';
const PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT';

const UPPERCASE_NOT_FOUND = 'UPPERCASE_NOT_FOUND';
const DIGIT_NOT_FOUND = 'DIGIT_NOT_FOUND';
const SPECIAL_CHARACTER_NOT_FOUND = 'SPECIAL_CHARACTER_NOT_FOUND';
const WHITESPACE_FOUND = 'WHITESPACE_FOUND';
const LOWERCASE_NOT_FOUND = 'LOWERCASE_NOT_FOUND';

const GROUP_MAX_COUNT_EXCEEDED = 'GROUP_MAX_COUNT_EXCEEDED';

const RECAPTCHA_NOT_PASSED = 'RECAPTCHA_NOT_PASSED';

const LIMIT_FOR_ENROLLMENTS_HAS_BEEN_EXCEEDED = 'LIMIT_FOR_ENROLLMENTS_HAS_BEEN_EXCEEDED';
const LIMIT_FOR_ENROLLMENTS_IN_LOCATION_HAS_BEEN_EXCEEDED = 'LIMIT_FOR_ENROLLMENTS_IN_LOCATION_HAS_BEEN_EXCEEDED';
const REJECTION_COMMENT_REQUIRED = 'REJECTION_COMMENT_REQUIRED';
const INVALID_REJECTION_COMMENT = 'INVALID_REJECTION_COMMENT';
const CHILD_ALREADY_HAS_CONFIRMED_ENROLLMENTS = 'CHILD_ALREADY_HAS_CONFIRMED_ENROLLMENTS';

const ENROLLMENT_GROUP_EMPTY = 'ENROLLMENT_GROUP_EMPTY';

export const PRIVILEGE_DATE_CONFLICT = 'PRIVILEGE_DATE_CONFLICT';

export const groupAddingError = (props) => {
    const { errorCode } = props;

    if (errorCode === GROUP_MAX_COUNT_EXCEEDED) {
        return t('errorTooManyGroups');
    } else {
        return '';
    }
};

export const isGroupAddingError = (props) => !!groupAddingError(props);

export const getAddAdminToObjectError = (props) => {
    const { errorCode } = props;

    switch (errorCode) {
    case EMAIL_EMPTY:
        return 'Заповніть електронну адресу';
    case ACCOUNT_NOT_FOUND:
        return 'Користувача зі вказаною електронною адресою не знайдено';
    case INVALID_EMAIL:
        return 'Електронна адреса некорректна';
    case ACCOUNT_NOT_ENABLED:
        return 'Данний користувач не активований';
    case INVALID_ACCOUNT_ROLE:
        return 'Користувач вже має іншу роль';
    case ACCOUNT_IS_ALREADY_ADMIN_FOR_THIS_OBJECT:
        return 'Користувач вже є адміністратором';
    default:
        return 'Помилка запиту';
    }
};

export const getRemoveAdminFromObjectError = (props) => {
    const { errorCode } = props;

    switch (errorCode) {
    case ACCOUNT_NOT_FOUND:
        return 'Користувача зі вказаною електронною адресою не знайдено';
    case ACCOUNT_IS_NOT_ADMIN_FOR_THIS_OBJECT:
        return 'Користувач не є адміністратором';
    default:
        return 'Помилка запиту';
    }
};

export const enrollmentUpdateError = (props) => {
    const { errorCode } = props;

    switch (errorCode) {
    case GROUP_MAX_PRIVILEGED_CHILDREN_COUNT_EXCEEDED:
        return t('errorGroupMaxPrivilegedChildrenCountExceeded');
    case GROUP_MAX_REGULAR_CHILDREN_COUNT_EXCEEDED:
        return t('errorGroupMaxRegularChildrenCountExceeded');
    case ENROLLMENT_OUT_OF_TURN_FOR_PRIVILEGED_CHILDREN:
        return 'Вже існує заявка від іншої дитини з пільгами, яку батьки зареєстрували раніше.';
    case ENROLLMENT_OUT_OF_TURN_FOR_NORMAL_CHILDREN:
        return 'Вже існує заявка від іншої дитини, яку батьки зареєстрували раніше.';
    case LIMIT_FOR_ENROLLMENTS_HAS_BEEN_EXCEEDED:
        return 'Ви подали занадто багато заявок для цієї дитини';
    case LIMIT_FOR_ENROLLMENTS_IN_LOCATION_HAS_BEEN_EXCEEDED:
        return t('errorEnrollmentsLimitForLocationExceeded');
    case REJECTION_COMMENT_REQUIRED:
        return 'Для відхилення заявки необхідно додати коментар';
    case CHILD_ALREADY_HAS_CONFIRMED_ENROLLMENTS:
        return 'Ця дитина вже має підтверджені заявки в інших закладах. Ви можете переглянути список цих заявок на сторінці перегляду заявки.';
    case INVALID_REJECTION_COMMENT:
        return 'Коментар містить неприпустимі символи';
    default:
        return '';
    }
};

export const isEnrollmentUpdateError = (props) => !!enrollmentUpdateError(props);

export const passwordError = (props) => {
    const { errorCode } = props;

    switch (errorCode) {
    case PASSWORDS_NOT_EQUAL:
        return 'Паролі не співпадають';
    case PASSWORD_EMPTY:
        return 'Введіть пароль';
    case PASSWORD_TOO_LONG:
        return 'Пароль занадто довгий';
    case PASSWORD_TOO_SHORT:
        return 'Пароль занадто короткий';
    case UPPERCASE_NOT_FOUND:
        return 'Пароль повинен містити хоч одну велику літеру';
    case LOWERCASE_NOT_FOUND:
        return 'Пароль повинен містити хоч одну маленьку літеру';
    case DIGIT_NOT_FOUND:
        return 'Пароль повинен містити хоч одну цифру';
    case SPECIAL_CHARACTER_NOT_FOUND:
        return 'Пароль повинен містити хоч один спецсимвол';
    case WHITESPACE_FOUND:
        return 'Пароль не повинен містити пробілу';
    default:
        return '';
    }
};

export const isPasswordError = (props) => !!passwordError(props);

export const emailError = (props) => {
    const { errorCode } = props;

    switch (errorCode) {
    case INVALID_EMAIL:
        return 'Невірний email';
    case USER_EXISTS:
        return 'Такий користувач вже існує';
    case EMAIL_EMPTY:
        return 'Введіть email';
    default:
        return '';
    }
};

export const isEmailError = (props) => !!emailError(props);

export const recaptchaError = (props) => {
    const { errorCode } = props;

    if (errorCode === RECAPTCHA_NOT_PASSED) {
        return 'Будь ласка, підтвердіть капчу';
    } else {
        return '';
    }
};

export const isRecaptchaError = (props) => {
    return props.errorCode === RECAPTCHA_NOT_PASSED;
};

export const limitForEnrollmentError = (props) => {
    const { errorCode } = props;

    switch (errorCode) {
    case LIMIT_FOR_ENROLLMENTS_HAS_BEEN_EXCEEDED:
        return 'Ви подали занадто багато заявок для цієї дитини';
    case LIMIT_FOR_ENROLLMENTS_IN_LOCATION_HAS_BEEN_EXCEEDED:
        return t('errorEnrollmentsLimitForLocationExceeded');
    default:
        return '';
    }
};

export const isLimitForEnrollmentError = (props) => !!limitForEnrollmentError(props);

export const isGroupNotEmpty = (props) => {
    const { errorCode } = props;

    return errorCode === CAN_NOT_DELETE_NOT_EMPTY_GROUP;
};

export const enrollmentGroupError = (props) => {

    const { errorCode } = props;

    if (errorCode === ENROLLMENT_GROUP_EMPTY) {
        return 'Оберіть групу';
    } else {
        return '';
    }
};

export const isEnrollmentGroupError = (props) => !!enrollmentGroupError(props);

const fieldMatches = (fieldError, fieldName) =>
    fieldError.fieldName === fieldName || _.endsWith(fieldError.fieldName, '.' + fieldName);

export const isFieldError = (props, fieldName) => {
    if (_.isEmpty(props.fieldErrors)) {
        return false;
    }
    return !!_.find(props.fieldErrors, fieldError => fieldMatches(fieldError, fieldName));
};

export const getFieldError = (props, fieldName) => {
    return _.find(props.fieldErrors, fieldError => fieldMatches(fieldError, fieldName)).message;
};

const ERROR_MESSAGES = {
    FILE_TOO_LARGE: 'Занадто великий файл'
};

export const getErrorMessage = errorCode => {
    return ERROR_MESSAGES[errorCode] || 'Помилка серверу';
};

export const getConflictingEnrollmentDetailsFromResponse = props => {
    const { errorResponse } = props;
    if (errorResponse) {
        return (
            <Link to={`/admin/locations/${errorResponse.locationId}/enrollments/${errorResponse.enrollmentId}`}>
                {errorResponse.locationName}, {errorResponse.groupName}, заявка № {errorResponse.enrollmentId}
            </Link>
        );
    } else {
        return '';
    }
}