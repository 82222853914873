import { getChildFromState, getResponseData } from '../utils';
import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { formatDateToLocalDateString } from '../../utils/dateTime';
import { transform } from '.';
import _ from 'lodash';

export const EDIT_ENROLLMENT_REQUEST = 'EDIT_ENROLLMENT_REQUEST';
export const EDIT_ENROLLMENT_SUCCESS = 'EDIT_ENROLLMENT_SUCCESS';
export const EDIT_ENROLLMENT_FAILURE = 'EDIT_ENROLLMENT_FAILURE';
export const EDIT_ENROLLMENT_RESET = 'EDIT_ENROLLMENT_RESET';

export function editEnrollmentReset() {
    return { type: EDIT_ENROLLMENT_RESET };
}

function editEnrollmentRequest() {
    return { type: EDIT_ENROLLMENT_REQUEST };
}

function editEnrollmentSuccess(res) {
    const payload = transform(res.data);
    return { type: EDIT_ENROLLMENT_SUCCESS, payload };
}

function editEnrollmentFailure(error) {
    const { errorCode, fieldErrors, errorResponse } = getResponseData(error);
    return { type: EDIT_ENROLLMENT_FAILURE, errorCode, fieldErrors, errorResponse };
}

export function doEditEnrollment(state) {

    const enrollment = {
        id: state.id,
        created: formatDateToLocalDateString(state.created),
        groupId: state.groupId,
        prioritized: state.prioritized,
        parentEmail: _.trim(state.parentEmail),
        child: getChildFromState(state),
    };

    return async function (dispatch) {

        dispatch(editEnrollmentRequest(enrollment));

        return axios
            .put('/api/enrollments', enrollment, REQUEST_SETTINGS)
            .then((res) => dispatch(editEnrollmentSuccess(res)))
            .catch(error => dispatch(editEnrollmentFailure(error)));
    };
}