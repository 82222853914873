import React from 'react';
import { SERVICE_TYPE } from '../utils/settings';

const resources = {

    landingTitle: {
        kindergarten: 'Електронна черга дітей в дитячі садки',
        school: 'Електронна черга дітей в школи',
        gurtok: 'Електронна черга дітей в гуртки та заклади позашкілля',
    },

    addGroup: {
        kindergarten: 'Додати групу',
        school: 'Додати клас',
        gurtok: 'Додати гурток',
    },

    selectGroup: {
        kindergarten: 'Вкажіть групу',
        school: 'Вкажіть клас',
        gurtok: 'Вкажіть гурток',
    },

    groupAdded: {
        kindergarten: 'Нова група була успішно додана',
        school: 'Новий клас був успішно доданий',
        gurtok: 'Новий гурток був успішно доданий',
    },

    groupSaved: {
        kindergarten: 'Групу збережено',
        school: 'Клас збережено',
        gurtok: 'гурток збережено',
    },

    backToLocation: {
        kindergarten: 'Назад до садку',
        school: ' Назад до школи',
        gurtok: ' Назад до закладу',
    },

    groupDetails: {
        kindergarten: 'Дані групи',
        school: 'Дані класу',
        gurtok: 'Дані гуртка',
    },

    createLocation: {
        kindergarten: 'Додати дитячий садок',
        school: 'Додати школу',
        gurtok: 'Додати заклад',
    },

    locationName: {
        kindergarten: 'Назва дитячого садку',
        school: 'Назва школи',
        gurtok: 'Назва закладу',
    },

    locationDescription: {
        kindergarten: 'Опис дитячого садку',
        school: 'Опис школи',
        gurtok: 'Опис закладу',
    },

    locationCreated: {
        kindergarten: 'Ви успішно додали дитячий садок',
        school: 'Ви успішно додали школу',
        gurtok: 'Ви успішно додали заклад',
    },

    errorLocationNameNotUnique: {
        kindergarten: 'Така назва дитячого садку вже існує',
        school: 'Така назва школи вже існує',
        gurtok: 'Така назва закладу вже існує',
    },

    errorLocationNameEmpty: {
        kindergarten: 'Назва дитячого садку має бути заповненою',
        school: 'Назва школи має бути заповненою',
        gurtok: 'Назва закладу має бути заповненою',
    },

    errorLocationInvalidName: {
        kindergarten: 'У назві дитячого садку присутні помилки. Назва може містити великі та малі літери, цифри та наступні символи: № ( ) . , — : ; \'',
        school: 'У назві школи присутні помилки. Назва може містити великі та малі літери, цифри та наступні символи: № ( ) . , — : ; \'',
        gurtok: 'У назві закладу присутні помилки. Назва може містити великі та малі літери, цифри та наступні символи: № ( ) . , — : ; \'',
    },

    errorLocationNameTooShort: {
        kindergarten: 'Назва дитячого садку закоротка',
        school: 'Назва школи закоротка',
        gurtok: 'Назва закладу закоротка',
    },

    errorLocationNameTooLong: {
        kindergarten: 'Назва дитячого садку задовга',
        school: 'Назва школи задовга',
        gurtok: 'Назва закладу задовга',
    },

    errorLocationInvalidDescription: {
        kindergarten: 'У описі присутні помилки. Опис дитячого садку може містити великі та малі літери, цифри та наступні символи: № ( ) . , — : ; \'',
        school: 'У описі присутні помилки. Опис школи може містити великі та малі літери, цифри та наступні символи: № ( ) . , — : ; \'',
        gurtok: 'У описі присутні помилки. Опис закладу може містити великі та малі літери, цифри та наступні символи: № ( ) . , — : ; \'',
    },

    errorLocationDescriptionTooShort: {
        kindergarten: 'Опис дитячого садку закороткий',
        school: 'Опис школи закороткий',
        gurtok: 'Опис закладу закороткий',
    },

    errorLocationDescriptionTooLong: {
        kindergarten: 'Опис дитячого садку задовгий',
        school: 'Опис школи задовгий',
        gurtok: 'Опис закладу задовгий',
    },

    eventText: {
        kindergarten(locationName, groupName) {
            return (
                <>до садка <em><strong>{locationName} </strong></em> в групу <em><strong>{groupName} </strong></em></>
            );
        },
        school(locationName, groupName) {
            return (
                <>до школи <em><strong>{locationName} </strong></em> в клас <em><strong>{groupName} </strong></em></>
            );
        },
        gurtok(locationName, groupName) {
            return (
                <>до закладу <em><strong>{locationName} </strong></em> в гурток <em><strong>{groupName} </strong></em></>
            );
        }
    },

    /**
     * @property event.groupName
     * @property event.locationName
     * @property event.newValue
     * @property event.oldValue
     */
    eventChangeText: {
        kindergarten(event) {
            return (
                <>
                    змінив максимальну кількість дітей
                    з <em><strong>{event.oldValue} </strong></em>
                    на <em><strong>{event.newValue} </strong></em>
                    в садку <em><strong>{event.locationName} </strong></em>
                    в групі <em><strong>{event.groupName} </strong></em>
                </>
            );
        },
        school(event) {
            return (
                <>
                    змінив максимальну кількість дітей
                    з <em><strong>{event.oldValue} </strong></em>
                    на <em><strong>{event.newValue} </strong></em>
                    в школі <em><strong>{event.locationName} </strong></em>
                    в класі <em><strong>{event.groupName} </strong></em>
                </>
            );
        },
        gurtok(event) {
            return (
                <>
                    змінив максимальну кількість дітей
                    з <em><strong>{event.oldValue} </strong></em>
                    на <em><strong>{event.newValue} </strong></em>
                    в закладі <em><strong>{event.locationName} </strong></em>
                    в гуртку <em><strong>{event.groupName} </strong></em>
                </>
            );
        }
    },

    locations: {
        kindergarten: 'Дитячі садки',
        school: 'Школи',
        gurtok: 'Заклади',
    },

    locationProperties: {
        kindergarten: 'Дані дитячого садка',
        school: 'Дані школи',
        gurtok: 'Дані закладу',
    },

    locationGroups: {
        kindergarten: 'Групи садка',
        school: 'Класи школи',
        gurtok: 'Гуртки закладу',
    },

    groups: {
        kindergarten: 'Групи',
        school: 'Класи',
        gurtok: 'Гуртки',
    },

    locationDescriptionLabel: {
        kindergarten: 'Дані про садок',
        school: 'Дані про школу',
        gurtok: 'Дані про заклад',
    },

    locationLongDescriptionLabel: {
        kindergarten: 'Додаткова інформація',
        school: 'Додаткова інформація',
        gurtok: 'Додаткова інформація',
    },

    locationDescriptionPlaceholder: {
        kindergarten: 'Введіть додадкові дані про садок',
        school: 'Введіть додадкові дані про школу',
        gurtok: 'Введіть додадкові дані про заклад',
    },

    locationLongDescriptionPlaceholder: {
        kindergarten: 'Додаткова інформація - список вулиць та інше',
        school: 'Додаткова інформація - список вулиць та інше',
        gurtok: 'Додаткова інформація - список вулиць та інше',
    },

    location: {
        kindergarten: 'Дитячий садок',
        school: 'Школа',
        gurtok: 'Заклад',
    },

    group: {
        kindergarten: 'Група',
        school: 'Клас',
        gurtok: 'Гурток',
    },

    regionLocations: {
        kindergarten: 'Дитячі садки, котрі входять до регіону',
        school: 'Школи, котрі входять до регіону',
        gurtok: 'Заклади, котрі входять до регіону',
    },

    regionEmpty: {
        kindergarten: 'Наразі в даному регіоні немає жодного дитячого садка',
        school: 'Наразі в даному регіоні немає жодної школи',
        gurtok: 'Наразі в даному регіоні немає жодноого закладу',
    },

    locationAdmins: {
        kindergarten: 'Адміністратори даного дитячого садку',
        school: 'Адміністратори даної школи',
        gurtok: 'Адміністратори даного закладу',
    },

    groupAdmins: {
        kindergarten: 'Адміністратори даної групи',
        school: 'Адміністратори даного класу',
        gurtok: 'Адміністратори даного гуртку',
    },

    locationAdminAdded: {
        kindergarten({ username, targetName }) {
            return `Користувачу ${username} надані права на адміністрування дитячого садка "${targetName}"`;
        },
        school({ username, targetName }) {
            return `Користувачу ${username} надані права на адміністрування школи "${targetName}"`;
        },
        gurtok({ username, targetName }) {
            return `Користувачу ${username} надані права на адміністрування закладу "${targetName}"`;
        }
    },

    groupAdminAdded: {
        kindergarten({ email, targetName }) {
            return `Користувачу ${email} надані права на адміністрування групи "${targetName}"`;
        },
        school({ email, targetName }) {
            return `Користувачу ${email} надані права на адміністрування класу "${targetName}"`;
        },
        gurtok({ email, targetName }) {
            return `Користувачу ${email} надані права на адміністрування гуртка "${targetName}"`;
        }
    },

    locationAdminAddConfirm: {
        kindergarten({ username, targetName }) {
            return `Підтвердіть дію надання користовачу ${username} адміністративних прав на дитячий садок "${targetName}"`;
        },
        school({ username, targetName }) {
            return `Підтвердіть дію надання користовачу ${username} адміністративних прав на школу "${targetName}"`;
        },
        gurtok({ username, targetName }) {
            return `Підтвердіть дію надання користовачу ${username} адміністративних прав на заклад "${targetName}"`;
        }
    },

    groupAdminAddConfirm: {
        kindergarten({ email, targetName }) {
            return `Підтвердіть дію надання користовачу ${email} адміністративних прав на групу "${targetName}"`;
        },
        school({ email, targetName }) {
            return `Підтвердіть дію надання користовачу ${email} адміністративних прав на клас "${targetName}"`;
        },
        gurtok({ email, targetName }) {
            return `Підтвердіть дію надання користовачу ${email} адміністративних прав на гурток "${targetName}"`;
        }
    },

    locationAdminRemoved: {
        kindergarten({ username, targetName }) {
            return `Користувач ${username} успішно позбавлений адміністративних прав на дитячий садок "${targetName}"`;
        },
        school({ username, targetName }) {
            return `Користувач ${username} успішно позбавлений адміністративних прав на школу "${targetName}"`;
        },
        gurtok({ username, targetName }) {
            return `Користувач ${username} успішно позбавлений адміністративних прав на заклад "${targetName}"`;
        }
    },

    groupAdminRemoved: {
        kindergarten({ email, targetName }) {
            return `Користувач ${email} успішно позбавлений адміністративних прав на групу "${targetName}"`;
        },
        school({ email, targetName }) {
            return `Користувач ${email} успішно позбавлений адміністративних прав на клас "${targetName}"`;
        },
        gurtok({ email, targetName }) {
            return `Користувач ${email} успішно позбавлений адміністративних прав на гурток "${targetName}"`;
        }
    },

    locationAdminRemoveConfirm: {
        kindergarten({ username, targetName }) {
            return `Підтвердіть дію - позбавлення користувача ${username} адміністративних прав на дитячий садок "${targetName}"`;
        },
        school({ username, targetName }) {
            return `Підтвердіть дію - позбавлення користувача ${username} адміністративних прав на школу "${targetName}"`;
        },
        gurtok({ username, targetName }) {
            return `Підтвердіть дію - позбавлення користувача ${username} адміністративних прав на заклад "${targetName}"`;
        }
    },

    groupAdminRemoveConfirm: {
        kindergarten({ email, targetName }) {
            return `Підтвердіть дію - позбавлення користувача ${email} адміністративних прав на групу "${targetName}"`;
        },
        school({ email, targetName }) {
            return `Підтвердіть дію - позбавлення користувача ${email} адміністративних прав на клас "${targetName}"`;
        },
        gurtok({ email, targetName }) {
            return `Підтвердіть дію - позбавлення користувача ${email} адміністративних прав на гурток "${targetName}"`;
        }
    },

    errorGroupNameExists: {
        kindergarten: 'Така назва групи вже існує в цьому садку',
        school: 'Така назва класу вже існує в цій школі',
        gurtok: 'Така назва гуртка вже існує в даному закладі',
    },

    errorTooManyGroups: {
        kindergarten: 'Перевищена максимально можлива кількість груп для цього року',
        school: 'Перевищена максимально можлива кількість класів для цього року',
        gurtok: 'Перевищена максимально можлива кількість гуртків для цього року',
    },

    errorEnrollmentsLimitForLocationExceeded: {
        kindergarten: 'Вже подано забагато заявок',
        school: 'Вже подано забагато заявок',
        gurtok: 'Вже подано забагато заявок',
    },

    errorGroupMaxPrivilegedChildrenCountExceeded: {
        kindergarten: 'В групі вже зареєстровано достатньо дітей з пільгами',
        school: 'В класі вже зареєстровано достатньо дітей з пільгами',
        gurtok: 'В гуртку вже зареєстровано достатньо дітей з пільгами',
    },

    errorGroupMaxRegularChildrenCountExceeded: {
        kindergarten: 'В групі вже зареєстровано достатньо дітей без пільг',
        school: 'В класі вже зареєстровано достатньо дітей без пільг',
        gurtok: 'В гурток вже зареєстровано достатньо дітей без пільг',
    },

    enrollmentConfirm: {
        kindergarten(groupName) {
            return (<>Ваша заявка до групи <b>{groupName}</b> буде оброблена адміністратором згідно з чергою.</>);
        },
        school(groupName) {
            return (<>Ваша заявка до класу <b>{groupName}</b> буде оброблена адміністратором згідно з чергою.</>);
        },
        gurtok(groupName) {
            return (<>Ваша заявка до гуртка <b>{groupName}</b> буде оброблена адміністратором згідно з чергою.</>);
        }
    },

    enrollmentUUID: {
        kindergarten: 'Номер заявки',
        school: 'Номер заявки',
        gurtok: 'Номер заявки',
    },

    noGroups: {
        kindergarten: 'Групи відсутні',
        school: 'Класи відсутні',
        gurtok: 'Гуртки відсутні',
    },

    groupName: {
        kindergarten: 'Назва групи',
        school: 'Назва класу',
        gurtok: 'Назва гуртка',
    },

    currentGroupName: {
        kindergarten: 'Поточна назва групи',
        school: 'Поточна назва класу',
        gurtok: 'Поточна назва гуртка',
    },

    selectNewGroup: {
        kindergarten: 'Виберіть нову групу',
        school: 'Виберіть новий клас',
        gurtok: 'Виберіть новий гурток',
    },

    groupSize: {
        kindergarten: 'Розмір групи',
        school: 'Розмір класу',
        gurtok: 'Розмір гуртка',
    },

    deleteGroup: {
        kindergarten: 'Видалити групу',
        school: 'Видалити клас',
        gurtok: 'Видалити гурток',
    },

    changeEnrollmentGroup: {
        kindergarten: 'Змінити групу',
        school: 'Змінити клас',
        gurtok: 'Змінити гурток',
    },

    enrollmentGroupChanged: {
        kindergarten: 'Групу було успішно змінено',
        school: 'Клас було успішно змінено',
        gurtok: 'Гурток було успішно змінено',
    },
};

export default function t(key) {
    return resources[key][SERVICE_TYPE];
}