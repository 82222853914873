import React, { Component } from 'react';
import { Button, Icon, Tab } from 'semantic-ui-react';
import GroupCreateModal from '../group/GroupCreateModal';
import { canCreateGroup, isLocationAdmin, isRegionAdmin } from '../../services/AuthService';
import t from '../../services/Translate';
import GroupListSection from '../group/GroupListSection';
import LocationDetailsFormSection from './LocationDetailsFormSection';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import LocationAdminBreadcrumb from './LocationAdminBreadcrumb';
import PresenceFactsView from '../presence-report/PresenceFactsView';
import locationSettingsActions from '../../actions/location/settings';
import LargeDimmer from '../helpers/Dimmer';
import PresenceFactReportView from '../presence-report/PresenceFactReportView';

class LocationViewAdmin extends Component {

    state = { isDialogOpen: false };

    componentDidMount() {
        this.props.dispatch(locationSettingsActions.fetchLocationSettings(this.getLocationId()));
    }

    getLocationId = () => {
        if (this.props.match) {
            return parseInt(this.props.match.params.locationId, 10);
        }
        return null;
    };

    onGroupAdded = (period) => {
        this.setState({ period, isDialogOpen: false });
    };

    onGroupAdd = () => {
        this.setState({ isDialogOpen: true });
    };

    onCloseGroupAdd = () => {
        this.setState({ isDialogOpen: false });
    };

    /**
     *
     * @property regionName
     * @property createGroupPermission
     */
    render() {
        const { payload, isFetching } = this.props;

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (!payload) {
            return null;
        }

        const { id, createGroupPermission, foodReportAvailable, regionId } = payload;

        const panes = [
            {
                menuItem: t('locationGroups'), render: () => {
                    return (
                        <Tab.Pane>
                            <GroupListSection locationId={id}/>

                            {canCreateGroup(createGroupPermission) &&
                            <Button
                                primary
                                onClick={() => this.onGroupAdd()}>{t('addGroup')}</Button>
                            }

                            {(isRegionAdmin() || isLocationAdmin()) &&
                            <a
                                href={`/api/locations/${id}/enrollments/excel`}
                                download>
                                <Button
                                    icon
                                    labelPosition='right'
                                    color='olive'>
                                    <Icon name='file excel'/>Eкспорт в Excel
                                </Button>
                            </a>
                            }
                        </Tab.Pane>
                    );
                }
            },
        ];

        if(isLocationAdmin() || isRegionAdmin()) {
            panes.push({
                menuItem: t('locationProperties'), render: () => {
                    return (
                        <Tab.Pane>
                            <LocationDetailsFormSection locationId={id}/>
                        </Tab.Pane>
                    );
                }
            });
        }

        if (foodReportAvailable) {
            panes.push(
                {
                    menuItem: 'Відвідування', render: () => {
                        return (<PresenceFactsView locationId={id}/>);
                    }
                }
            );

            if (foodReportAvailable && isLocationAdmin()) {
                panes.push({
                    menuItem: 'Звіт з відвідування', render: () => {
                        return (
                            <Tab.Pane>
                                <PresenceFactReportView regionId={regionId}/>
                            </Tab.Pane>
                        );
                    }
                });
            }
        }

        return (
            <>
                <GroupCreateModal
                    isDialogOpen={this.state.isDialogOpen}
                    locationId={id}
                    onCloseGroupAdd={this.onCloseGroupAdd}
                    onGroupAdded={this.onGroupAdded}/>
                <LocationAdminBreadcrumb location={payload}/>
                <Tab panes={panes}/>
            </>
        );
    }
}

LocationViewAdmin.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
    return store.locationSettings;
}

export default connect(mapStateToProps)(LocationViewAdmin);