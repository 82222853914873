import Toast from '../helpers/Toasts';
import { getAddAdminToObjectError } from '../../utils/validation';
import DialogBase from './DialogBase';

class AddDialogBase extends DialogBase {

    isProgress = () => this.props.isUpdating;

    isDone = () => this.props.isUpdated || this.props.isUpdateFailed;

    isFailure = () => this.props.isUpdateFailed;

    // noinspection JSCheckFunctionSignatures
    componentDidUpdate(prevProps) {
        const { isUpdateFailed, isUpdated } = this.props;

        if (isUpdateFailed && !prevProps.isUpdateFailed) {
            this.closeDialog();
            Toast('error', getAddAdminToObjectError(this.props));
        }

        if (isUpdated && !prevProps.isUpdated) {
            this.closeDialog();
            Toast('success', this.getCompletedMessage());
        }
    }
}

export default AddDialogBase;