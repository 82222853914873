// react
import React, { Component } from 'react';

// semantic
import { Button, Form, Label, Segment } from 'semantic-ui-react';

// libs
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import locationActions from '../../actions/location';

// services
import t from '../../services/Translate';
import { isInRole, ROLE_REGION_ADMIN } from '../../services/AuthService';
import { getFieldError, isFieldError, } from '../../utils/validation';

// components
import LocationAdminListSection from './LocationAdminListSection';
import Toast from '../helpers/Toasts';
import LargeDimmer from '../helpers/Dimmer';
import Notification from '../helpers/Notification';
import SubRegionDropdown from '../subregion/SubRegionDropdown';

class LocationDetailsFormSection extends Component {

    state = {
        id: null,
        name: '',
        number: '',
        description: '',
        longDescription: '',
        subRegionId: null
    };

    componentDidMount() {
        this.props.dispatch(locationActions.fetchLocation(this.props.locationId));
    }

    componentDidUpdate(prevProps) {
        const { isUpdated, isFetched, payload } = this.props;

        if (isUpdated && !prevProps.isUpdated) {
            Toast('success', 'Дані успішно оновлені');
        }

        if (isFetched && !prevProps.isFetched) {
            this.setState({ ...payload });
        }
    }

    handleUpdateLocationClick = (event) => {
        event.preventDefault();
        this.props.dispatch(locationActions.updateLocation({ ...this.state }));
    };

    handleChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    render() {

        const { isFetchFailed, isFetching, isUpdating } = this.props;

        const { id, name, number, description, longDescription, subRegionId, regionId } = this.state;

        if (isFetchFailed) {
            return (
                <Segment><Notification.Error/></Segment>
            );
        }

        if (!id) {
            return null;
        }

        if (isFetching) {
            return <LargeDimmer text='Завантаження данних'/>;
        }

        if (isUpdating) {
            return <LargeDimmer text='Збереження даних'/>;
        }

        return (
            <>
                <Segment>
                    <Form>
                        <Form.Field>
                            <label>Назва</label>
                            <Form.Input
                                name='name'
                                value={name}
                                error={isFieldError(this.props, 'name')}
                                onChange={this.handleChange}
                            />

                            {isFieldError(this.props, 'name') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'name')}</Label>}
                        </Form.Field>

                        <Form.Field>
                            <label>Номер</label>
                            <Form.Input
                                name='number'
                                type='number'
                                value={number}
                                error={isFieldError(this.props, 'number')}
                                onChange={this.handleChange}
                            />

                            {isFieldError(this.props, 'number') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'number')}</Label>}
                        </Form.Field>

                        <SubRegionDropdown
                            regionId={regionId}
                            value={subRegionId}
                            onChange={this.handleChange}/>

                        <Form.Field>
                            <Form.TextArea
                                name='description'
                                value={description}
                                label={t('locationDescriptionLabel')}
                                error={isFieldError(this.props, 'description')}
                                placeholder={t('locationDescriptionPlaceholder')}
                                onChange={this.handleChange}
                            />

                            {isFieldError(this.props, 'description') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'description')}</Label>}
                        </Form.Field>

                        <Form.Field>
                            <Form.TextArea
                                name='longDescription'
                                value={longDescription}
                                label={t('locationLongDescriptionLabel')}
                                error={isFieldError(this.props, 'longDescription')}
                                placeholder={t('locationLongDescriptionPlaceholder')}
                                onChange={this.handleChange}
                                rows={6}
                            />

                            {isFieldError(this.props, 'longDescription') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'longDescription')}</Label>}
                        </Form.Field>

                        <Button type='button' positive onClick={this.handleUpdateLocationClick}>
                            Зберегти
                        </Button>
                    </Form>
                </Segment>

                {isInRole([ROLE_REGION_ADMIN]) && this.props.isFetched &&
                <LocationAdminListSection
                    targetId={this.props.locationId}
                    targetName={name}
                />
                }
            </>
        );
    }
}

LocationDetailsFormSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
    return store.location;
}

export default connect(mapStateToProps)(LocationDetailsFormSection);