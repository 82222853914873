import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import { enrollmentGroupError, isEnrollmentGroupError } from '../../utils/validation';
import t from '../../services/Translate';
import { fetchGroupsByLocationId } from '../../actions/group/fetchGroupsByLocationId';
import _ from 'lodash';

class GroupDropdownSection extends Component {

    componentDidMount() {

        const { dispatch, locationId, fetchGroups} = this.props;

        const { isFetched, isFetching } = fetchGroups;

        if (isFetched || isFetching) {
            return;
        }

        dispatch(fetchGroupsByLocationId(locationId));
    }

    render() {

        const { fetchGroups, onChange, groupId, disabled } = this.props;
        const { isFetching, groups } = fetchGroups;

        if (_.isEmpty(groups) || isFetching) {
            return null;
        }

        const groupList = groups.map(group => {
            return {
                key: group.id,
                value: group.id,
                text: `${group.name} - ${group.period} р.`
            };
        });

        return (
            <Form.Field>
                <label>{t('groupName')}</label>
                <Dropdown
                    name='groupId'
                    options={groupList}
                    placeholder={t('groupName')}
                    selection
                    fluid
                    onChange={onChange}
                    value={groupId}
                    disabled={disabled}
                />
                {isEnrollmentGroupError(this.props.enrollmentEdit) &&
                <Label color='red' pointing>
                    {enrollmentGroupError(this.props.enrollmentEdit)}
                </Label>
                }
            </Form.Field>
        );
    }
}

// TODO missed locationId and others
GroupDropdownSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    groupId: PropTypes.number,
    disabled: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        fetchGroups: state.fetchGroupsByLocationId,
        enrollmentEdit: state.enrollmentEdit
    };
}

export default connect(mapStateToProps)(GroupDropdownSection);