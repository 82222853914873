import { SET_STEP } from '../actions/tour';

export default function tour(state = {
    step: 0,
}, action) {

    switch (action.type) {
    case SET_STEP:
        return {
            ...state,
            step: action.step
        };
    default:
        return state;
    }
}