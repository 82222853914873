import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
    ADD_ATTACHMENT,
    addAttachment,
    DELETE_ATTACHMENT,
    deleteAttachment,
    fetchAttachments
} from './attachments-action';
import { Button, Form, Header, List, Segment } from 'semantic-ui-react';
import { getErrorMessage, isFieldError } from '../../utils/validation';
import LargeDimmer from '../helpers/Dimmer';
import { isUser } from '../../services/AuthService';
import _ from 'lodash';
import Toast from '../helpers/Toasts';
import { Dispatch } from 'redux';
import { AttachmentParams, AttachmentState, IAttachment } from '../../shared/attachment.model';
import { SUCCESS } from '../../shared/action-type';
import { State } from '../../shared/store.model';

interface AttachmentsProps extends State<IAttachment>, AttachmentParams {
    dispatch: Dispatch<any>
}

class Attachments extends Component<AttachmentsProps, AttachmentState> {

    state = { file: null };

    componentDidMount() {
        this.props.dispatch(fetchAttachments(this.props));
    }

    componentDidUpdate(prevProps: AttachmentsProps) {
        const { completed, errorCode, actionType } = this.props;

        if (completed && !prevProps.completed && actionType === SUCCESS(ADD_ATTACHMENT)) {
            this.setState({ file: null });
            Toast('success', 'Файл завантажено');
        }

        if (completed && !prevProps.completed && actionType === SUCCESS(DELETE_ATTACHMENT)) {
            Toast('success', 'Файл видалено');
        }

        if (errorCode && !prevProps.errorCode) {
            this.setState({ file: null });
            Toast('error', getErrorMessage(errorCode));
        }
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const { files } = event.target;
        if (files && files.length) {
            this.setState({ file: files[0] });
        }
    };

    onDelete = (fileId: number) => {
        const params = this.props;
        this.props.dispatch(deleteAttachment(fileId, params));
    };

    onSubmit = (event: React.MouseEvent) => {
        event.preventDefault();

        const params = this.props;
        const { file } = this.state;
        this.props.dispatch(addAttachment(file, params));
    };

    renderFilesList = (files: readonly IAttachment[]) => {

        return (
            <>
                {_.isEmpty(files) ?
                    <Header as='h2' content='Документи не додані'/>
                    :
                    <Header as='h2' content='Документи дитини'/>}

                <Header as='h5' content='Додайте копію документу про місце реєстрації дитини'/>
                <List celled>
                    {files.map(file => {
                        return (
                            <List.Item key={file.id}>
                                {file.description}
                                <Segment basic floated='right'>
                                    <a
                                        href={`/api/attachments/${file.id}`}
                                        target='_blank'
                                        rel='noopener noreferrer'>переглянути</a>
                                    &nbsp;
                                    {file.type === 'CHILD' && isUser() &&
                                    <a
                                        href={void (0)}
                                        style={{ color: '#faa' }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.onDelete(file.id);
                                        }}>видалити</a>
                                    }
                                </Segment>
                            </List.Item>
                        );
                    })}
                </List>
            </>
        );
    };

    render() {

        const { entities, loading } = this.props;

        if (!entities) {
            return null;
        }

        if (loading) {
            return (<LargeDimmer text='Оновлення даних'/>);
        }

        return (
            <>
                {this.renderFilesList(entities)}

                {isUser() && entities.length < 10 &&
                <Form>
                    <Form.Field>
                        <label>Виберіть файл (розмір файлу до 1МБ)</label>
                        <Form.Input
                            name='file'
                            type='file'
                            error={isFieldError(this.props, 'file')}
                            onChange={this.onChange}/>
                    </Form.Field>
                    <Button
                        data-testid='add-attachment'
                        type='button'
                        disabled={this.state.file === null}
                        onClick={this.onSubmit}>Завантажити</Button>
                </Form>
                }
            </>
        );
    }
}

function mapStateToProps(state: any) {
    return state.attachments;
}

export default connect(mapStateToProps)(Attachments);