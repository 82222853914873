import {
    FETCH_REGIONS_PAGINATED_FAILURE,
    FETCH_REGIONS_PAGINATED_REQUEST,
    FETCH_REGIONS_PAGINATED_SUCCESS
} from '../../actions/region/fetchRegionsPaginated';


const initialState = {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    payload: {},
};
export default function fetchRegionsPaginated(state = initialState, action) {

    switch (action.type) {
    case FETCH_REGIONS_PAGINATED_REQUEST:
        return {
            ...action,
            isFetching: true,
            isFetched: false,
            isFailed: false,
            payload: {}
        };
    case FETCH_REGIONS_PAGINATED_SUCCESS:
        return {
            ...action,
            isFetching: false,
            isFetched: true,
            isFailed: false
        };
    case FETCH_REGIONS_PAGINATED_FAILURE:
        return {
            ...action,
            isFetching: false,
            isFetched: false,
            isFailed: true,
            payload: {},
        };
    default:
        return state;
    }
}