import { createDownloadAction } from '../generic/downloadAction';
import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';

export const OPEN_DATA_REPORT_XLS = 'OPEN_DATA_REPORT_XLS';

export default {
    download: createDownloadAction(regionId => {
        return axios.get(`/api/regions/${regionId}/open-data`, { ...REQUEST_SETTINGS, responseType: 'blob' });
    }, OPEN_DATA_REPORT_XLS, 'open_data_report.xls')
};