import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Form } from 'semantic-ui-react';
import regionsAction from '../../actions/subregion';
import _ from 'lodash';

class SubRegionDropdown extends Component {

    componentDidMount() {
        const { dispatch, regionId, isFetched, isFetching } = this.props;

        if (isFetched || isFetching) {
            return;
        }

        dispatch(regionsAction.fetchAll(regionId));
    }

    render() {
        const { payload, onChange, value, isFetching, isFetched } = this.props;

        if (isFetching || !isFetched || _.isEmpty(payload)) {
            return null;
        }

        const subregions = payload.map(subregion => {
            return {
                key: subregion.id,
                value: subregion.id,
                text: subregion.name
            };
        });

        return (
            <Form.Field>
                <label>Район</label>
                <Dropdown
                    name='subRegionId'
                    options={subregions}
                    placeholder='Оберить район'
                    selection
                    fluid
                    onChange={onChange}
                    value={value}
                />
            </Form.Field>
        );
    }
}

SubRegionDropdown.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
    value: PropTypes.number
};

function mapStateToProps(state) {
    return state.subregions;
}

export default connect(mapStateToProps)(SubRegionDropdown);