import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Form, Grid, Header, Input, Label, Segment } from 'semantic-ui-react';
import { doGetUserInfo, doUpdateUserInfo } from '../actions/userInfo';
import { getFieldError, isFieldError } from '../utils/validation';
import LargeDimmer from './helpers/Dimmer';
import Toast from './helpers/Toasts';
import _ from 'lodash';

class UserInfo extends Component {

    state = {
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        phoneNumber: '',
        additionalPhoneNumber: '',
        regionId: null,
    };

    componentDidMount() {
        this.props.dispatch(doGetUserInfo());
    }

    componentDidUpdate(prevProps) {
        const { userInfoData, isFetched, isUpdated } = this.props;

        if (isFetched && !prevProps.isFetched) {
            this.setState({ ...userInfoData });
        }

        if (isUpdated && !prevProps.isUpdated) {
            this.setState({ ...userInfoData });
            Toast('success', 'Ваші дані введені коректно і були успішно збережені');
        }
    }

    handleChange = (event, { name, value }) => {
        event.preventDefault();

        this.setState({ [name]: value });
    };


    handleSubmitClick = (event) => {
        event.preventDefault();
        this.props.dispatch(doUpdateUserInfo(this.state));
    };

    getRegionOptions = () => {
        return _.map(this.state.regions, region => {
            return {
                text: region.name,
                value: region.id,
                key: region.id
            };
        });
    };

    /**
     * @property openForRegistrations
     */
    render() {

        if (this.props.isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (this.props.isUpdating) {
            return <LargeDimmer text='Збереження даних'/>;
        }

        const { regionId, regions } = this.state;

        if (!regions) {
            return null;
        }

        const selectedRegion = _.find(regions, (region) => region && region.id === regionId);

        return (
            <Grid centered>
                <Grid.Column computer={12} laptop={14} mobile={16}>
                    <Segment>
                        <Header as='h2'>Дані користувача</Header>
                        <Form id='user-info'>
                            <Form.Field>
                                <label>Регіон</label>
                                <Form.Dropdown
                                    name='regionId'
                                    value={regionId}
                                    error={isFieldError(this.props, 'regionId')}
                                    placeholder='Оберіть регіон'
                                    fluid
                                    selection
                                    options={this.getRegionOptions()}
                                    onChange={this.handleChange}
                                />

                                {isFieldError(this.props, 'regionId') &&
                                <Label color='red' pointing>{getFieldError(this.props, 'regionId')}</Label>}
                                {selectedRegion && !selectedRegion.openForRegistrations &&
                                <Label color='red' pointing>
                                    Обраний регіон знаходиться в стадії підключення, не всі навчальні заклади можуть бути доступні для вибору.
                                </Label>
                                }
                                {selectedRegion && selectedRegion.locked &&
                                <Label color='red' pointing>
                                    Обраний регіон відключений від системи черг. За уточненнями та поясненнями будь ласка зверніться в міську адміністрацію.
                                </Label>
                                }
                            </Form.Field>

                            <Form.Field>
                                <label>Прізвище</label>
                                <Form.Input
                                    name='lastName'
                                    value={this.state.lastName}
                                    error={isFieldError(this.props, 'lastName')}
                                    onChange={this.handleChange}/>

                                {isFieldError(this.props, 'lastName') &&
                                <Label color='red' pointing>{getFieldError(this.props, 'lastName')}</Label>}
                            </Form.Field>

                            <Form.Field>
                                <label>Ім'я</label>
                                <Form.Input
                                    name='firstName'
                                    value={this.state.firstName}
                                    error={isFieldError(this.props, 'firstName')}
                                    onChange={this.handleChange}/>

                                {isFieldError(this.props, 'firstName') &&
                                <Label color='red' pointing>{getFieldError(this.props, 'firstName')}</Label>}
                            </Form.Field>

                            <Form.Field>
                                <label>По батькові</label>
                                <Form.Input
                                    name='middleName'
                                    value={this.state.middleName}
                                    error={isFieldError(this.props, 'middleName')}
                                    onChange={this.handleChange}/>

                                {isFieldError(this.props, 'middleName') &&
                                <Label color='red' pointing>{getFieldError(this.props, 'middleName')}</Label>}
                            </Form.Field>

                            <Form.Field>
                                <label>Адреса</label>
                                <Form.Input
                                    name='address'
                                    value={this.state.address}
                                    error={isFieldError(this.props, 'address')}
                                    onChange={this.handleChange}/>

                                {isFieldError(this.props, 'address') &&
                                <Label color='red' pointing>{getFieldError(this.props, 'address')}</Label>}
                            </Form.Field>

                            <Form.Field>
                                <label>Номер телефону</label>
                                <Input
                                    name='phoneNumber'
                                    type='number'
                                    label='+380'
                                    value={this.state.phoneNumber}
                                    error={isFieldError(this.props, 'phoneNumber')}
                                    onChange={this.handleChange}/>

                                {isFieldError(this.props, 'phoneNumber') &&
                                <Label color='red' pointing>{getFieldError(this.props, 'phoneNumber')}</Label>}
                            </Form.Field>

                            <Form.Field>
                                <label>Додатковий номер телефону</label>
                                <Input
                                    name='additionalPhoneNumber'
                                    type='number'
                                    label='+380'
                                    value={this.state.additionalPhoneNumber}
                                    error={isFieldError(this.props, 'additionalPhoneNumber')}
                                    onChange={this.handleChange}/>

                                {isFieldError(this.props, 'additionalPhoneNumber') &&
                                <Label
                                    color='red'
                                    pointing>{getFieldError(this.props, 'additionalPhoneNumber')}</Label>}
                            </Form.Field>
                            <Button
                                type='button' primary
                                onClick={this.handleSubmitClick}>Зберегти</Button>

                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

UserInfo.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.userInfo;
}

export default connect(mapStateToProps)(UserInfo);