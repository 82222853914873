import React from 'react';
import { isRegionAdmin } from '../../services/AuthService';
import { Breadcrumb, Divider, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const EnrollmentDetailsBreadcrumb = ({ group, enrollmentId }) => (
    <>
        <Breadcrumb>
            {isRegionAdmin() &&
            <>
                <Breadcrumb.Section>
                    <Header as='h5'>
                        <Link to={`/regions/${group.regionId}`}>
                            {group.regionName}
                        </Link>
                    </Header>
                </Breadcrumb.Section>

                <Breadcrumb.Divider icon='right chevron'/>
            </>
            }

            <Breadcrumb.Section>
                <Header as='h5'>
                    <Link to={`/admin/locations/${group.locationId}`}>
                        {group.locationName}
                    </Link>
                </Header>
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon='right chevron'/>

            <Breadcrumb.Section>
                <Header as='h5'>
                    <Link to={`/admin/locations/${group.locationId}/groups/${group.id}`}>
                        {group.name}
                    </Link>
                </Header>
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon='right chevron'/>

            <Breadcrumb.Section active>
                <Header as='h5'>Заявка № {enrollmentId}</Header>
            </Breadcrumb.Section>

        </Breadcrumb>
        <Divider/>
    </>
);