import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import RegionsTable from './RegionsTable';

class RegionsAdminView extends Component {

    render() {

        return (
            <>
                <Header as='h2' content='Регіони'/>

                <Grid centered>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <RegionsTable/>
                    </Grid.Column>
                </Grid>
            </>
        );
    }
}

RegionsAdminView.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return state.fetchRegionsPaginated;
}

export default connect(mapStateToProps)(RegionsAdminView);