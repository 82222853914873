import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';

export const LIST_REGIONS_REQUEST = 'LIST_REGIONS_REQUEST';
export const LIST_REGIONS_SUCCESS = 'LIST_REGIONS_SUCCESS';
export const LIST_REGIONS_FAIL = 'LIST_REGIONS_FAIL';

function listRegionsRequest() {
    return { type: LIST_REGIONS_REQUEST };
}

function listRegionsSuccess(res) {

    const regions = res.data.map(region => {
        return {
            id: region.id,
            title: region.name,
            description: region.description
        };
    });

    return { type: LIST_REGIONS_SUCCESS, regions };
}

function listRegionsFail(res) {

    const { errorCode } = getResponseData(res);

    return { type: LIST_REGIONS_FAIL, errorCode };
}

export function doListRegions({ query }) {

    return async function (dispatch) {

        dispatch(listRegionsRequest());

        return axios
            .get('/api/regions/search', createGetParams({ query }))
            .then(res => dispatch(listRegionsSuccess(res)))
            .catch(res => dispatch(listRegionsFail(res)));
    };
}