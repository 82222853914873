import {
    FETCH_ENROLLMENT_FAILURE,
    FETCH_ENROLLMENT_REQUEST,
    FETCH_ENROLLMENT_RESET,
    FETCH_ENROLLMENT_SUCCESS,
} from '../../actions/enrollment/fetchEnrollment';


const initialState = {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    payload: {},
    errorCode: null,
};

export default function fetchEnrollment(state = initialState, action) {

    switch (action.type) {
    case FETCH_ENROLLMENT_RESET:
        return initialState;
    case FETCH_ENROLLMENT_REQUEST:
        return {
            ...state,
            isFetching: true,
            isFetched: false,
            isFailed: false,
            payload: {},
            errorCode: null,
        };

    case FETCH_ENROLLMENT_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetched: true,
            isFailed: false,
            payload: action.payload,
            errorCode: null,
        };

    case FETCH_ENROLLMENT_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            isFailed: true,
            payload: {},
            errorCode: action.errorCode,
        };
    default:
        return state;
    }
}