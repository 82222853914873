import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Icon, Label, Modal } from 'semantic-ui-react';
import { doCreateGroup, doResetGroupCreation } from '../../actions/group/createGroup';
import { MAX_ADDED_NUM_FOR_GROUP_PERIOD } from '../../const/variables';
import { getFieldError, groupAddingError, isFieldError, isGroupAddingError, } from '../../utils/validation';
import t from '../../services/Translate';
import LargeDimmer from '../helpers/Dimmer';
import Notification from '../helpers/Notification';
import GroupAgeDropDown from '../group-age/GroupAgeDropdown';

const initialState = {
    name: '',
    period: '',
    maxChildren: '',
    maxPrivilegedChildren: '',
};

const CURRENT_YEAR = new Date().getFullYear();

const PERIOD_OPTIONS = [];

for (let i = -1; i < MAX_ADDED_NUM_FOR_GROUP_PERIOD; i++) {
    PERIOD_OPTIONS.push({
        text: `${CURRENT_YEAR + i} — ${CURRENT_YEAR + i + 1}`,
        value: CURRENT_YEAR + i,
        key: CURRENT_YEAR + i
    });
}

class GroupCreateModal extends Component {

    state = {
        ...initialState
    };

    closeGroupCreation = (event) => {
        event.preventDefault();

        this.clearStateAndPropsErrors();

        this.props.onCloseGroupAdd();
    };

    closeGroupCreationWithOk = (event) => {
        event.preventDefault();

        this.clearStateAndPropsErrors();

        this.props.onGroupAdded(this.state.period);
    };

    clearStateAndPropsErrors = () => {
        this.setState({ ...initialState });
        this.props.dispatch(doResetGroupCreation());
    };

    componentWillUnmount() {
        this.props.dispatch(doResetGroupCreation());
    }

    handleSubmitClick = (event) => {
        event.preventDefault();

        const { locationId } = this.props;

        this.props.dispatch(doCreateGroup({ ...this.state, locationId }));
    };

    handleChange = (event, { name, value }) => {
        event.preventDefault();

        this.setState({ [name]: value });
    };

    render() {
        const { isDialogOpen, locationId } = this.props;

        return (
            <Modal size='small' open={isDialogOpen} centered={false}>
                <Modal.Header>{t('addGroup')}</Modal.Header>
                <Modal.Content>

                    {this.props.isCreated &&
                    <Notification.Success header='Збережено' content={t('groupAdded')}/>}

                    {this.props.isCreating &&
                    <LargeDimmer text='Збереження даних'/>}

                    {!this.props.isCreated &&
                    <Form>
                        <Form.Field>
                            <label>Назва</label>
                            <Form.Input
                                name='name'
                                value={this.state.name}
                                error={isFieldError(this.props, 'name')}
                                onChange={this.handleChange}
                            />
                            {isFieldError(this.props, 'name') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'name')}</Label>}
                        </Form.Field>

                        <GroupAgeDropDown onChange={this.handleChange} locationId={locationId}/>

                        <Form.Field>
                            <label>Період</label>
                            <Form.Dropdown
                                name='period'
                                value={this.state.period}
                                error={isFieldError(this.props, 'period')}
                                placeholder='Рік'
                                fluid
                                selection
                                options={PERIOD_OPTIONS}
                                onChange={this.handleChange}
                            />

                            {isFieldError(this.props, 'period') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'period')}</Label>}

                            {isGroupAddingError(this.props) &&
                            <Label color='red' pointing>{groupAddingError(this.props)}</Label>}
                        </Form.Field>

                        <Form.Field>
                            <label>Максимальна кількість дітей</label>
                            <Form.Input
                                type='number'
                                name='maxChildren'
                                value={this.state.maxChildren}
                                error={isFieldError(this.props, 'maxChildren')}
                                onChange={this.handleChange}
                            />

                            {isFieldError(this.props, 'maxChildren') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'maxChildren')}</Label>}

                        </Form.Field>

                        <Form.Field>
                            <label>Зарезервовано пільгових місць</label>
                            <Form.Input
                                type='number'
                                name='maxPrivilegedChildren'
                                value={this.state.maxPrivilegedChildren}
                                error={isFieldError(this.props, 'maxPrivilegedChildren')}
                                onChange={this.handleChange}
                            />

                            {isFieldError(this.props, 'maxPrivilegedChildren') &&
                            <Label color='red' pointing>{getFieldError(this.props, 'maxPrivilegedChildren')}</Label>}

                        </Form.Field>

                    </Form>

                    }
                </Modal.Content>
                {this.props.isCreated &&
                <Modal.Actions>
                    <Button primary onClick={this.closeGroupCreationWithOk}>OK</Button>
                </Modal.Actions>
                }

                {!this.props.isCreated &&
                <Modal.Actions>

                    <Button
                        primary
                        type='button'
                        disabled={false}
                        onClick={this.handleSubmitClick}
                        animated='fade'>
                        <Button.Content visible>Додати</Button.Content>
                        <Button.Content hidden><Icon name='add'/> Додати</Button.Content>
                    </Button>

                    <Button secondary animated onClick={this.closeGroupCreation}>
                        <Button.Content visible>Відміна</Button.Content>
                        <Button.Content hidden>
                            <Icon name='arrow left'/>
                        </Button.Content>
                    </Button>

                </Modal.Actions>
                }
            </Modal>
        );
    }
}

GroupCreateModal.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.createGroup;
}

export default connect(mapStateToProps)(GroupCreateModal);