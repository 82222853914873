import { getChildFromState, getResponseData } from '../utils';
import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { formatDateToLocalDateString } from '../../utils/dateTime';
import { transform } from '.';
import _ from 'lodash';

export const CREATE_ENROLLMENT_REQUEST = 'CREATE_ENROLLMENT_REQUEST';
export const CREATE_ENROLLMENT_SUCCESS = 'CREATE_ENROLLMENT_SUCCESS';
export const CREATE_ENROLLMENT_FAILURE = 'CREATE_ENROLLMENT_FAILURE';
export const CREATE_ENROLLMENT_RESET = 'CREATE_ENROLLMENT_RESET';

export function createEnrollmentReset() {
    return { type: CREATE_ENROLLMENT_RESET };
}

function createEnrollmentRequest() {
    return { type: CREATE_ENROLLMENT_REQUEST };
}

function createEnrollmentSuccess(res) {
    const createdEnrollment = transform(res.data);
    return { type: CREATE_ENROLLMENT_SUCCESS, createdEnrollment };
}

function createEnrollmentFailure(error) {
    const { errorCode, fieldErrors, errorResponse } = getResponseData(error);
    return { type: CREATE_ENROLLMENT_FAILURE, errorCode, fieldErrors, errorResponse };
}

export function doCreateEnrollment(state) {
    const enrollment = {
        created: formatDateToLocalDateString(state.created),
        groupId: state.groupId,
        prioritized: state.prioritized,
        parentEmail: _.trim(state.parentEmail),
        child: getChildFromState(state)
    };

    return async function (dispatch) {

        dispatch(createEnrollmentRequest(enrollment));

        return axios
            .post('/api/enrollments/create', enrollment, REQUEST_SETTINGS)
            .then(res => dispatch(createEnrollmentSuccess(res)))
            .catch(error => dispatch(createEnrollmentFailure(error)));
    };
}