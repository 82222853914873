import React, { Component } from 'react';
import { Button, Message, Modal } from 'semantic-ui-react';
import LargeDimmer from '../helpers/Dimmer';

class DialogBase extends Component {

    getHeaderText = () => {
        if (this.isProgress()) {
            return 'Обробка запиту';
        } else {
            return 'Підтвердження';
        }
    };

    closeDialog = () => {
        this.props.onClose();
        this.resetDialog();
    };

    renderActionButtons = () => {
        const isDone = this.isDone();

        return (
            <Modal.Actions>
                {isDone &&
                <Button primary onClick={() => this.closeDialog()} content='Закрити'/>}

                {!isDone &&
                <>
                    <Button secondary onClick={() => this.closeDialog()} content='Відміна'/>
                    <Button primary onClick={this.onSubmit} content='Так'/>
                </>
                }
            </Modal.Actions>);
    };

    render() {
        const { isDialogOpen } = this.props;

        const isProgress = this.isProgress();
        const isFailed = this.isFailure();

        return (
            <Modal size='small' open={isDialogOpen} centered={false}>
                <Modal.Header>{this.getHeaderText()}</Modal.Header>
                <Modal.Content>
                    {isProgress &&
                    <LargeDimmer text='Обробка запиту'/>
                    }

                    {!isProgress &&
                    <Message content={this.getContent()} positive={!isFailed} error={isFailed}/>
                    }

                </Modal.Content>
                {this.renderActionButtons()}
            </Modal>
        );
    }

}

export default DialogBase;