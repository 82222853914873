import { Action, ResponseDataList } from '../../shared/store.model';
import { Enrollment } from '../../shared/enrollment.model';
import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from '../../shared/action-type';
import { ErrorResponse } from '../../shared/error-response';
import { REQUEST_SETTINGS } from '../../utils/settings';

export const FETCH_USER_ENROLLMENTS = 'FETCH_USER_ENROLLMENTS';

type UserEnrollmentsResponse = ResponseDataList<Enrollment>;

export function startFetchUserEnrollments(): Action<Enrollment> {
    return { type: REQUEST(FETCH_USER_ENROLLMENTS) };
}

export function completeFetchUserEnrollments(response: UserEnrollmentsResponse): Action<Enrollment> {
    return { type: SUCCESS(FETCH_USER_ENROLLMENTS), entities: response.data };
}

export function failFetchUserEnrollments({ errorCode }: ErrorResponse) {
    return { type: FAILURE(FETCH_USER_ENROLLMENTS), errorCode };
}

export function fetchFetchUserEnrollments() {

    return async function (dispatch: any) {

        dispatch(startFetchUserEnrollments());

        return axios
            .get('/api/enrollments/me', REQUEST_SETTINGS)
            .then((res: UserEnrollmentsResponse) => dispatch(completeFetchUserEnrollments(res)))
            .catch(({ response }) => {
                dispatch(failFetchUserEnrollments(response.data))
            });
    };
}