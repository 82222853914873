import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';
import { transform } from '.';


export const FETCH_ENROLLMENT_REQUEST = 'FETCH_ENROLLMENT_REQUEST';
export const FETCH_ENROLLMENT_SUCCESS = 'FETCH_ENROLLMENT_SUCCESS';
export const FETCH_ENROLLMENT_FAILURE = 'FETCH_ENROLLMENT_FAILURE';
export const FETCH_ENROLLMENT_RESET = 'FETCH_ENROLLMENT_RESET';

export function fetchEnrollmentReset() {
    return { type: FETCH_ENROLLMENT_RESET };
}

function fetchEnrollmentRequest() {
    return { type: FETCH_ENROLLMENT_REQUEST };
}

function fetchEnrollmentSuccess(res) {
    const payload = transform(res.data);
    return { type: FETCH_ENROLLMENT_SUCCESS, payload };
}

function fetchEnrollmentFailure(res) {
    const { errorCode } = getResponseData(res);
    return { type: FETCH_ENROLLMENT_FAILURE, errorCode };
}

export function fetchEnrollment(enrollmentId) {

    return async function (dispatch) {

        dispatch(fetchEnrollmentRequest());

        return axios
            .get(`/api/enrollments/${enrollmentId}`, createGetParams({ enrollmentId }))
            .then(res => dispatch(fetchEnrollmentSuccess(res)))
            .catch(res => dispatch(fetchEnrollmentFailure(res)));
    };
}