// react
import React, { Component } from 'react';
// libs
import _ from 'lodash';
import PropTypes from 'prop-types';
// services
import EnrollmentStatusUpdate from './EnrollmentStatusUpdate';

class EnrollmentButtons extends Component {

    render() {
        const { enrollment, onReload } = this.props;
        const { allowableStatuses } = enrollment;

        if (_.isEmpty(allowableStatuses)) {
            return null;
        }

        return (
            <>
                {allowableStatuses.map((nextStatus, i) => {
                    return (
                        <EnrollmentStatusUpdate
                            key={i}
                            enrollment={enrollment}
                            enrollmentStatus={nextStatus}
                            onDone={onReload}
                        />
                    );
                })}
            </>
        );
    }
}

EnrollmentButtons.propTypes = {
    enrollment: PropTypes.object.isRequired,
    enrollmentStatus: PropTypes.string.isRequired,
    onReload: PropTypes.func.isRequired
};

export default EnrollmentButtons;