// react
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
// semantic
import { Header, Label, Menu, Tab } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';
import _ from 'lodash';
// components
import { CANCELLED, REJECTED } from '../../const/variables';
import EnrollmentsTableSection from './EnrollmentsTableSection';

const waitingStatus = ['RECEIVED', 'IN_PROGRESS', 'CONFIRM_REQUEST', 'REJECT_REQUEST'];
const confirmedStatus = ['CONFIRMED'];
const canceledStatus = ['CANCELLED', 'REJECTED'];

/**
 * @property childStatus
 */

class EnrollmentsTable extends Component {

    splitEnrollmentsToPanes = (enrollments, stats) => {

        const activeRegularCount = stats.activeRegularEnrollmentsCount || 0;
        const activePrivilegedCount = stats.activePrivilegedEnrollmentsCount || 0;

        const confirmedRegularCount = stats.confirmedRegularEnrollmentsCount || 0;
        const confirmedPrivilegedCount = stats.confirmedPrivilegedEnrollmentsCount || 0;

        const menuItems = [
            (
                <Menu.Item key='waiting'>
                    В черзі<Label>{activeRegularCount + activePrivilegedCount}</Label>
                </Menu.Item>
            ),
            (
                <Menu.Item key='confirmed'>
                    Прийняті<Label>{confirmedRegularCount + confirmedPrivilegedCount}</Label>
                </Menu.Item>
            ),
            (
                <Menu.Item key='rejected'>
                    Скасовані
                    <Label>
                        {enrollments.filter((enrollment) => {
                            return enrollment.enrollmentStatus === REJECTED
                                || enrollment.enrollmentStatus === CANCELLED;
                        }).length}
                    </Label>
                </Menu.Item>
            ),
        ];

        const groupStatuses = [waitingStatus, confirmedStatus, canceledStatus];

        const enrollmentsSorted = _.sortBy(enrollments,
            ['priority', 'created', 'id'],
            ['asc', 'desc', 'desc']);

        const result = groupStatuses.map(statuses =>
            enrollmentsSorted.filter(({ enrollmentStatus }) => statuses.includes(enrollmentStatus))
        );

        return result.map((sortedEnrollments, i) => ({
            menuItem: menuItems[i],
            render: () =>
                <EnrollmentsTableSection
                    enrollments={sortedEnrollments}
                    onReload={this.props.onReload}
                />
        }));
    };

    render() {

        const { enrollments, stats } = this.props;

        if (enrollments.length === 0) {
            return null;
        }

        return (
            <>
                <Header as='h3'>Заявки на вступ</Header>
                <Tab panes={this.splitEnrollmentsToPanes(enrollments, stats)}/>
            </>
        );
    }
}

EnrollmentsTable.propTypes = {
    enrollments: PropTypes.array.isRequired,
    stats: PropTypes.object.isRequired,
    onReload: PropTypes.func.isRequired,
};

export default connect()(EnrollmentsTable);