import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { getResponseData } from '../utils';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export function doDeleteGroup(groupId) {

    return async function (dispatch) {

        dispatch(deleteGroupRequest());

        return axios
            .delete(`/api/groups/${groupId}`, REQUEST_SETTINGS)
            .then(() => dispatch(deleteGroupSuccess()))
            .catch(error => dispatch(deleteGroupFailure(error)));
    };

    function deleteGroupRequest() {
        return { type: DELETE_GROUP_REQUEST };
    }

    function deleteGroupSuccess() {
        return { type: DELETE_GROUP_SUCCESS };
    }

    function deleteGroupFailure(res) {

        const { errorCode } = getResponseData(res);

        return { type: DELETE_GROUP_FAILURE, errorCode };
    }
}