import { combineReducers } from 'redux';
import authentication from './login';
import registration from './register';
import confirmation from './confirm';
import userInfo from './userInfo';
import sendPasswordResetEmail from './email/sendPasswordResetEmail';
import resendConfirmationEmail from './email/resendConfirmationEmail';
import resetPassword from './resetPassword';
import listChildren from './child/listChildren';
import listRegions from './region/listRegions';
import listLocations from './location/listLocations';
import fetchRegionsPaginated from './region/fetchRegionsPaginated';
import locationView from './location/locationView';
import applyToGroup from './enrollment/applyToGroup';
import region from './region';
import locationsForAdmin from './location/locationsForAdmin';
import createGroup from './group/createGroup';
import updateGroup from './group/updateGroup';
import fetchGroupsByLocationId from './group/fetchGroupsByLocationId';
import fetchEnrollment from './enrollment/fetchEnrollment';
import enrollmentStatusUpdate from './enrollment/enrollmentStatusUpdate';
import eventList from './event/eventList';
import deleteGroup from './group/deleteGroup';
import enrollmentEdit from './enrollment/enrollmentEdit';
import enrollmentCreate from './enrollment/enrollmentCreate';
import createChild from './child/createChild';
import fetchChild from './child/fetchChild';
import updateChild from './child/updateChild';
import fetchEnrollmentsForAttention from './enrollment/fetchEnrollmentsForAttention';
import fetchGroup from './group/fetchGroup';
import fetchRelatedEnrollments from './enrollment/fetchRelatedEnrollments';
import fetchPrivileges from './child/privileges';
import accountDetails from './accountDetails';
import tour from './tour';
import attachments from '../components/attachment/attachments-reducer';
import userEnrollments from '../components/enrollments-user/enrollments-user-reducer';
import listLocationsDetails from './location/listLocationsDetails';
import { SUB_REGION_LIST } from '../actions/subregion';
import createEntityReducer from './generic/entityReducer';
import { GROUP_AGE_LIST } from '../actions/group-age';
import { FOOD_REPORT_DIMENSIONS, PRESENCE_FACTS_LIST } from '../actions/presence-facts';
import { LOCATION } from '../actions/location';
import { LOCATION_SETTINGS } from '../actions/location/settings';
import {
    FACTS_REPORT_FILTER,
    PRESENCE_FACTS_REPORT,
    PRESENCE_FACTS_REPORT_XLS
} from '../actions/presence-facts/presence-report';
import { FOOD_PRIVILEGE_ENROLLMENT } from '../actions/food-privilege-enrollment';
import { FOOD_PRIVILEGE_LIST } from '../actions/food-privilege';
import { FOOD_PRIVILEGE_ENROLLMENT_LIST } from '../actions/food-privilege-enrollment/list';
import createDownloadReducer from './generic/downloadReducer';
import { OPEN_DATA_REPORT_XLS } from '../actions/open-data';
import { FOOD_FACTS_REPORT, FOOD_FACTS_REPORT_XLS } from '../actions/presence-facts/food-report';
import { ENROLLMENT_SEARCH } from '../actions/enrollment/searchEnrollments';
import { GROUP_ADMIN, GROUP_ADMINS_LIST } from '../actions/group/groupAdmin';
import { LOCATION_ADMIN, LOCATION_ADMINS_LIST } from '../actions/location/locationAdmin';

const appReducer = combineReducers({
    authentication,
    registration,
    confirmation,
    userInfo,
    sendPasswordResetEmail,
    resendConfirmationEmail,
    resetPassword,
    createChild,
    fetchChild,
    updateChild,
    listChildren,
    listRegions,
    listLocations,
    fetchRegionsPaginated,
    region,
    locationView,
    locationsForAdmin,
    applyToGroup,
    createGroup,
    updateGroup,
    fetchGroup,
    fetchGroupsByLocationId,
    fetchEnrollment,
    enrollmentStatusUpdate,
    eventList,
    deleteGroup,
    enrollmentEdit,
    enrollmentCreate,
    fetchEnrollmentsForAttention,
    fetchRelatedEnrollments,
    fetchPrivileges,
    accountDetails,
    tour,
    attachments,
    userEnrollments,
    listLocationsDetails,
    subregions: createEntityReducer(SUB_REGION_LIST),
    groupAges: createEntityReducer(GROUP_AGE_LIST),
    foodReportDimensions: createEntityReducer(FOOD_REPORT_DIMENSIONS),
    presenceFacts: createEntityReducer(PRESENCE_FACTS_LIST),
    location: createEntityReducer(LOCATION),
    locationSettings: createEntityReducer(LOCATION_SETTINGS),
    presenceFactsReport: createEntityReducer(PRESENCE_FACTS_REPORT),
    factReportFilter: createEntityReducer(FACTS_REPORT_FILTER),
    foodPrivilegeEnrollment: createEntityReducer(FOOD_PRIVILEGE_ENROLLMENT),
    foodPrivilegeList: createEntityReducer(FOOD_PRIVILEGE_LIST),
    foodPrivilegeEnrollmentList: createEntityReducer(FOOD_PRIVILEGE_ENROLLMENT_LIST),
    presenceFactsReportXls: createDownloadReducer(PRESENCE_FACTS_REPORT_XLS),
    openDataReportXls: createDownloadReducer(OPEN_DATA_REPORT_XLS),

    foodFactsReport: createEntityReducer(FOOD_FACTS_REPORT),
    foodFactsReportXls: createDownloadReducer(FOOD_FACTS_REPORT_XLS),

    enrollmentSearch: createEntityReducer(ENROLLMENT_SEARCH),

    groupAdminList: createEntityReducer(GROUP_ADMINS_LIST),
    groupAdmin: createEntityReducer(GROUP_ADMIN),

    locationAdminList: createEntityReducer(LOCATION_ADMINS_LIST),
    locationAdmin: createEntityReducer(LOCATION_ADMIN),
});

const rootReducer = (state, action) => {

    if (action.type === 'COMPLETE_LOGOUT' || action.type === 'REDIRECTED') {
        const { accountDetails, tour } = state;
        state = { accountDetails, tour };
    }

    return appReducer(state, action);
};

export default rootReducer;