import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { doRegister, registerReset } from '../actions/register';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '../utils/settings';
import { Button, Checkbox, Divider, Form, Grid, Header, Label, Message, Responsive, Segment } from 'semantic-ui-react';
import {
    emailError,
    isEmailError,
    isPasswordError,
    isRecaptchaError,
    passwordError,
    recaptchaError
} from '../utils/validation';
import RegistrationHelp, { EMAIL_STEP, PASSWORD_STEP } from './help/RegistrationHelp';

const recaptchaRef = React.createRef();

const initialState = {
    username: '',
    password: '',
    repeatPassword: '',
    response: '',
    agreed: false,
    step: EMAIL_STEP
};

class Register extends Component {

    state = initialState;

    componentWillUnmount() {
        this.props.dispatch(registerReset());
    }

    handleRegisterClick = (event) => {
        event.preventDefault();

        const { username, password, repeatPassword, response, agreed } = this.state;

        this.props.dispatch(doRegister(username, password, repeatPassword, response, agreed));
    };

    onRecaptchaResolved = (response) => {
        this.setState({ response });
    };

    handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    componentDidUpdate(prevProps) {
        if (isRecaptchaError(this.props) && !isRecaptchaError(prevProps) && recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }

    toggleAgree = () => {
        this.setState({ agreed: !this.state.agreed });
    };

    renderAgreeLabel = () => {
        return (
            <label>Реєструючись я погоджуюсь з <a href="/tos.pdf" target="_blank">політикою конфіденційності</a> та <a
                href="/personal.pdf" target="_blank">умовами використання</a> сервісу</label>);
    };

    onChangeStep = (step) => {
        this.setState({ step });
    };

    render() {

        const { isRegistered, isFetching } = this.props;

        if (isRegistered) {
            return (<Redirect to="/register-success"/>);
        }

        const { agreed, step } = this.state;

        return (
            <Grid centered>
                <Responsive as={Grid.Column} minWidth={Responsive.onlyMobile.maxWidth} computer={8}>
                    <RegistrationHelp step={step} onChangeStep={this.onChangeStep}/>
                </Responsive>
                <Grid.Column computer={8} tablet={12} mobile={16}>
                    <Segment>
                        <Header as='h2'>Реєстрація</Header>

                        <Form loading={isFetching}>
                            <Form.Field>
                                <label>Електронна пошта</label>
                                <Form.Input
                                    name='username'
                                    error={isEmailError(this.props)}
                                    placeholder='email@youraddress.com'
                                    onFocus={() => this.onChangeStep(EMAIL_STEP)}
                                    onChange={this.handleChange}/>

                                {isEmailError(this.props) &&
                                <Label color='red' pointing>
                                    {emailError(this.props)}
                                </Label>}

                            </Form.Field>

                            <Form.Field>
                                <label>Пароль</label>
                                <Form.Input
                                    name='password'
                                    type='password'
                                    placeholder='Пароль повинен містити мінімум 8 символів'
                                    error={isPasswordError(this.props)}
                                    onFocus={() => this.onChangeStep(PASSWORD_STEP)}
                                    onChange={this.handleChange}/>

                                {isPasswordError(this.props) &&
                                <Label color='red' pointing>
                                    {passwordError(this.props)}
                                </Label>}

                            </Form.Field>

                            <Form.Field>
                                <label>Пароль ще раз</label>
                                <Form.Input
                                    name='repeatPassword'
                                    type='password'
                                    error={isPasswordError(this.props)}
                                    placeholder='Перевірка пароля'
                                    onFocus={() => this.onChangeStep(PASSWORD_STEP)}
                                    onChange={this.handleChange}/>
                            </Form.Field>


                            <Label>
                                Пароль повинен містити мінімум 8 символів,
                                серед яких хоч одну цифру, спецсимвол, велику та маленьку літери
                            </Label>

                            <br/>
                            <br/>

                            <div>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={RECAPTCHA_KEY}
                                    onChange={this.onRecaptchaResolved}
                                    hl='uk'
                                />

                                {isRecaptchaError(this.props) &&
                                <Label color='red' pointing>
                                    {recaptchaError(this.props)}
                                </Label>}
                            </div>

                            <br/>

                            <Button
                                name='register'
                                primary
                                type='button'
                                disabled={!agreed}
                                onClick={this.handleRegisterClick}>Зареєструватися</Button>

                            <Form.Field>
                                <br/>
                                <Checkbox
                                    name='agreement'
                                    label={this.renderAgreeLabel()}
                                    checked={agreed}
                                    onChange={() => this.toggleAgree()}
                                />
                            </Form.Field>

                        </Form>
                        <Divider/>
                        <Message attached='bottom'>
                            <div>Вже є обліковий запис?</div>
                            <br/>
                            <Button as={Link} to='/login'>Увійти</Button>
                        </Message>

                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

Register.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.registration;
}

export default connect(mapStateToProps)(Register);
