import axios from 'axios';
import { REQUEST_SETTINGS } from '../utils/settings';
import { getResponseData } from './utils';

export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const COMPLETE_RESET_PASSWORD = 'COMPLETE_RESET_PASSWORD';
export const FAIL_RESET_PASSWORD = 'FAIL_RESET_PASSWORD';

function startResetPassword() {
    return { type: START_RESET_PASSWORD };
}

function completeResetPassword() {
    return { type: COMPLETE_RESET_PASSWORD };
}

function failResetPassword(err) {

    const { errorCode } = getResponseData(err);

    return { type: FAIL_RESET_PASSWORD, errorCode };
}

export function resetPassword(password) {

    return async function (dispatch) {

        dispatch(startResetPassword());

        return axios
            .put('/api/password-reset', password, REQUEST_SETTINGS)
            .then(() => dispatch(completeResetPassword()))
            .catch(err => dispatch(failResetPassword(err)));

    };
}