import { COMPLETE_LIST_CHILDREN, FAIL_LIST_CHILDREN, START_LIST_CHILDREN, } from '../../actions/child/listChildren';

const initialState = {
    isFetching: false,
    isFailed: false,
    children: [],
};

export default function listChildren(state = initialState, action) {

    switch (action.type) {
    case START_LIST_CHILDREN:
        return {
            ...state,
            isFetching: true,
            isFailed: false,
            children: []
        };
    case COMPLETE_LIST_CHILDREN:
        return {
            ...action,
            isFetching: false,
            isFailed: false
        };
    case FAIL_LIST_CHILDREN:
        return {
            ...action,
            isFetching: false,
            isFailed: true,
            children: []
        };
    default:
        return state;
    }
}