import React, { Component } from 'react';
import { isKindergarten } from '../../utils/settings';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import openDataReportActions from '../../actions/open-data';

class OpenDataLink extends Component {

    downloadReport = () => {
        this.props.dispatch(openDataReportActions.download(this.props.regionId));
    };

    render() {

        const { regionId, isFetching } = this.props;

        return (
            <>
                {/*sadok && mariupol */}
                {isKindergarten() && regionId === 201 &&
                <Button
                    disabled={isFetching}
                    loading={isFetching}
                    labelPosition='right'
                    size='small'
                    floated='right'
                    icon
                    color='blue'
                    onClick={this.downloadReport}>
                    Open Data
                    <Icon name='file excel'/>
                </Button>
                }
            </>
        );
    }
}

OpenDataLink.propTypes = {
    dispatch: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
    return state.openDataReportXls;
}

export default connect(mapStateToProps)(OpenDataLink);