import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';

export const START_LIST_LOCATIONS_DETAILS = 'START_LIST_LOCATIONS_DETAILS';
export const COMPLETE_LIST_LOCATIONS_DETAILS = 'COMPLETE_LIST_LOCATIONS_DETAILS';
export const FAIL_LIST_LOCATIONS_DETAILS = 'FAIL_LIST_LOCATIONS_DETAILS';

function startListLocationsDetails() {
    return { type: START_LIST_LOCATIONS_DETAILS };
}

function completeListLocationsDetails(res) {
    return { type: COMPLETE_LIST_LOCATIONS_DETAILS, locations: res.data };
}

function failListLocationsDetails(res) {
    const { errorCode } = getResponseData(res);
    return { type: FAIL_LIST_LOCATIONS_DETAILS, errorCode };
}

/**
 * Fetch list of locations by regionId if current user is a 'REGION_ADMIN' and has access to region
 */
export function doListLocationsDetails(regionId) {

    return async function (dispatch) {

        dispatch(startListLocationsDetails());

        return axios
            .get(`/api/regions/${regionId}/locations`, createGetParams())
            .then(res => dispatch(completeListLocationsDetails(res)))
            .catch(res => dispatch(failListLocationsDetails(res)));
    };
}