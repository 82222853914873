import {
    LIST_REGIONS_REQUEST,
    LIST_REGIONS_SUCCESS,
    LIST_REGIONS_FAIL,
} from '../../actions/region/listRegions';

const initialState = {
    isLoading: false,
    isFailed: false,
    query: '',
    regions: [],
};

export default function listRegions(state = initialState, action) {

    switch (action.type) {
    case LIST_REGIONS_REQUEST:
        return {
            ...action,
            isLoading: true,
            isFailed: false,
            regions: [],
        };
    case LIST_REGIONS_SUCCESS:
        return {
            ...action,
            isLoading: false,
            isFailed: false
        };
    case LIST_REGIONS_FAIL:
        return {
            ...action,
            isLoading: false,
            isFailed: true,
            regions: []
        };
    default:
        return state;
    }
}