import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { createGetParams } from '../../actions/utils';
import { FAILURE, REQUEST, SUCCESS } from '../../shared/action-type';
import { ErrorResponse } from '../../shared/error-response';
import { AttachmentParams, IAttachment } from '../../shared/attachment.model';
import { Action, ResponseDataList } from '../../shared/store.model';

export const FETCH_ATTACHMENTS = 'FETCH_ATTACHMENTS';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const DELETE_ATTACHMENT = 'FAIL_DELETE_ATTACHMENT';
export const RESET_ATTACHMENTS = 'RESET_FETCH_ATTACHMENTS';

type AttachmentsResponse = ResponseDataList<IAttachment>;

export function resetAttachment() {
    return { type: RESET_ATTACHMENTS };
}

function startAddAttachment() {
    return { type: REQUEST(ADD_ATTACHMENT) };
}

function completeAddAttachment() {
    return { type: SUCCESS(ADD_ATTACHMENT) };
}

function failAddAttachment({ errorCode }: ErrorResponse) {
    return { type: FAILURE(ADD_ATTACHMENT), errorCode };
}

export function addAttachment(file: any, { childUuid, enrollmentUuid }: AttachmentParams) {

    return async function (dispatch: any) {

        dispatch(startAddAttachment());

        const formData = new FormData();

        formData.append('file', file);
        formData.append('childUuid', childUuid);
        formData.append('enrollmentUuid', enrollmentUuid);

        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        };

        return axios
            .post('/api/attachments', formData, config)
            .then(() => {
                dispatch(completeAddAttachment());
                dispatch(fetchAttachments({ childUuid, enrollmentUuid }));
            })
            .catch(({ response }) => dispatch(failAddAttachment(response.data)));
    };
}

function startFetchAttachments() {
    return { type: REQUEST(FETCH_ATTACHMENTS) };
}

function completeFetchAttachments(response: AttachmentsResponse): Action<IAttachment> {
    return { type: SUCCESS(FETCH_ATTACHMENTS), entities: response.data };
}

function failFetchAttachments({ errorCode }: ErrorResponse) {
    return { type: FAILURE(ADD_ATTACHMENT), errorCode };
}

export function fetchAttachments(state: AttachmentParams) {

    return async function (dispatch: any) {

        dispatch(startFetchAttachments());

        const { childUuid, enrollmentUuid } = state;

        return axios
            .get('/api/attachments', createGetParams({ childUuid, enrollmentUuid }))
            .then((res: AttachmentsResponse) => dispatch(completeFetchAttachments(res)))
            .catch(({ response }) => dispatch(failFetchAttachments(response.dataa)));
    };
}

function startDeleteAttachment() {
    return { type: REQUEST(DELETE_ATTACHMENT) };
}

function completeDeleteAttachment() {
    return { type: SUCCESS(DELETE_ATTACHMENT) };
}

function failDeleteAttachment({ errorCode }: ErrorResponse) {
    return { type: FAILURE(DELETE_ATTACHMENT), errorCode };
}

export function deleteAttachment(fileId: number, { childUuid, enrollmentUuid }: AttachmentParams) {
    return async function (dispatch: any) {
        dispatch(startDeleteAttachment());
        return axios
            .delete(`/api/attachments/${fileId}`, REQUEST_SETTINGS)
            .then(() => {
                dispatch(completeDeleteAttachment());
                dispatch(fetchAttachments({ childUuid, enrollmentUuid }));
            })
            .catch(({ response }) => dispatch(failDeleteAttachment(response.data)));
    };
}