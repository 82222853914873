import {
    CREATE_ENROLLMENT_FAILURE,
    CREATE_ENROLLMENT_REQUEST,
    CREATE_ENROLLMENT_RESET,
    CREATE_ENROLLMENT_SUCCESS,
} from '../../actions/enrollment/enrollmentCreate';

const initialState = {
    isCreating: false,
    isCreated: false,
    errorCode: null,
    errorResponse: null,
    fieldErrors: null,
};

export default function enrollmentEdit(state = initialState, action) {

    switch (action.type) {
    case CREATE_ENROLLMENT_RESET:
        return initialState;
    case CREATE_ENROLLMENT_REQUEST:
        return {
            ...state,
            isCreating: true,
            isCreated: false,
            errorCode: null,
            fieldErrors: null,
            errorResponse: null,
        };
    case CREATE_ENROLLMENT_SUCCESS:
        return {
            ...state,
            isCreating: false,
            isCreated: true,
            errorCode: null,
            createdEnrollment: action.createdEnrollment,
            fieldErrors: null,
            errorResponse: null,
        };
    case CREATE_ENROLLMENT_FAILURE:
        return {
            ...state,
            isCreating: false,
            isCreated: false,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
            errorResponse: action.errorResponse,
        };
    default:
        return state;
    }
}