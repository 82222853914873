import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPrivileges } from '../../actions/child/fetchPrivileges';
import { Dropdown, Form } from 'semantic-ui-react';
import _ from 'lodash';

class Privileges extends Component {

    componentDidMount() {

        const { isFetched, isFetching, dispatch } = this.props;

        if (isFetched || isFetching) {
            return;
        }

        dispatch(fetchPrivileges());
    }

    render() {

        const {
            // list of privileges for the current region
            privileges,

            // list of privileges objects which are already set for this object
            privilegeValues,

            // list of privileges ids which are already set for this object
            privilegeIds,

            // disabled if in confirmation
            disabled,

            onChange,
        } = this.props;

        if (_.isEmpty(privileges)) {
            return null;
        }

        const privilegeOptions = [...privileges, ...privilegeValues].map(privilege => {
            const { id, description } = privilege;
            return {
                key: id,
                value: id,
                text: description
            };
        });

        const privilegeOptionsUniq = _.uniqBy(privilegeOptions, 'key');

        return (
            <Form.Field>
                <label>Пільги</label>
                <Dropdown
                    name='privilegeIds'
                    options={privilegeOptionsUniq}
                    placeholder='Вкажіть пільги дитини'
                    selection
                    fluid
                    multiple
                    value={privilegeIds}
                    onChange={onChange}
                    disabled={disabled}
                />
            </Form.Field>
        );
    }
}

Privileges.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    privilegeIds: PropTypes.array.isRequired,
    privilegeValues: PropTypes.array,
    disabled: PropTypes.bool,
};

function mapStateToProps(state) {
    return { ...state.fetchPrivileges };
}

export default connect(mapStateToProps)(Privileges);