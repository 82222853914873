import axios from 'axios';
import { createGetParams, getResponseData } from './utils';

export const START_CONFIRMATION = 'START_CONFIRMATION';
export const COMPLETE_CONFIRMATION = 'COMPLETE_CONFIRMATION';
export const FAIL_CONFIRMATION = 'FAIL_CONFIRMATION';

function startConfirmation() {
    return { type: START_CONFIRMATION };
}

function completeConfirmation(res) {
    return { type: COMPLETE_CONFIRMATION, status: res.status };
}

function failConfirmation(res) {
    const { errorCode } = getResponseData(res);

    return { type: FAIL_CONFIRMATION, errorCode };
}

export function doConfirmation(token) {
    return async function (dispatch) {

        dispatch(startConfirmation());

        return axios
            .get('/api/confirm', createGetParams({ token }))
            .then(res => dispatch(completeConfirmation(res)))
            .catch(res => dispatch(failConfirmation(res)));
    };
}