import {
    CANCELLED,
    CONFIRM_REQUEST,
    CONFIRMED,
    IN_PROGRESS,
    RECEIVED,
    REJECT_REQUEST,
    REJECTED
} from '../const/variables';

export function getEnrollmentStatusName(enumStatus) {
    switch (enumStatus) {
    case RECEIVED:
        return 'отримана';
    case IN_PROGRESS:
        return 'в обробці';
    case CONFIRMED:
        return 'підтверджена';
    case CONFIRM_REQUEST:
        return 'запит на підтвердження';
    case REJECTED:
        return 'відхилена';
    case CANCELLED:
        return 'скасована';
    case REJECT_REQUEST:
        return 'запит на відмову';
    default:
        return 'невідомо';
    }
}

export function getEnrollmentStatusAction(nextStatus) {
    switch (nextStatus) {
    case IN_PROGRESS:
        return 'До розгляду';
    case REJECTED:
        return 'Відхилити';
    case CONFIRMED:
        return 'Підтвердити';
    case REJECT_REQUEST:
        return 'Відхилити';
    case CONFIRM_REQUEST:
        return 'Підтвердити';
    case CANCELLED:
        return 'Відмінити';
    default:
        return '';
    }
}

export const isAllowableToChangeGroup = (payload) => {
    return payload.enrollmentStatus === RECEIVED
        || payload.enrollmentStatus === IN_PROGRESS
        || payload.enrollmentStatus === CONFIRMED;
};

export const isConfirmation = (payload) => {
    return payload.enrollmentStatus === CONFIRMED
        || payload.enrollmentStatus === CONFIRM_REQUEST;
};