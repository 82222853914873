import momentz from 'moment-timezone';
import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT, ZONE } from './settings';

export function formatDateToLocalDateString(date) {
    if (!date) {
        return null;
    }

    return moment(date).format(DATE_FORMAT);
}

export function formatTimestampToDateTimeString(timestamp) {
    return momentz.utc(timestamp).tz(ZONE).format(DATE_TIME_FORMAT);
}

export function formatTimestampToDateString(timestamp) {
    return momentz.utc(timestamp).tz(ZONE).format(DATE_FORMAT);
}

export function getDateFromString(date) {
    if (!date) {
        return null;
    }

    return moment(date, DATE_FORMAT).toDate();
}