import {
    COMPLETE_UPDATE_CHILD,
    FAIL_UPDATE_CHILD,
    RESET_UPDATE_CHILD,
    START_UPDATE_CHILD,
} from '../../actions/child/updateChild';

const initialState = {
    isUpdating: false,
    isUpdated: false,
    errorCode: null,
};

export default function updateChild(state = initialState, action) {

    switch (action.type) {
    case RESET_UPDATE_CHILD:
        return initialState;
    case START_UPDATE_CHILD:
        return {
            ...state,
            isUpdating: true,
            isUpdated: false,
            errorCode: null,
            fieldErrors: null,
        };
    case COMPLETE_UPDATE_CHILD:
        return {
            ...state,
            isUpdating: false,
            isUpdated: true,
            errorCode: null,
            fieldErrors: null,
            child: action.child,
        };
    case FAIL_UPDATE_CHILD:
        return {
            ...state,
            isUpdating: false,
            isUpdated: false,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
        };
    default:
        return state;
    }
}