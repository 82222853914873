import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';


export const FETCH_ENROLLMENTS_FOR_ATTENTION_REQUEST = 'FETCH_ENROLLMENTS_FOR_ATTENTION_REQUEST';
export const FETCH_ENROLLMENTS_FOR_ATTENTION_SUCCESS = 'FETCH_ENROLLMENTS_FOR_ATTENTION_SUCCESS';
export const FETCH_ENROLLMENTS_FOR_ATTENTION_FAILURE = 'FETCH_ENROLLMENTS_FOR_ATTENTION_FAILURE';

export function fetchEnrollmentForAttention(page) {

    return async function (dispatch) {

        dispatch(fetchEnrollmentForAttentionRequest());

        return axios
            .get(`/api/enrollments/attention?page=${page}`, createGetParams())
            .then(res => dispatch(fetchEnrollmentForAttentionSuccess(res)))
            .catch(res => dispatch(fetchEnrollmentForAttentionFailure(res)));
    };

    function fetchEnrollmentForAttentionRequest() {
        return { type: FETCH_ENROLLMENTS_FOR_ATTENTION_REQUEST };
    }

    function fetchEnrollmentForAttentionSuccess(res) {
        const payload = res.data;
        return {
            type: FETCH_ENROLLMENTS_FOR_ATTENTION_SUCCESS, payload
        };
    }

    function fetchEnrollmentForAttentionFailure(res) {

        const { errorCode } = getResponseData(res);

        return { type: FETCH_ENROLLMENTS_FOR_ATTENTION_FAILURE, errorCode };
    }
}