import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';

export const START_LIST_CHILDREN = 'START_LIST_CHILDREN';
export const COMPLETE_LIST_CHILDREN = 'COMPLETE_LIST_CHILDREN';
export const FAIL_LIST_CHILDREN = 'FAIL_LIST_CHILDREN';

function startListChildren() {
    return { type: START_LIST_CHILDREN };
}

function completeListChildren(res) {

    return { type: COMPLETE_LIST_CHILDREN, children: res.data };
}

function failListChildren(res) {

    const { errorCode } = getResponseData(res);

    return { type: FAIL_LIST_CHILDREN, errorCode };
}

export function doListChildren() {

    return async function (dispatch) {

        dispatch(startListChildren());

        return axios
            .get('/api/children', createGetParams())
            .then(res => dispatch(completeListChildren(res)))
            .catch(res => dispatch(failListChildren(res)));
    };
}