import {
    COMPLETE_GET_EVENT_LIST,
    FAIL_GET_EVENT_LIST,
    START_GET_EVENT_LIST
} from '../../actions/event/eventListAction';


const initialState = {
    isFetching: false,
    isFailed: false,
    payload: null,
};
export default function fetchEventList(state = initialState, action) {

    switch (action.type) {
    case START_GET_EVENT_LIST:
        return {
            isFetching: true,
            payload: null
        };
    case COMPLETE_GET_EVENT_LIST:
        return {
            payload: action.payload
        };
    case FAIL_GET_EVENT_LIST:
        return {
            isFailed: true,
            payload: null
        };
    default:
        return state;
    }
}