import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendPasswordResetEmail, sendPasswordResetEmailReset } from '../../actions/email/sendPasswordResetEmail';
import { Button, Form, Grid, Header, Label, Message, Segment } from 'semantic-ui-react';
import { getFieldError, isFieldError } from '../../utils/validation';
import RecaptchaWrapper from '../RecaptchaWrapper';
import LargeDimmer from '../helpers/Dimmer';

class SendPasswordResetEmail extends Component {

    state = { email: '' };

    componentWillUnmount() {
        this.props.dispatch(sendPasswordResetEmailReset());
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    onRecaptchaResolved = (captchaResponse) => {
        this.setState({ captchaResponse });
    };

    onSendEmailBtnClick = (event) => {
        event.preventDefault();

        const { email, captchaResponse } = this.state;

        this.props.dispatch(sendPasswordResetEmail({ username: email, captchaResponse }));
    };

    render() {
        const props = this.props;

        if (props.isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        return (
            <Grid centered>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                    <Segment>
                        <Header as='h2' content='Відновлення паролю'/>
                        {this.renderForm()}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }

    renderForm = () => {
        const props = this.props;

        if (props.isSuccess) {
            return this.renderSuccessView();
        }

        return (
            <>
                <Form>
                    <Form.Field>
                        <Header as='h5'>
                            Для відновлення паролю введіть email який ви використовували в процесі реєєстрації
                        </Header>
                        <Form.Input
                            id='email'
                            name='email'
                            error={isFieldError(props, 'email')}
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            placeholder='Введіть email для відновлення паролю'
                        />
                    </Form.Field>

                    {isFieldError(props, 'email') &&
                    <Label color='red' pointing>
                        {getFieldError(props, 'email')}
                    </Label>}

                    <RecaptchaWrapper {...this.props} onRecaptchaResolved={this.onRecaptchaResolved}/>

                    <br/>
                    <br/>

                    <Button
                        primary
                        onClick={this.onSendEmailBtnClick}
                        content='Відправити'
                    />
                </Form>
            </>
        );
    };

    renderSuccessView = () =>
        <Message positive>
            <p>Лист з інструкціями для відновлення паролю надіслано на Вашу поштову скриньку</p>
            <p><Link to='/login'>Повернутися на сторінку входу</Link></p>
        </Message>;
}

SendPasswordResetEmail.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.sendPasswordResetEmail;
}

export default connect(mapStateToProps)(SendPasswordResetEmail);