import React, { Component } from 'react';
import { Button, Checkbox, Form, Grid, Header, Input, Label, Radio } from 'semantic-ui-react';
import { getFieldError, isFieldError, } from '../../utils/validation';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import { REGION_FRANKOVSK, REGION_TERNOPOL } from '../../const/variables';
import { ternopolKindergartenInfo, ternopolSchoolInfo } from './AdditionalInfo';
import { DATE_PICKER_FORMAT, isKindergarten, isSchool } from '../../utils/settings';
import Privileges from './Privileges';
import { connect } from 'react-redux';
import { isUser } from '../../services/AuthService';
import { getAdditionalInfoPlaceholder } from '../../utils/texts';
import { FEMALE, MALE } from '.';

// UI constants

class ChildFormComponent extends Component {

    state = { personalDataConfirmed: false };

    togglePersonalDataConfirmed = () => {
        this.setState({ personalDataConfirmed: !this.state.personalDataConfirmed });
    };

    render() {

        const props = this.props;

        const {
            // TODO move out to props
            // enrollment use case
            payload,
            // TODO move out to props
            // child use case
            child,
            value,
            children,
            headerText,
            submitButtonText,
            onSubmit,
            onChange,
            onDateChange,
            requirePersonalData,
            accountDetails
        } = props;

        const target = payload || child;

        const privileges = target ? (target.privileges || []) : [];

        return (
            <Grid centered>
                <Grid.Column computer={12} laptop={14} mobile={16}>
                    {headerText && <Header as='h2'>{headerText}</Header>}
                    <Form>

                        {children}

                        <Form.Field required={true}>
                            <label>Прізвище дитини</label>
                            <Form.Input
                                name='lastName'
                                value={value.lastName}
                                error={isFieldError(props, 'lastName')}
                                onChange={onChange}/>

                            {isFieldError(props, 'lastName') &&
                            <Label color='red' pointing>{getFieldError(props, 'lastName')}</Label>}
                        </Form.Field>

                        <Form.Field required={true}>
                            <label>Ім'я дитини</label>
                            <Form.Input
                                name='firstName'
                                value={value.firstName}
                                error={isFieldError(props, 'firstName')}
                                onChange={onChange}/>

                            {isFieldError(props, 'firstName') &&
                            <Label color='red' pointing>{getFieldError(props, 'firstName')}</Label>}
                        </Form.Field>

                        <Form.Field required={true}>
                            <label>По батькові дитини</label>
                            <Form.Input
                                name='middleName'
                                value={value.middleName}
                                error={isFieldError(props, 'middleName')}
                                onChange={onChange}/>

                            {isFieldError(props, 'middleName') &&
                            <Label color='red' pointing>{getFieldError(props, 'middleName')}</Label>}
                        </Form.Field>

                        <Form.Field required={true}>
                            <label>Дата народження</label>
                            <DatePicker
                                placeholderText='день-місяць-рік'
                                dateFormat={DATE_PICKER_FORMAT}
                                selected={value.birthDate}
                                onChange={onDateChange}
                                locale={uk}/>

                            {isFieldError(props, 'birthDate') &&
                            <>
                                <br/>
                                <Label color='red' pointing>{getFieldError(props, 'birthDate')}</Label>
                            </>
                            }
                        </Form.Field>

                        <Form.Field required={true}>
                            <label>Адреса проживання дитини</label>
                            <Form.Input
                                name='address'
                                value={value.address}
                                error={isFieldError(props, 'address')}
                                onChange={onChange}/>

                            {isFieldError(props, 'address') &&
                            <Label color='red' pointing>{getFieldError(props, 'address')}</Label>}
                        </Form.Field>

                        <Form.Field required={true}>
                            <label>Місце реєстрації дитини (за замовчуванням таке саме як адреса проживання)</label>
                            <Form.Input
                                name='registrationAddress'
                                value={value.registrationAddress}
                                placeholder='За замовчуванням збігається з адресою проживання'
                                error={isFieldError(props, 'registrationAddress')}
                                onChange={onChange}/>

                            {isFieldError(props, 'registrationAddress') &&
                            <Label
                                color='red'
                                pointing>{getFieldError(props, 'registrationAddress')}</Label>}
                        </Form.Field>

                        <Form.Field>
                            <label>Стать</label>
                            <Radio
                                label='дівчинка'
                                name='sex'
                                value={FEMALE}
                                checked={value.sex === FEMALE}
                                onChange={onChange}
                            />
                            <Radio
                                style={{ marginLeft: 30 }}
                                label='хлопчик'
                                name='sex'
                                value={MALE}
                                checked={value.sex === MALE}
                                onClick={onChange}
                            />
                        </Form.Field>

                        {isFieldError(props, 'sex') &&
                        <Label color='red' pointing>{getFieldError(props, 'sex')}</Label>}

                        {isKindergarten() && accountDetails.regionName === REGION_FRANKOVSK &&
                            <Form.Field>
                                <label>Тип документу</label>
                                <Radio
                                    label='Свідоцтво про народження'
                                    name='documentType'
                                    value='BIRTH_CERTIFICATE'
                                    checked={value.documentType === 'BIRTH_CERTIFICATE'}
                                    onChange={onChange}
                                />
                                <Radio
                                    style={{ marginLeft: 30 }}
                                    label='Інше'
                                    name='documentType'
                                    value='OTHER_DOCUMENT'
                                    checked={value.documentType === 'OTHER_DOCUMENT'}
                                    onClick={onChange}
                                />
                            </Form.Field>
                        }
                        <Form.Group widths='equal'>
                            <Form.Field required={true}>
                                {isKindergarten() && accountDetails.regionName === REGION_FRANKOVSK &&
                                    (<label>
                                        Номер документу
                                    </label>)
                                    ||
                                    (<label>
                                        Серія та номер свідоцтва про народження дитини (наприклад І-СГ333444) або номер закордонного паспорта
                                    </label>)
                                }
                                <Form.Input
                                    size='mini'
                                    name='documentId'
                                    value={value.documentId}
                                    error={isFieldError(props, 'documentId')}
                                    onChange={onChange}/>
                            </Form.Field>
                        </Form.Group>
                        {isFieldError(props, 'documentId') &&
                        <Label color='red' pointing>{getFieldError(props, 'documentId')}</Label>}

                        {!(isKindergarten() && accountDetails.regionName === REGION_FRANKOVSK) &&
                        <Form.Field>
                            <label>Інший документ, що засвідчує дитину</label>
                            <Form.Input
                                name='otherDocument'
                                value={value.otherDocument}
                                error={isFieldError(props, 'otherDocument')}
                                placeholder='Назва документу, наприклад закордонный паспорт'
                                onChange={onChange}/>
                            {isFieldError(props, 'otherDocument') &&
                            <Label color='red' pointing>{getFieldError(props, 'otherDocument')}</Label>}
                        </Form.Field>
                        }

                        <Form.TextArea
                            name='documentInfo'
                            value={value.documentInfo}
                            label='Дані про документ'
                            error={isFieldError(props, 'documentInfo')}
                            placeholder='Дата видачі, ким виданий'
                            onChange={onChange}/>
                        {isFieldError(props, 'documentInfo') &&
                        <Label color='red' pointing>{getFieldError(props, 'documentInfo')}</Label>}

                        <Form.Field required={true}>
                            <label>​Прізвище, ім’я, по батькові одного з батьків дитини</label>
                            <Form.Input
                                name='parentName'
                                value={value.parentName}
                                error={isFieldError(props, 'parentName')}
                                onChange={onChange}
                            />
                            {isFieldError(props, 'parentName') &&
                            <Label color='red' pointing>{getFieldError(props, 'parentName')}</Label>}
                        </Form.Field>

                        {props.parentEmail && props.parentEmail}

                        <Form.Field required={true}>
                            <label>Контактний телефон</label>
                            <Input
                                name='parentPhone'
                                value={value.parentPhone}
                                error={isFieldError(props, 'parentPhone')}
                                label='+380'
                                onChange={onChange}>
                            </Input>

                            {isFieldError(props, 'parentPhone') &&
                            <Label color='red' pointing>{getFieldError(props, 'parentPhone')}</Label>}
                        </Form.Field>

                        <Form.TextArea
                            name='additionalInfo'
                            value={value.additionalInfo}
                            error={isFieldError(props, 'additionalInfo')}
                            label='Додаткова інформація'
                            placeholder={getAdditionalInfoPlaceholder(accountDetails)}
                            onChange={onChange}/>
                        {isFieldError(props, 'additionalInfo') &&
                        <Label color='red' pointing>{getFieldError(props, 'additionalInfo')}</Label>}

                        <Privileges
                            onChange={onChange}
                            // should be disabled if in confirmation
                            disabled={value.privilegeDisabled}
                            // dropdown values, list of ids of privileges which are already set
                            privilegeIds={value.privilegeIds}
                            // additional dropdown values - list of objects of privileges which are already set
                            // should be merged with list of privileges for the current region
                            privilegeValues={privileges}
                        />

                        {requirePersonalData &&
                        <Form.Field required={true}>
                            <Checkbox
                                label='Даю згоду на обробку персональних даних'
                                checked={this.state.personalDataConfirmed}
                                onChange={this.togglePersonalDataConfirmed}
                            />
                        </Form.Field>
                        }

                        <Button
                            disabled={requirePersonalData && !this.state.personalDataConfirmed}
                            type='button' primary
                            onClick={onSubmit}>{submitButtonText}</Button>

                        {accountDetails.regionName === REGION_TERNOPOL && isSchool() && isUser() &&
                        ternopolSchoolInfo()
                        }

                        {accountDetails.regionName === REGION_TERNOPOL && isKindergarten() && isUser() &&
                        ternopolKindergartenInfo()
                        }
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

ChildFormComponent.propTypes = {
    state: PropTypes.object,
    submitButtonText: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    requirePersonalData: PropTypes.bool,
    parentEmail: PropTypes.object
};

function mapStateToProps(state) {
    return { accountDetails: state.accountDetails };
}

export default connect(mapStateToProps)(ChildFormComponent);