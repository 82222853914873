import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Form } from 'semantic-ui-react';
import presenceFactsReportActions from '../../actions/presence-facts/presence-report';
import DatePicker from 'react-datepicker';
import { DATE_PICKER_FORMAT } from '../../utils/settings';
import uk from 'date-fns/locale/uk';
import { toOptions } from '../../utils';
import t from '../../services/Translate';
import { isRegionAdmin } from '../../services/AuthService';

class FactReportFilter extends Component {

    state = {
        dateFrom: '',
        dateTo: '',
        subRegionIds: [],
        locationIds: [],
        groupIds: [],
        foodFactTypeIds: [],
        foodPrivilegeIds: [],
        groupAgeIds: [],
        page: 1
    };

    fetchPresenceFilters() {
        const { regionId } = this.props;

        const {
            subRegionIds,
            locationIds,
            groupIds,
            groupAgeIds
        } = this.state;

        const request = {
            regionId,
            subRegionIds,
            locationIds,
            groupIds,
            groupAgeIds,
        };

        this.props.dispatch(presenceFactsReportActions.fetchFilters(request));
    }

    componentDidMount() {
        this.fetchPresenceFilters();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { payload, isUpdated } = this.props;

        if (isUpdated && !prevProps.isUpdated) {
            this.props.onFilterUpdated({ ...this.state, page: 1 });
        }

        if (!payload) {
            return;
        }

        const { subRegions, groupAges } = payload;

        const {
            subRegionIds,
            locationIds,
            groupIds,
            groupAgeIds,
            dateFrom,
            dateTo,
            foodFactTypeIds,
            foodPrivilegeIds
        } = this.state;

        if (subRegions && subRegions.length && subRegionIds !== prevState.subRegionIds) {
            this.setState({
                locationIds: [],
                groupIds: [],
            });
        }

        if (dateFrom !== prevState.dateFrom) {
            this.fetchPresenceFilters();
        }

        if (dateTo !== prevState.dateTo) {
            this.fetchPresenceFilters();
        }

        if (locationIds !== prevState.locationIds) {
            this.fetchPresenceFilters();
        }

        if (foodFactTypeIds !== prevState.foodFactTypeIds) {
            this.fetchPresenceFilters();
        }

        if (foodPrivilegeIds !== prevState.foodPrivilegeIds) {
            this.fetchPresenceFilters();
        }

        if (groupIds !== prevState.groupIds) {
            this.fetchPresenceFilters();
        }

        if (groupAges && groupAges.length && groupAgeIds !== prevState.groupAgeIds) {
            this.fetchPresenceFilters();
        }
    }

    handleChange = (event, { name, value }) => {
        event.preventDefault();

        this.setState({ [name]: value });
    };

    onDateFromChange = dateFrom => {
        this.setState({ dateFrom });
    };

    onDateToChange = dateTo => {
        this.setState({ dateTo });
    };

    /**
     * @property subRegions
     * @property groups
     */
    render() {

        const { payload, isFoodReport } = this.props;

        if (!payload) {
            return null;
        }

        const {
            dateFrom,
            dateTo,
            subRegionIds,
            locationIds,
            groupIds,
            groupAgeIds,
            foodPrivilegeIds,
            foodFactTypeIds
        } = this.state;

        const subRegions = toOptions(payload.subRegions);
        const locations = toOptions(payload.locations);
        const groups = toOptions(payload.groups, group => {
            const period = `${group.period}-${group.period + 1}`;
            return group.name.includes(period) ? group.name : `${group.name}, ${period}`;
        });
        const groupAges = toOptions(payload.groupAges);
        const foodPrivileges = toOptions(payload.foodPrivileges);
        const foodFactTypes = toOptions(payload.foodFactTypes);

        return (
            <Form>
                <Form.Group inline>
                    <Form.Field>
                        <label>Від</label>
                        <DatePicker
                            placeholderText='день-місяць-рік'
                            dateFormat={DATE_PICKER_FORMAT}
                            selected={dateFrom}
                            onChange={this.onDateFromChange}
                            locale={uk}/>
                    </Form.Field>
                    <Form.Field>
                        <label>До</label>
                        <DatePicker
                            placeholderText='день-місяць-рік'
                            dateFormat={DATE_PICKER_FORMAT}
                            selected={dateTo}
                            onChange={this.onDateToChange}
                            locale={uk}/>
                    </Form.Field>
                </Form.Group>
                {subRegions.length > 0 && isRegionAdmin() &&
                <Form.Field>
                    <label>Райони</label>
                    <Dropdown
                        name='subRegionIds'
                        options={subRegions}
                        placeholder='Вкажить райони'
                        multiple
                        selection
                        fluid
                        value={subRegionIds}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                }
                <Form.Field>
                    <label>{t('locations')}</label>
                    <Dropdown
                        name='locationIds'
                        options={locations}
                        placeholder={'Вкажіть ' + t('locations').toLowerCase()}
                        multiple
                        search
                        selection
                        fluid
                        value={locationIds}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('groups')}</label>
                    <Dropdown
                        name='groupIds'
                        options={groups}
                        placeholder={'Вкажіть ' + t('groups').toLowerCase()}
                        multiple
                        search
                        selection
                        fluid
                        value={groupIds}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                {foodPrivileges.length > 0 && isFoodReport &&
                <Form.Field>
                    <label>Пільги з зарчування</label>
                    <Dropdown
                        name='foodPrivilegeIds'
                        options={foodPrivileges}
                        placeholder='Вкажить пільги з харчування'
                        multiple
                        selection
                        fluid
                        value={foodPrivilegeIds}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                }
                {foodFactTypes.length > 0 && isFoodReport &&
                <Form.Field>
                    <label>Харчування</label>
                    <Dropdown
                        name='foodFactTypeIds'
                        options={foodFactTypes}
                        placeholder='Вкажить тип харчування'
                        multiple
                        selection
                        fluid
                        value={foodFactTypeIds}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                }
                {groupAges.length > 0 &&
                <Form.Field>
                    <label>Вікові групи</label>
                    <Dropdown
                        name='groupAgeIds'
                        options={groupAges}
                        placeholder='Вкажить вікові групи'
                        multiple
                        selection
                        fluid
                        value={groupAgeIds}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                }
            </Form>
        );
    }
}

FactReportFilter.propTypes = {
    dispatch: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
    onFilterUpdated: PropTypes.func.isRequired,
    isFoodReport: PropTypes.bool,
};

function mapStateToProps(state) {
    return state.factReportFilter;
}

export default connect(mapStateToProps)(FactReportFilter);