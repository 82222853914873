import {
    FETCH_GROUP_FAILURE,
    FETCH_GROUP_REQUEST,
    FETCH_GROUP_SUCCESS,
} from '../../actions/group/fetchGroup';

const initialState = {
    isFetching: false,
    isFetchFailed: false,
    isFetched: false,
    group: { enrollments: [] },
    errorCode: null
};

export default function fetchGroup(state = initialState, action) {

    switch (action.type) {
    case FETCH_GROUP_REQUEST:
        return {
            ...state,
            isFetching: true,
            isFetchFailed: false,
            isFetched: false,
            group: { enrollments: [] },
            errorCode: null
        };
    case FETCH_GROUP_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: false,
            isFetched: true,
            group: { enrollments: [], ...action.group },
            errorCode: null
        };
    case FETCH_GROUP_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: true,
            isFetched: false,
            group: { enrollments: [] },
            errorCode: action.errorCode,
        };
    default:
        return { ...state };
    }
}