const STATE_KEY = 'STATE_KEY';

export function saveState(state) {
    try {
        localStorage.setItem(STATE_KEY, JSON.stringify(state));
    } catch (e) {
        // ignore
    }
}

export function loadState() {
    try {
        const stateString = localStorage.getItem(STATE_KEY);

        if (!stateString) {
            return undefined;
        }

        return JSON.parse(stateString);
    } catch (e) {
        return undefined;
    }
}