import Toast from '../helpers/Toasts';
import { getRemoveAdminFromObjectError } from '../../utils/validation';
import DialogBase from './DialogBase';

class DeleteDialogBase extends DialogBase {

    isProgress = () => this.props.isDeleting;

    isDone = () => this.props.isDeleted || this.props.isDeleteFailed;

    isFailure = () => this.props.isDeleteFailed;

    // noinspection JSCheckFunctionSignatures
    componentDidUpdate(prevProps) {
        const { isDeleteFailed, isDeleted } = this.props;

        if (isDeleteFailed && !prevProps.isDeleteFailed) {
            this.closeDialog();
            Toast('error', getRemoveAdminFromObjectError(this.props));
        }

        if (isDeleted && !prevProps.isDeleted) {
            this.closeDialog();
            Toast('success', this.getCompletedMessage());
        }
    }
}

export default DeleteDialogBase;