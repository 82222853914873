// react
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
// semantic
import { Button, Modal } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';
// services
import { enrollmentUpdateError, isEnrollmentUpdateError } from '../../utils/validation';
// actions
import { doResetEnrollment, doUpdateEnrollment } from '../../actions/enrollment/enrollmentStatusUpdate';
// components
import LargeDimmer from '../helpers/Dimmer';
import Notification from '../helpers/Notification';

class EnrollmentMessageModal extends Component {

    componentDidUpdate(prevProps) {

        const { isOpen } = this.props;

        if (isOpen && !prevProps.isOpen) {
            this.updateEnrollmentStatus();
        }
    }

    updateEnrollmentStatus = () => {

        const { enrollment, enrollmentStatus, comment } = this.props;

        this.props.dispatch(doUpdateEnrollment({
            id: enrollment.id, enrollmentStatus, comment
        }));
    };

    getMessage = () => {
        return (
            <>
                {isEnrollmentUpdateError(this.props) &&
                <Notification.Error content={enrollmentUpdateError(this.props)}/>}

                {this.props.isUpdated &&
                <Notification.Success header='Зміни збережено' content='Статус заявки було оновлено'/>}

                {this.props.isUpdating &&
                <LargeDimmer text='Відправка запиту'/>}
            </>
        );
    };

    closeDialog = () => {
        this.props.dispatch(doResetEnrollment());
        this.props.onClose();
    };

    render() {
        return (
            <Modal open={this.props.isOpen}>
                <Modal.Content>
                    {this.getMessage()}
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.closeDialog}>Добре</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

EnrollmentMessageModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    enrollment: PropTypes.object.isRequired,
    enrollmentStatus: PropTypes.string.isRequired,
    comment: PropTypes.string
};

function mapStateToProps(state) {
    return state.enrollmentStatusUpdate;
}

export default connect(mapStateToProps)(EnrollmentMessageModal);