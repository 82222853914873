import axios from 'axios';
import { createGetParams } from '../utils';

export const START_GET_PRIVILEGES = 'START_GET_PRIVILEGES';
export const COMPLETE_GET_PRIVILEGES = 'COMPLETE_GET_PRIVILEGES';
export const FAIL_GET_PRIVILEGES = 'FAIL_GET_PRIVILEGES';

function startGetPrivileges() {
    return { type: START_GET_PRIVILEGES };
}

function completeGetPrivileges(res) {
    return { type: COMPLETE_GET_PRIVILEGES, privileges: res.data };
}

function failGetPrivileges() {
    return { type: FAIL_GET_PRIVILEGES };
}

export function fetchPrivileges() {

    return async function (dispatch) {

        dispatch(startGetPrivileges());

        return axios
            .get('/api/data/privileges', createGetParams())
            .then(res => dispatch(completeGetPrivileges(res)))
            .catch(() => dispatch(failGetPrivileges()));
    };
}