import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { getChildFromState, getResponseData } from '../utils';
import { transform } from '.';

export const START_UPDATE_CHILD = 'START_UPDATE_CHILD';
export const COMPLETE_UPDATE_CHILD = 'COMPLETE_UPDATE_CHILD';
export const FAIL_UPDATE_CHILD = 'FAIL_UPDATE_CHILD';
export const RESET_UPDATE_CHILD = 'RESET_UPDATE_CHILD';

export function resetUpdateChild() {
    return { type: RESET_UPDATE_CHILD };
}

function startUpdateChild() {
    return { type: START_UPDATE_CHILD };
}

function completeUpdateChild(res) {
    return { type: COMPLETE_UPDATE_CHILD, child: transform(res.data) };
}

function failUpdateChild(res) {
    const { errorCode, fieldErrors } = getResponseData(res);
    return { type: FAIL_UPDATE_CHILD, errorCode, fieldErrors };
}

export function doUpdateChild(state) {

    return async function (dispatch) {

        dispatch(startUpdateChild());

        return axios
            .put('/api/children', getChildFromState(state), REQUEST_SETTINGS)
            .then((res) => dispatch(completeUpdateChild(res)))
            .catch(error => dispatch(failUpdateChild(error)));
    };
}