import {
    createDeleteEntityAction,
    createFetchEntityAction,
    createResetEntityAction,
    createUpdateEntityAction,
} from '../generic/entityAction';

export const GROUP_ADMINS_LIST = 'GROUP_ADMINS_LIST';
export const GROUP_ADMIN = 'GROUP_ADMIN';

export default {
    fetchAll: createFetchEntityAction(groupId => `/api/groups/${groupId}/accounts`, GROUP_ADMINS_LIST),
    add: createUpdateEntityAction(payload => `/api/groups/${payload.groupId}/accounts`, GROUP_ADMIN),
    delete: createDeleteEntityAction(payload => `/api/groups/${payload.groupId}/accounts`, GROUP_ADMIN),
    reset: createResetEntityAction(GROUP_ADMIN),
};