import React, { Component } from 'react';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';

const initialState = {
    email: '',
    isAddAdminDialogOpen: false
};

class AddAdminFormBase extends Component {

    state = { ...initialState };

    onAddAdmin = (event) => {
        event.preventDefault();

        this.setState({
            isAddAdminDialogOpen: true
        });
    };

    onCompleted = () => {
        this.onClear();
        this.props.onAdminAdded();
    };

    onClear = () =>
        this.setState({ ...initialState});

    handleChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    render() {
        return (
            <Form loading={this.props.isUpdating}>

                {this.renderDialog()}

                <Form.Group inline>
                    <Form.Input
                        icon='mail'
                        iconPosition='left'
                        placeholder='Email адміністратора'
                        name='email'
                        onChange={this.handleChange}
                        value={this.state.email}
                    />
                    <Form.Button
                        labelPosition='right'
                        size='small'
                        content='Додати адміністратора'
                        icon='add user'
                        color='green'
                        disabled={!this.state.email || _.trim(this.state.email) === ''}
                        onClick={this.onAddAdmin}
                    />
                </Form.Group>
            </Form>
        );
    }
}

export default AddAdminFormBase;
