import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { createGetParams, getResponseData } from '../utils';
import _ from 'lodash';

export const FETCH_ENTITY_REQUEST = 'FETCH_ENTITY_REQUEST_';
export const FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS_';
export const FETCH_ENTITY_FAILURE = 'FETCH_ENTITY_FAILURE_';

export const SAVE_ENTITY_REQUEST = 'SAVE_ENTITY_REQUEST_';
export const SAVE_ENTITY_SUCCESS = 'SAVE_ENTITY_SUCCESS_';
export const SAVE_ENTITY_FAILURE = 'SAVE_ENTITY_FAILURE_';

export const UPDATE_ENTITY_REQUEST = 'UPDATE_ENTITY_REQUEST_';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS_';
export const UPDATE_ENTITY_FAILURE = 'UPDATE_ENTITY_FAILURE_';

export const DELETE_ENTITY_REQUEST = 'DELETE_ENTITY_REQUEST_';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS_';
export const DELETE_ENTITY_FAILURE = 'DELETE_ENTITY_FAILURE_';

export const RESET = 'RESET_';

const pickByPredicate = prop => {
    return !_.isString(prop) || _.trim(prop);
};

export function createFetchEntityAction(pathBuilder, entityName) {
    return (args) => {

        return async function (dispatch) {
            dispatch(fetchEntityRequest());
            return axios
                .get(pathBuilder(args), createGetParams())
                .then(res => dispatch(fetchEntitySuccess(res)))
                .catch(res => dispatch(fetchEntityFailure(res)));
        };

        function fetchEntityRequest() {
            return { type: FETCH_ENTITY_REQUEST + entityName };
        }

        function fetchEntitySuccess(res) {
            const payload = res.data;
            return { type: FETCH_ENTITY_SUCCESS + entityName, payload };
        }

        function fetchEntityFailure(res) {
            const { errorCode } = getResponseData(res);
            return { type: FETCH_ENTITY_FAILURE + entityName, errorCode };
        }
    };
}

export function createSaveEntityAction(pathBuilder, entityName) {
    return (payload) => {

        return async function (dispatch) {
            dispatch(saveEntityRequest());
            return axios
                .post(pathBuilder(payload), _.pickBy(payload, pickByPredicate), REQUEST_SETTINGS)
                .then(res => dispatch(saveEntitySuccess(res)))
                .catch(res => dispatch(saveEntityFailure(res)));
        };

        function saveEntityRequest() {
            return { type: SAVE_ENTITY_REQUEST + entityName };
        }

        function saveEntitySuccess(res) {
            const payload = res.data;
            return { type: SAVE_ENTITY_SUCCESS + entityName, payload };
        }

        function saveEntityFailure(res) {
            const { errorCode, errorMessage, fieldErrors } = getResponseData(res);
            return { type: SAVE_ENTITY_FAILURE + entityName, errorCode, errorMessage, fieldErrors };
        }
    };
}

export function createUpdateEntityAction(pathBuilder, entityName) {
    return payload => {

        return async function (dispatch) {
            dispatch(updateEntityRequest());
            return axios
                .put(pathBuilder(payload), _.pickBy(payload, pickByPredicate), REQUEST_SETTINGS)
                .then(res => dispatch(updateEntitySuccess(res)))
                .catch(res => dispatch(updateEntityFailure(res)));
        };

        function updateEntityRequest() {
            return { type: UPDATE_ENTITY_REQUEST + entityName };
        }

        function updateEntitySuccess(res) {
            const payload = res.data;
            return { type: UPDATE_ENTITY_SUCCESS + entityName, payload };
        }

        function updateEntityFailure(res) {
            const { errorCode, errorMessage, fieldErrors } = getResponseData(res);
            return { type: UPDATE_ENTITY_FAILURE + entityName, errorCode, errorMessage, fieldErrors };
        }
    };
}

export function createDeleteEntityAction(pathBuilder, entityName) {
    return payload => {

        return async function (dispatch) {
            dispatch(deleteEntityRequest());
            return axios
                .delete(pathBuilder(payload), { ...REQUEST_SETTINGS, data: _.pickBy(payload, pickByPredicate) })
                .then(res => dispatch(deleteEntitySuccess(res)))
                .catch(res => dispatch(deleteEntityFailure(res)));
        };

        function deleteEntityRequest() {
            return { type: DELETE_ENTITY_REQUEST + entityName };
        }

        function deleteEntitySuccess(res) {
            const payload = res.data;
            return { type: DELETE_ENTITY_SUCCESS + entityName, payload };
        }

        function deleteEntityFailure(res) {
            const { errorCode, errorMessage } = getResponseData(res);
            return { type: DELETE_ENTITY_FAILURE + entityName, errorCode, errorMessage };
        }
    };
}

export function createResetEntityAction(entityName) {
    return () => {
        return { type: RESET + entityName };
    };
}
