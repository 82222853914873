import { COMPLETE_GET_CHILD, FAIL_GET_CHILD, RESET_GET_CHILD, START_GET_CHILD, } from '../../actions/child/fetchChild';

const initialState = {
    isFetching: false,
    isFetched: false,
    child : {},
    errorCode: null,
};

export default function fetchChild(state = initialState, action) {

    switch (action.type) {
    case RESET_GET_CHILD:
        return initialState;
    case START_GET_CHILD:
        return {
            ...state,
            isFetching: true,
            isFetched: false,
            child : {},
            errorCode: null,
        };
    case COMPLETE_GET_CHILD:
        return {
            ...state,
            isFetching: false,
            isFetched: true,
            child : action.child,
            errorCode: null,
        };
    case FAIL_GET_CHILD:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            child : {},
            errorCode: action.errorCode,
        };
    default:
        return state;
    }
}