import {
    EDIT_ENROLLMENT_FAILURE,
    EDIT_ENROLLMENT_REQUEST,
    EDIT_ENROLLMENT_RESET,
    EDIT_ENROLLMENT_SUCCESS,
} from '../../actions/enrollment/enrollmentEdit';

const initialState = {
    isUpdating: false,
    isUpdateFailed: false,
    isUpdated: false,
    errorCode: null,
    errorResponse: null,
    fieldErrors: null,
};

export default function enrollmentEdit(state = initialState, action) {

    switch (action.type) {
    case EDIT_ENROLLMENT_REQUEST:
        return {
            ...state,
            isUpdating: true,
            isUpdateFailed: false,
            isUpdated: false,
            errorCode: null,
            fieldErrors: null,
            errorResponse: null,
        };
    case EDIT_ENROLLMENT_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            isUpdateFailed: false,
            isUpdated: true,
            errorCode: null,
            fieldErrors: null,
            errorResponse: null,
            payload: action.payload,
        };
    case EDIT_ENROLLMENT_FAILURE:
        return {
            ...state,
            isUpdating: false,
            isUpdateFailed: true,
            isUpdated: false,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
            errorResponse: action.errorResponse
        };
    case EDIT_ENROLLMENT_RESET:
        return initialState;
    default:
        return state;
    }
}