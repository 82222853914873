import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import groupAdminActions from '../../../actions/group/groupAdmin';
import t from '../../../services/Translate';
import AddDialogBase from '../../common/AddDialogBase';

class AddGroupAdminDialog extends AddDialogBase {

    getCompletedMessage = () => t('groupAdminAdded')(this.props);

    onSubmit = () => {
        const { targetId, email } = this.props;
        this.props.dispatch(groupAdminActions.add({ groupId: targetId, username: email }));
    };

    resetDialog = () => this.props.dispatch(groupAdminActions.reset());

    getContent = () => t('groupAdminAddConfirm')(this.props);
}

AddGroupAdminDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    targetId: PropTypes.number.isRequired,
    targetName: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return state.groupAdmin;
}

export default connect(mapStateToProps)(AddGroupAdminDialog);