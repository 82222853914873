import React, { Component } from 'react';
import { fetchEnrollment } from '../../actions/enrollment/fetchEnrollment';
import { Divider, Grid, Segment, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LargeDimmer from '../helpers/Dimmer';
import { isLocationAdmin } from '../../services/AuthService';
import { fetchRelatedEnrollments } from '../../actions/enrollment/fetchRelatedEnrollments';
import EnrollmentsTableSection from './EnrollmentsTableSection';
import Notification from '../helpers/Notification';
import Attachments from '../attachment/attachments';
import { EnrollmentDetailsBreadcrumb } from './EnrollmentDetailsBreadcrumb';
import ChildInfoTab from './details/ChildInfoTab';
import ParentInfoTab from './details/ParentInfoTab';
import FoodPrivilegesTab from './details/FoodPrivilegesTab';

class EnrollmentDetailsViewAdmin extends Component {

    componentDidUpdate(prevProps) {

        const { payload, isFetched } = this.props.fetchEnrollment;

        if (isFetched && !prevProps.fetchEnrollment.isFetched) {
            this.props.dispatch(fetchRelatedEnrollments(payload.id));
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchEnrollment(this.props.match.params.enrollmentId));
    }

    /**
     * @property statusComment
     */
    render() {

        const { payload, isFetched } = this.props.fetchEnrollment;

        const { group, uuid } = payload;

        const panes = [
            {
                menuItem: 'Інформація про дитину', render: () =>
                    <Tab.Pane><ChildInfoTab enrollment={payload}/></Tab.Pane>
            },
            {
                menuItem: 'Пільги на харчування', render: () =>
                    <Tab.Pane><FoodPrivilegesTab enrollment={payload}/></Tab.Pane>
            },
            {
                menuItem: 'Інформація про батьків', render: () =>
                    <Tab.Pane><ParentInfoTab enrollment={payload}/></Tab.Pane>
            },
            { menuItem: 'Заявки в інших закладах', render: this.renderRelatedEnrollments },
        ];

        if (isLocationAdmin()) {
            panes.push({
                menuItem: 'Документи', render: () =>
                    <Tab.Pane><Attachments enrollmentUuid={uuid}/></Tab.Pane>
            });
        }

        return (
            <>
                {isFetched &&
                <Grid centered>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <EnrollmentDetailsBreadcrumb group={group} enrollmentId={payload.id}/>
                        <Segment>
                            <Tab panes={panes} menu={{ vertical: true, tabular: true, fluid: true }}/>
                        </Segment>
                    </Grid.Column>
                </Grid>
                }

                {this.props.isFetching &&
                <LargeDimmer text="Завантаження даних"/>
                }

                {this.props.isFailed &&
                <Grid centered>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Segment><Notification.Error/></Segment>
                    </Grid.Column>
                </Grid>
                }

            </>
        );
    }

    renderRelatedEnrollments = () => {

        const { enrollments, isFetching } = this.props.fetchRelatedEnrollments;

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        return (
            <Tab.Pane>
                <Divider hidden/>
                <EnrollmentsTableSection
                    enrollments={enrollments}
                    isRelated={true}
                />
            </Tab.Pane>
        );
    };
}

EnrollmentDetailsViewAdmin.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(store) {
    return {
        fetchEnrollment: store.fetchEnrollment,
        fetchRelatedEnrollments: store.fetchRelatedEnrollments
    };
}

export default connect(mapStateToProps)(EnrollmentDetailsViewAdmin);