export const FEMALE = 'FEMALE';
export const MALE = 'MALE';
export const DEFAULT_CHILD_STATE = {
    id: null,
    uuid: '',
    firstName: '',
    lastName: '',
    middleName: '',
    birthDate: '',
    created: '',
    sex: '',
    documentType: 'BIRTH_CERTIFICATE',
    documentId: '',
    documentInfo: '',
    otherDocument: '',
    address: '',
    registrationAddress: '',
    parentName: '',
    parentEmail: '',
    parentPhone: '',
    additionalInfo: '',
    privilegeIds: [],
    privilegeDisabled: false,
};