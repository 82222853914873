import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Header, Pagination, Segment } from 'semantic-ui-react';
import { doGetEventList } from '../../actions/event/eventListAction';
import { EXPLORER_URL } from '../../utils/settings';
import { EVENTS_ON_PAGE, RECEIVED } from '../../const/variables';
import { getEnrollmentStatusName } from '../../utils/enrollmentUtils';
import _ from 'lodash';
import t from '../../services/Translate';
import { formatTimestampToDateTimeString } from '../../utils/dateTime';

const ENROLLMENT_STATUS_CHANGE = 'ENROLLMENT_STATUS_CHANGE';
const GROUP_MAX_CHILDREN_CHANGE = 'GROUP_MAX_CHILDREN_CHANGE';
const GROUP_MAX_PRIVILEGED_CHILDREN_CHANGE = 'GROUP_MAX_PRIVILEGED_CHILDREN_CHANGE';

function getHeader(event) {
    switch (event.eventType) {
    case ENROLLMENT_STATUS_CHANGE:
        return 'Зміна статусу заявки';
    case GROUP_MAX_CHILDREN_CHANGE:
        return 'Зміна максимальної кількості дітей';
    case GROUP_MAX_PRIVILEGED_CHILDREN_CHANGE:
        return 'Зміна максимальної кількості дітей з пільгою';
    default:
        return null;
    }
}

function isReceived(event) {
    return event.newValue === RECEIVED && event.oldValue === RECEIVED;
}

function getAction(event) {
    if (isReceived(event)) {
        return 'подав нову заявку';
    } else {
        return 'перевів заявку';
    }
}

function getDeclarant(event) {
    if (event.account) {
        return 'Адміністратор';
    } else  {
        return 'Батько';
    }
}

function getDeclarantName(event) {
    if (!event.account || !event.account.accountInfo) {
        return ' ';
    }

    const { firstName, lastName, middleName} = event.account.accountInfo;

    return(` ${lastName} ${firstName} ${middleName} `);
}

function getStatusText(event) {
    if (isReceived(event)) {
        return null;
    }

    return (
        <>
            зі статуса<em><strong> «{getEnrollmentStatusName(event.oldValue)}» </strong></em>
            в статус<em><strong> «{getEnrollmentStatusName(event.newValue)}» </strong></em>
        </>
    );
}

class EventListComponent extends Component {

    state = { page: 1 };

    componentDidMount() {
        this.props.dispatch(doGetEventList(this.state));
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState, this.state)) {
            this.props.dispatch(doGetEventList(this.state));
        }
    }

    handlePageChange = (event, { activePage }) => {
        if (activePage > 0) { // cannot be less than 1
            this.setState({ page: activePage });
        }
    };

    /**
     *
     * @property event.account
     * @property event.groupName
     * @property event.enrollmentUuid
     */
    render() {

        const { payload } = this.props;

        if (!payload) {
            return null;
        }

        const { events, eventsCount } = payload;

        const eventsData = events.map(event => {
            return (
                <Card key={event.id}>
                    <Card.Content>
                        <Card.Header>{getHeader(event)}</Card.Header>
                        <Card.Meta>
                            {formatTimestampToDateTimeString(event.timestamp)}
                        </Card.Meta>

                        {event.eventType === ENROLLMENT_STATUS_CHANGE &&
                        <Card.Description>
                            {getDeclarant(event)}
                            <em><strong>{getDeclarantName(event)}</strong></em>
                            {getAction(event)}
                            <em>
                                <strong>
                                    <a
                                        title={event.enrollmentUuid}
                                        href={`${EXPLORER_URL}/#/events/key/${event.enrollmentUuid}`}
                                        target='_blank' rel='noopener noreferrer'>
                                        {` ${event.enrollmentUuid.substring(0, 10)}... `}
                                    </a>
                                </strong>
                            </em>
                            {t('eventText')(event.locationName, event.groupName)} {getStatusText(event)}
                        </Card.Description>
                        }

                        {(event.eventType === GROUP_MAX_CHILDREN_CHANGE
                            || event.eventType === GROUP_MAX_PRIVILEGED_CHILDREN_CHANGE) &&
                        <Card.Description>
                            {getDeclarant(event)}
                            <em><strong>{getDeclarantName(event)}</strong></em>
                            {t('eventChangeText')(event)}
                        </Card.Description>
                        }

                    </Card.Content>
                </Card>
            );
        });

        const pagesTotalCount = Math.ceil(eventsCount / EVENTS_ON_PAGE);

        if (eventsCount === 0) {
            return null;
        }

        return (
            <Segment textAlign='left'>
                <Header as='h2'>Останні події в системі</Header>
                <Card.Group itemsPerRow={1}>
                    {eventsData}
                </Card.Group>

                <Segment basic textAlign='center'>
                    <Pagination
                        size='mini'
                        onPageChange={this.handlePageChange}
                        activePage={this.state.page}
                        totalPages={pagesTotalCount}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        prevItem={null}
                        nextItem={null}
                    />
                </Segment>
            </Segment>
        );
    }

}

EventListComponent.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.eventList;
}

export default connect(mapStateToProps)(EventListComponent);