import {
    FETCH_LOCATIONS_FAILURE,
    FETCH_LOCATIONS_REQUEST,
    FETCH_LOCATIONS_SUCCESS
} from '../../actions/location/locationsForAdmin';

const initialState = {
    isFetching: false,
    isFailed: false,
    locations: [],
    errorCode: null,
};
export default function locationsForAdmin(state = initialState, action) {

    switch (action.type) {
    case FETCH_LOCATIONS_REQUEST:
        return {
            ...state,
            isFetching: true,
            isFetchFailed: false,
            isFetched: false,
            locations: [],
            errorCode: null,
        };
    case FETCH_LOCATIONS_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: false,
            isFetched: true,
            locations: action.locations,
            errorCode: null,
        };
    case FETCH_LOCATIONS_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: true,
            isFetched: false,
            locations: [],
            errorCode: action.errorCode,
        };
    default:
        return state;
    }
}