import axios from 'axios';
import { REQUEST_SETTINGS } from '../utils/settings';
import { getResponseData } from './utils';

export const START_REGISTER = 'START_REGISTER';
export const COMPLETE_REGISTER = 'COMPLETE_REGISTER';
export const FAIL_REGISTER = 'FAIL_REGISTER';
export const REGISTER_RESET = 'REGISTER_RESET';

function startRegister() {
    return { type: START_REGISTER };
}

function completeRegister() {
    return { type: COMPLETE_REGISTER };
}

function failRegister(res) {

    const { errorCode } = getResponseData(res);

    return { type: FAIL_REGISTER, errorCode };
}

export function registerReset() {
    return { type: REGISTER_RESET };
}

export function doRegister(username, password, repeatPassword, captchaResponse, agreed) {

    return async function (dispatch) {

        dispatch(startRegister());

        return axios
            .post('/api/register', { username, password, repeatPassword, captchaResponse, agreed }, REQUEST_SETTINGS)
            .then(() => dispatch(completeRegister()))
            .catch(res => dispatch(failRegister(res)));
    };
}