// react
import React, { Component } from 'react';
// libs
import Toast from '../helpers/Toasts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// actions
import { doGetChild, resetGetChild, } from '../../actions/child/fetchChild';
import { doUpdateChild, resetUpdateChild, } from '../../actions/child/updateChild';
// components
import ChildFormComponent from './ChildFormComponent';
import LargeDimmer from '../helpers/Dimmer';
import Attachments from '../attachment/attachments';
import { Tab } from 'semantic-ui-react';
import { DEFAULT_CHILD_STATE } from '.';

class ChildUpdate extends Component {

    state = { ...DEFAULT_CHILD_STATE };

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(resetGetChild());
        dispatch(resetUpdateChild());
    }

    componentDidUpdate(prevProps) {

        const { isUpdated, isFetched, child } = this.props;

        if (isUpdated && !prevProps.isUpdated) {
            this.setState({ ...child });
            Toast('success', 'Дані успішно оновлені');
        }

        if (isFetched && !prevProps.isFetched) {
            this.setState({ ...child });
        }
    }

    componentDidMount() {
        this.props.dispatch(doGetChild(this.props.match.params.childUuid));
    }

    onChildUpdate = () => {
        this.props.dispatch(doUpdateChild({ ...this.state }));
    };

    onDateChange = birthDate => {
        this.setState({ birthDate });
    };

    onChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    render() {
        const { isFetching, isFetched, isUpdating, isUpdated, child } = this.props;

        if (isFetching) {
            return (<LargeDimmer text='Завантаження даних'/>);
        }

        if (isUpdating) {
            return (<LargeDimmer text='Збереження даних'/>);
        }

        if (!isFetched && !isUpdated) {
            return null;
        }

        if (!child) {
            return null;
        }

        const panes = [
            {
                menuItem: 'Інформація про дитину', render: () => {
                    return (
                        <Tab.Pane>
                            <ChildFormComponent
                                headerText='Редагувати дані дитини'
                                submitButtonText='Зберегти'
                                onSubmit={this.onChildUpdate}
                                onDateChange={this.onDateChange}
                                onChange={this.onChange}
                                requirePersonalData={true}
                                value={{ ...this.state }}
                                {...this.props}
                            />
                        </Tab.Pane>
                    );
                }
            },
            {
                menuItem: 'Документи', render: () => {
                    return (
                        <Tab.Pane>
                            <Attachments childUuid={child.uuid}/>
                        </Tab.Pane>
                    );
                }
            },
        ];

        return (<Tab panes={panes}/>);
    }
}

ChildUpdate.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        ...state.fetchChild,
        ...state.updateChild
    };
}

export default connect(mapStateToProps)(ChildUpdate);