import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doLogout } from '../actions/logout';
import { withRouter } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

class LogoutComponent extends Component {

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout(event) {
        event.preventDefault();

        this.props.dispatch(doLogout(this.props.history));
    }

    render() {
        return (
            <Menu.Item  position='right' onClick={this.logout}>
                <Icon name='sign-out'/>
            </Menu.Item>
        );
    }
}

LogoutComponent.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.authentication;
}

export default withRouter(connect(mapStateToProps)(LogoutComponent));