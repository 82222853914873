import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CreateLocationDialog from '../location/dialog/CreateLocationDialog';
import { Button, Segment } from 'semantic-ui-react';
import t from '../../services/Translate';
import OpenDataLink from './OpenDataLink';
import Toast from '../helpers/Toasts';
import { doListLocationsDetails } from '../../actions/location/listLocationsDetails';
import LocationListRegionAdmin from '../location/LocationListRegionAdmin';

class LocationsTab extends Component {

    state = {
        isCreateLocationModalOpened: false,
    };

    handleOnClickAddLocationButton = () => {
        this.setState({ isCreateLocationModalOpened: true });
    };

    onCloseCreateLocationDialog = () => {
        this.setState({ isCreateLocationModalOpened: false });
    };

    onCompleteCreateLocationDialog = () => {
        this.setState({ isCreateLocationModalOpened: false });
        this.props.dispatch(doListLocationsDetails(this.props.regionId));
        Toast('success', t('locationCreated'));
    };

    render() {
        const { regionId } = this.props;
        return (
            <>
                <CreateLocationDialog
                    isOpened={this.state.isCreateLocationModalOpened}
                    regionId={regionId}
                    onClose={this.onCloseCreateLocationDialog}
                    onComplete={this.onCompleteCreateLocationDialog}
                />
                <Segment>
                    <Button
                        labelPosition='right'
                        size='small'
                        content={t('createLocation')}
                        icon='plus'
                        color='green'
                        onClick={this.handleOnClickAddLocationButton}
                    />

                    <OpenDataLink regionId={regionId}/>
                </Segment>

                <LocationListRegionAdmin regionId={regionId}/>
            </>
        );
    }
}

LocationsTab.propTypes = {
    dispatch: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
};


export default connect()(LocationsTab);