import { DOWNLOAD_FAILURE, DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, } from '../../actions/generic/downloadAction';

const initialState = {
    isFetching: false,
    isFetched: false,
    isFetchFailed: false,
    payload: null,
};

export default function createDownloadReducer(entityName) {
    return (state = initialState, action) => {
        switch (action.type) {
        case DOWNLOAD_REQUEST + entityName:
            return {
                ...state,
                ...initialState,
                isFetching: true,
            };
        case DOWNLOAD_SUCCESS + entityName:
            return {
                ...state,
                ...initialState,
                isFetched: true,
                payload: action.payload
            };
        case DOWNLOAD_FAILURE + entityName:
            return {
                ...state,
                ...initialState,
                isFetchFailed: true,
                errorCode: action.errorCode,
                errorMessage: action.errorMessage,
            };
        default:
            return state;
        }
    };
}