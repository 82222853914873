import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Label, Popup, Table } from 'semantic-ui-react';
import { formatTimestampToDateString, formatTimestampToDateTimeString } from '../../utils/dateTime';
import { getEnrollmentStatusName } from '../../utils/enrollmentUtils';
import EnrollmentButtons from './EnrollmentButtons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IN_PROGRESS } from '../../const/variables';

class EnrollmentsTableSection extends Component {

    renderEnrollmentRow = (enrollment, index) => {
        const { isRelated, isAttention, onReload } = this.props;
        const { firstName, middleName, lastName } = enrollment;
        const { created, statusModified, enrollmentStatus } = enrollment;

        return (
            <React.Fragment key={enrollment.id}>
                <Table.Body>
                    <Table.Row key={enrollment.id}>
                        <Table.Cell>{index + 1}</Table.Cell>
                        {(isRelated || isAttention) &&
                        <Table.Cell>
                            {enrollment.locationName}<br/>
                            {enrollment.groupName}<br/>
                            {enrollment.groupPeriod}-{enrollment.groupPeriod + 1}
                        </Table.Cell>
                        }
                        <Table.Cell>
                            {`${lastName} ${firstName} ${middleName}`}
                            <br/>
                            {enrollment.childStatus === 'PRIVILEGED' &&
                                <Label color='green'>пільга</Label>
                            }
                            {enrollment.priority === 0 &&
                                <Label color='green'>пріоритет</Label>
                            }
                        </Table.Cell>
                        <Table.Cell>{formatTimestampToDateTimeString(created)}</Table.Cell>
                        <Table.Cell>
                            {getEnrollmentStatusName(enrollmentStatus)}
                            <br/>
                            {enrollmentStatus === IN_PROGRESS && formatTimestampToDateString(statusModified)}
                        </Table.Cell>

                        {!isRelated &&
                        <Table.Cell singleLine textAlign='right'>
                            <EnrollmentButtons
                                enrollment={enrollment}
                                enrollmentStatus={enrollmentStatus}
                                handleEnrollmentClick={this.handleEnrollmentClick}
                                onReload={onReload}
                            />

                            &nbsp;

                            <Popup trigger={
                                <Button
                                    size='mini'
                                    icon='info'
                                    compact
                                    as={Link}
                                    to={{ pathname: `/admin/locations/${enrollment.locationId}/enrollments/${enrollment.id}` }}
                                />
                            } content='Переглянути'/>

                        </Table.Cell>
                        }
                    </Table.Row>
                </Table.Body>
            </React.Fragment>
        );
    };

    render() {

        const { enrollments, isRelated, isAttention } = this.props;

        return (
            <>
                {enrollments.length > 0 &&
                <Table sortable celled selectable striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            {(isRelated || isAttention) &&
                            <Table.HeaderCell>Заклад</Table.HeaderCell>
                            }
                            <Table.HeaderCell>ПІБ</Table.HeaderCell>
                            <Table.HeaderCell>Створено</Table.HeaderCell>
                            <Table.HeaderCell>Статус</Table.HeaderCell>
                            {!isRelated &&
                            <Table.HeaderCell>Операції</Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    {enrollments.map(this.renderEnrollmentRow)}
                </Table>
                }
                {enrollments.length === 0 && <Header as='h5'>Заявки відсутні</Header>}
                <br/> {/* The last enrollment is impossible to clicks due to help button*/}
                <br/>
            </>
        );
    }
}

EnrollmentsTableSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    enrollments: PropTypes.array.isRequired,
    onReload: PropTypes.func,
    isRelated: PropTypes.bool,
    isAttention: PropTypes.bool
};

export default connect()(EnrollmentsTableSection);