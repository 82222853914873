import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import './semantic/dist/semantic.min.css';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { GA } from './utils/settings';

ReactGA.initialize(GA);
ReactGA.pageview(window.location.pathname + window.location.search);

window.recaptchaOptions = {
    lang: 'uk',
    removeOnUnmount: false,
};

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();