export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DOWNLOAD_ERROR';

export function createDownloadAction(requestBuilder, entityName, fileName) {
    return args => {
        return async function (dispatch) {
            dispatch(downloadRequest());
            requestBuilder(args)
                .then(res => {
                    dispatch(downloadSuccess(res));
                    let url = window.URL.createObjectURL(res.data);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    a.href = '';
                })
                .catch(res => dispatch(downloadFailure(res)));
        };

        function downloadRequest() {
            return { type: DOWNLOAD_REQUEST + entityName };
        }

        function downloadSuccess(res) {
            const payload = res.data;
            return { type: DOWNLOAD_SUCCESS + entityName, payload };
        }

        function downloadFailure() {
            return { type: DOWNLOAD_FAILURE + entityName };
        }
    };
}