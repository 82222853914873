import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { doListLocationsDetails } from '../../actions/location/listLocationsDetails';
import LargeDimmer from '../helpers/Dimmer';
import LocationListAdminSection from './LocationListAdminSection';

class LocationListRegionAdmin extends Component {

    componentDidMount() {
        this.props.dispatch(doListLocationsDetails(this.props.regionId));
    }

    render() {

        const { locations, isFetched, isFetching } = this.props;

        if (isFetching) {
            return (<LargeDimmer text='Завантаження даних'/>);
        }

        if (!isFetched) {
            return null;
        }

        return (
            <LocationListAdminSection locations={locations}/>
        );
    }
}

function mapStateToProps(state) {
    return state.listLocationsDetails;
}

LocationListRegionAdmin.propTypes = {
    dispatch: PropTypes.func.isRequired,
    regionId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(LocationListRegionAdmin);
