import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LargeDimmer from '../helpers/Dimmer';
import { Button, Checkbox, Form, Header, Icon, Popup, Table } from 'semantic-ui-react';
import presenceFactsActions from '../../actions/presence-facts';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/settings';
import Toast from '../helpers/Toasts';
import { isGroupAdmin, isLocationAdmin } from '../../services/AuthService';
import _ from 'lodash';

class PresenceFacts extends Component {

    state = {
        presence: {},
        sickness: {},
        foodFacts: {},
        toggleFoodFacts: {}
    };

    componentDidUpdate(prevProps) {
        const { payload, isSaved } = this.props;
        if (payload && payload !== prevProps.payload) {
            const presence = {};
            const sickness = {};
            const foodFacts = {};
            payload.forEach(fact => {
                const enrollmentId = fact.enrollment.id;
                presence[enrollmentId] = fact.present;
                sickness[enrollmentId] = fact.sick;
                const enrollmentFoodFactsArray = fact.foodFacts || [];
                const enrollmentFoodFacts = {};
                enrollmentFoodFactsArray.forEach(value => {
                    if (value) {
                        enrollmentFoodFacts[value] = true;
                    }
                });
                foodFacts[enrollmentId] = enrollmentFoodFacts;
            });
            this.setState({ presence, sickness, foodFacts });
        }

        if (isSaved && !prevProps.isSaved) {
            Toast('success', 'Дані оновлені!');
            this.props.dispatch(presenceFactsActions.fetchDimensions(this.props.locationId));
        }
    }

    togglePresent = fact => {
        const enrollmentId = fact.enrollment.id;
        const present = !this.state.presence[enrollmentId];

        const enrollmentFoodFacts = present ? { ...this.state.foodFacts[enrollmentId] } : {};

        this.setState({
            presence: { ...this.state.presence, [enrollmentId]: present },
            sickness: { ...this.state.sickness, [enrollmentId]: false },
            foodFacts: {
                ...this.state.foodFacts,
                [enrollmentId]: enrollmentFoodFacts
            }
        });
    };

    toggleSick = fact => {
        const enrollmentId = fact.enrollment.id;
        this.setState({ sickness: { ...this.state.sickness, [enrollmentId]: !this.state.sickness[enrollmentId] } });
    };

    toggleFoodFact = (fact, foodFactTypeId) => {
        const enrollmentId = fact.enrollment.id;
        const enrollmentFoodFacts = { ...this.state.foodFacts[enrollmentId] } || {};
        enrollmentFoodFacts[foodFactTypeId] = !enrollmentFoodFacts[foodFactTypeId];

        this.setState({
            foodFacts: {
                ...this.state.foodFacts,
                [enrollmentId]: enrollmentFoodFacts,
            }
        });
    };

    renderFact = (fact, index) => {
        const { foodFactTypes } = this.props;
        const { presence, sickness } = this.state;
        const present = presence[fact.enrollment.id];
        const sick = sickness[fact.enrollment.id];
        return (
            <Table.Row key={fact.enrollment.id}>
                <Table.Cell>{index}</Table.Cell>
                <Table.Cell>
                    {`${fact.enrollment.lastName} ${fact.enrollment.firstName} ${fact.enrollment.middleName}`}
                </Table.Cell>
                <Table.Cell>
                    {fact.privilege &&
                    <Popup content={fact.privilege.name} trigger={
                        <Icon name='check' color='green'/>}/>
                    }
                </Table.Cell>
                <Table.Cell>
                    <Form.Field>
                        <Checkbox
                            toggle
                            disabled={!(isLocationAdmin() || isGroupAdmin())}
                            checked={present}
                            onChange={() => this.togglePresent(fact)}
                        />
                    </Form.Field>
                </Table.Cell>
                <Table.Cell>
                    <Form.Field>
                        <Checkbox
                            toggle
                            disabled={!(isLocationAdmin() || isGroupAdmin()) || present}
                            checked={sick}
                            onChange={() => this.toggleSick(fact)}
                        />
                    </Form.Field>
                </Table.Cell>
                {foodFactTypes.map(foodFactType => {
                    const enrollmentFoodFact = this.state.foodFacts[fact.enrollment.id] || {};
                    const foodFact = !!enrollmentFoodFact[foodFactType.id];

                    return (
                        <Table.Cell key={foodFactType.id}>
                            <Checkbox
                                toggle
                                disabled={!(isLocationAdmin() || isGroupAdmin()) || !present}
                                checked={foodFact}
                                onChange={() => this.toggleFoodFact(fact, foodFactType.id)}
                            />
                        </Table.Cell>
                    );
                })}
            </Table.Row>
        );
    };

    savePresenceFacts = () => {
        const presenceDate = moment().format(DATE_FORMAT);
        this.props.dispatch(presenceFactsActions.saveAll({
            groupId: this.props.groupId,
            presence: this.state.presence,
            sickness: this.state.sickness,
            foodFacts: this.state.foodFacts,
            presenceDate,
        }));
    };

    countPresent = facts => {
        let count = 0;
        facts.forEach(fact => {
            if (this.state.presence[fact.enrollment.id]) {
                count++;
            }
        });
        return count;
    };

    countSick = facts => {
        let count = 0;
        facts.forEach(fact => {
            if (this.state.sickness[fact.enrollment.id]) {
                count++;
            }
        });
        return count;
    };

    countPrivileges = facts => {
        let count = 0;
        facts.forEach(fact => {
            if (fact.privilege) {
                count++;
            }
        });
        return count;
    };

    countFoodFacts = foodFactTypeId => {
        let count = 0;
        _.values(this.state.foodFacts).forEach(enrollmentFoodFact => {
            if (enrollmentFoodFact[foodFactTypeId]) {
                count++;
            }
        });
        return count;
    };

    toggleFoodFactType = foodFactTypeId => {

        const currentFoodFactState = !!this.state.toggleFoodFacts[foodFactTypeId];

        const foodFacts = { ...this.state.foodFacts };
        _.keys(foodFacts).forEach(enrollmentId => {
            const present = this.state.presence[enrollmentId];

            if (present || currentFoodFactState) {
                foodFacts[enrollmentId][foodFactTypeId] = !currentFoodFactState;
            }
        });

        this.setState({
            toggleFoodFacts: {
                ...this.state.toggleFoodFacts,
                [foodFactTypeId]: !currentFoodFactState
            },
            foodFacts
        });
    };

    renderTotals = (payload, foodFactTypes) => {
        const total = payload.length;
        const presentCount = this.countPresent(payload);
        const sickCount = this.countSick(payload);
        const privilegesCount = this.countPrivileges(payload);
        return (
            <Table.Footer>
                <Table.Row positive>
                    <Table.Cell colSpan='2'>{total}</Table.Cell>
                    <Table.Cell>{privilegesCount}</Table.Cell>
                    <Table.Cell>{presentCount}</Table.Cell>
                    <Table.Cell>{sickCount}</Table.Cell>
                    {foodFactTypes.map(foodFactType =>
                        <Table.Cell key={foodFactType.id}>{this.countFoodFacts(foodFactType.id)}</Table.Cell>
                    )}
                </Table.Row>
            </Table.Footer>
        );
    };

    render() {
        const { isFetching, isSaving, payload, foodFactTypes } = this.props;

        if (isFetching || isSaving) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (!payload) {
            return null;
        }

        if (payload.length === 0) {
            return <Header as='h5' content='Наразі прийнятих заявок немає.'/>;
        }

        let index = 1;
        return (
            <>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>ПІБ</Table.HeaderCell>
                            <Table.HeaderCell>Пільга</Table.HeaderCell>
                            <Table.HeaderCell>Присутній</Table.HeaderCell>
                            <Table.HeaderCell>Хворіє</Table.HeaderCell>
                            {foodFactTypes.map(foodFactType =>
                                <Table.HeaderCell key={foodFactType.id}>
                                    {foodFactType.name}
                                    <br/>
                                    <Checkbox
                                        toggle
                                        disabled={!(isLocationAdmin() || isGroupAdmin())}
                                        checked={this.state.toggleFoodFacts[foodFactType.id]}
                                        onChange={() => this.toggleFoodFactType(foodFactType.id)}
                                    />
                                </Table.HeaderCell>
                            )}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {payload.map(fact => {
                            return this.renderFact(fact, index++);
                        })}

                    </Table.Body>
                    {this.renderTotals(payload, foodFactTypes)}
                </Table>

                {(isLocationAdmin() || isGroupAdmin()) &&
                <Button type='button' positive onClick={this.savePresenceFacts}>
                    Зберегти
                </Button>
                }
            </>
        );
    }

}

PresenceFacts.propTypes = {
    dispatch: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
    groupId: PropTypes.number.isRequired,
    foodFactTypes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return state.presenceFacts;
}

export default connect(mapStateToProps)(PresenceFacts);