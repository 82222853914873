import {
    FETCH_REGION_FAILURE,
    FETCH_REGION_REQUEST,
    FETCH_REGION_SUCCESS,
    UPDATE_REGION_FAILURE,
    UPDATE_REGION_REQUEST,
    UPDATE_REGION_SUCCESS,
} from '../../actions/region';

const initialState = {
    isFetching: false,
    isFetched: false,

    isUpdating: false,
    isUpdated: false,

    isFailed: false,
    errorCode: null,
    errorMessage: null,
    fieldErrors: null,

    payload: null,
};

export default function fetchRegion(state = initialState, action) {

    switch (action.type) {
    case FETCH_REGION_REQUEST:
        return {
            ...state,
            isFetching: true,
            isFetched: false,
            isUpdating: false,
            isUpdated: false,
            isFailed: false,
            errorCode: null,
            errorMessage: null,
            fieldErrors: null,
            payload: null,
        };
    case FETCH_REGION_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetched: true,
            isUpdating: false,
            isUpdated: false,
            isFailed: false,
            errorCode: null,
            errorMessage: null,
            fieldErrors: null,
            payload: action.region
        };
    case FETCH_REGION_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            isUpdating: false,
            isUpdated: false,
            isFailed: true,
            errorCode: action.errorCode,
            errorMessage: action.errorMessage,
            fieldErrors: null,
        };
    case UPDATE_REGION_REQUEST:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            isUpdating: true,
            isUpdated: false,
            isFailed: false,
            errorCode: null,
            errorMessage: null,
            fieldErrors: null,
        };
    case UPDATE_REGION_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            isUpdating: false,
            isUpdated: true,
            isFailed: false,
            errorCode: null,
            errorMessage: null,
            fieldErrors: null,
            payload: action.region
        };
    case UPDATE_REGION_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            isUpdating: false,
            isUpdated: false,
            isFailed: true,
            errorCode: action.errorCode,
            errorMessage: action.errorMessage,
            fieldErrors: action.fieldErrors,
        };
    default:
        return state;
    }
}