import { getDateFromString } from '../../utils/dateTime';
import { isConfirmation } from '../../utils/enrollmentUtils';

export function transform(payload) {

    // TODO simplify
    const groupId = payload.group ? payload.group.id : payload.groupId;

    return {
        ...payload,
        birthDate: getDateFromString(payload.birthDate),
        created: new Date(payload.created),
        privilegeDisabled: isConfirmation(payload),
        groupId
    };
}