import React, { Component } from 'react';
import { Button, Header, Icon, Progress, Segment, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const EMAIL_STEP = 'EMAIL_STEP';
export const PASSWORD_STEP = 'PASSWORD_STEP';
export const NOT_A_ROBOT_STEP = 'NOT_A_ROBOT_STEP';
export const AGREE_STEP = 'AGREE_STEP';
export const REGISTER_STEP = 'REGISTER_STEP';

const STEPS = [
    EMAIL_STEP,
    PASSWORD_STEP,
    NOT_A_ROBOT_STEP,
    AGREE_STEP,
    REGISTER_STEP
];

const nextButtonStyle = {position: 'absolute', right: 0, bottom: 0, margin: 5};

class RegistrationHelp extends Component {

    emailTab = () => {
        return (
            <>
                <Header as='h4'>
                    <Icon name='mail outline' />
                    <Header.Content>Вкажіть свою електронну пошту</Header.Content>
                </Header>
                <Header as='h5' content='Наприклад: '/>
                <i>
                    <Header as='h5' content='myemailaddress@ukr.net'/>
                </i>
                <Button positive style={nextButtonStyle} onClick={() => this.props.onChangeStep(PASSWORD_STEP)}>Далі</Button>
            </>
        );
    };

    passwordTab = () => {
        return (
            <>
                <Header as='h4'>
                    <Icon name='ellipsis horizontal' />
                    <Header.Content>Вкажіть свій пароль</Header.Content>
                </Header>
                <Header as='h5'>
                    Пароль повинен складатися з мінімум 8 символів та містити:
                    <ul>
                        <li>Мінімум 1 спецсимвол: <i>!@#$%^&*</i></li>
                        <li>Мінімум 1 маленьку літеру</li>
                        <li>Мінімум 1 велику літеру</li>
                    </ul>
                </Header>
                <br/>
                <br/>

                <Button positive style={nextButtonStyle} onClick={() => this.props.onChangeStep(NOT_A_ROBOT_STEP)}>Далі</Button>
            </>
        );
    };

    captchaTab = () => {
        return (
            <>
                <Header as='h4'>
                    <Icon name='check' />
                    <Header.Content>Пройдіть капчу</Header.Content>
                </Header>
                <Header as='h5'>
                    Цей крок необхідний для боротьби зі спамом
                </Header>

                <Button positive style={nextButtonStyle} onClick={() => this.props.onChangeStep(AGREE_STEP)}>Далі</Button>
            </>
        );
    };

    agreementTab = () => {
        return (
            <>
                <Header as='h4'>
                    <Icon name='user outline' />
                    <Header.Content>Угода користувача</Header.Content>
                </Header>
                <Header as='h5'>
                    Підтвердіть що ознайомлені з правилами користування сервісом
                </Header>

                <Button positive style={nextButtonStyle} onClick={() => this.props.onChangeStep(REGISTER_STEP)}>Далі</Button>
            </>
        );
    };

    finalTab = () => {
        return (
            <>
                <Header as='h4'>
                    <Icon name='thumbs up outline' />
                    <Header.Content>Завершення реєстрації</Header.Content>
                </Header>
                <Header as='h5'>
                    Нажмить кнопку "зареєструватися", у ввідповідь ми надишлемо вам email для підтвердження реєстрації
                </Header>

            </>
        );
    };

    render() {
        const { step, onChangeStep } = this.props;

        const activeIndex = STEPS.indexOf(step);

        const panes = [
            { menuItem: 'Електрона пошта', render: () => <Tab.Pane>{this.emailTab()}</Tab.Pane> },
            { menuItem: 'Пароль', render: () => <Tab.Pane>{this.passwordTab()}</Tab.Pane> },
            { menuItem: 'Капча', render: () => <Tab.Pane>{ this.captchaTab()}</Tab.Pane> },
            { menuItem: 'Угода', render: () => <Tab.Pane>{this.agreementTab()}</Tab.Pane> },
            { menuItem: 'Реєстрація', render: () => <Tab.Pane>{this.finalTab()}</Tab.Pane> },
        ];

        return (
            <Segment basic>
                <Header as='h2' content='Допомога'/>
                <Progress total={5} value={activeIndex + 1} success/>
                <Tab
                    onTabChange={(e, { activeIndex }) => onChangeStep(STEPS[activeIndex])}
                    menu={{ fluid: true, vertical: true, tabular: true }}
                    panes={panes}
                    activeIndex={activeIndex}/>
            </Segment>
        );
    }
}

RegistrationHelp.propTypes = {
    onChangeStep: PropTypes.func.isRequired
};

export default RegistrationHelp;