import { createGetParams, getResponseData } from '../utils';
import axios from 'axios';
import { transform } from '.';

export const FETCH_GROUP_REQUEST = 'FETCH_GROUP_REQUEST';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_FAILURE = 'FETCH_GROUP_FAILURE';

function fetchGroupRequest() {
    return { type: FETCH_GROUP_REQUEST };
}

function fetchGroupSuccess(res) {
    return { type: FETCH_GROUP_SUCCESS, group: transform(res.data) };
}

function fetchGroupFailure(error) {
    const { errorCode } = getResponseData(error);
    return { type: FETCH_GROUP_FAILURE, errorCode };
}

export function doFetchGroup(groupId) {

    return async function (dispatch) {

        dispatch(fetchGroupRequest());

        return axios
            .get(`/api/groups/${groupId}`, createGetParams())
            .then(res => dispatch(fetchGroupSuccess(res)))
            .catch(error => dispatch(fetchGroupFailure(error)));
    };
}