import {
    START_UPDATE_USER_INFO,
    COMPLETE_UPDATE_USER_INFO,
    FAIL_UPDATE_USER_INFO,
    START_GET_USER_INFO,
    COMPLETE_GET_USER_INFO,
    FAIL_GET_USER_INFO,
} from '../actions/userInfo';

const initialState = {
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    isFetchFailed: false,
    isUpdateFailed: false,
    errorCode: null,
    fieldErrors: null,
};

export default function userInfo(state = initialState, action) {

    switch (action.type) {
    case START_UPDATE_USER_INFO:
        return {
            ...state,
            isFetching: false,
            isUpdating: true,
            isUpdated: false,
            isFetchFailed: false,
            isUpdateFailed: false,
            errorCode: null,
            fieldErrors: null,
        };
    case COMPLETE_UPDATE_USER_INFO:
        return {
            ...state,
            isFetching: false,
            isUpdating: false,
            isUpdated: true,
            isFetchFailed: false,
            isUpdateFailed: false,
            userInfoData: action.userInfoData,
            errorCode: null,
            fieldErrors: null,
        };
    case FAIL_UPDATE_USER_INFO:
        return {
            ...state,
            isFetching: false,
            isUpdating: false,
            isUpdated: false,
            isFetchFailed: false,
            isUpdateFailed: true,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
        };
    case START_GET_USER_INFO:
        return {
            ...state,
            isFetching: true,
            isUpdating: false,
            isUpdated: false,
            isFetched: false,
            isFetchFailed: false,
            isUpdateFailed: false,
            userInfoData: {},
            errorCode: null,
            fieldErrors: null,
        };
    case COMPLETE_GET_USER_INFO:
        return {
            ...state,
            isFetching: false,
            isUpdating: false,
            isUpdated: false,
            isFetched: true,
            isFetchFailed: false,
            isUpdateFailed: false,
            userInfoData: action.userInfoData,
            errorCode: null,
            fieldErrors: null,
        };
    case FAIL_GET_USER_INFO:
        return {
            ...state,
            isFetching: false,
            isUpdating: false,
            isUpdated: false,
            isFetchFailed: true,
            isUpdateFailed: false,
            userInfoData: {},
            errorCode: action.errorCode,
            fieldErrors: null,
        };
    default:
        return state;
    }
}