import React, { Component } from 'react';
import { Button, Confirm, Form, Message, Segment } from 'semantic-ui-react';
import EnrollmentMessageModal from '../enrollment/EnrollmentMessageModal';
import PropTypes from 'prop-types';
import { getEnrollmentStatusAction, getEnrollmentStatusName } from '../../utils/enrollmentUtils';
import {
    CANCELLED,
    CONFIRMATION_DISTRIBUTED,
    CONFIRMATION_SOLO,
    CONFIRMED,
    REJECT_REQUEST,
    REJECTED
} from '../../const/variables';
import { isGurtok } from '../../utils/settings';

const initialState = {
    isConfirmDialogOpen: false,
    isEnrollmentStatusUpdateModalOpen: false,
    comment: null
};

class EnrollmentStatusUpdate extends Component {

    state = { ...initialState };

    closeConfirmation = () => this.setState({
        isConfirmDialogOpen: false
    });

    openConfirmation = () => this.setState({
        isConfirmDialogOpen: true
    });

    openEnrollmentStatusUpdateModal = () => {
        this.closeConfirmation();
        this.setState({ isEnrollmentStatusUpdateModalOpen: true });
    };

    closeEnrollmentStatusUpdateModal = () => {
        this.setState({ ...initialState });
        this.props.onDone();
    };

    handleCommentChange = (event, { value }) => {
        this.setState({ comment: value });
    };

    renderConfirmMessageTitle = (enrollment, newStatus) => {
        const { firstName, middleName, lastName } = enrollment;

        return (
            <>
                Змінити статус заявки для <strong>{lastName} {firstName} {middleName}</strong> на
                статус <strong>{getEnrollmentStatusName(newStatus)}</strong>?
            </>
        );
    };

    renderConfirmEnrollmentWarning = () => {
        return (
            <>
                <Message
                    warning
                    icon='exclamation'
                    header='Увага!'
                    content='Всі інші заявки цієї дитини, крім поточної, будуть скасовані, включаючи ті, що знаходяться в інших установах.'
                />
                <Message
                    error
                    icon='exclamation'
                    header='Увага!'
                    content='Перед підтвердженням заявки необхідно переконатися, що батьки надали оригінали всіх необхідних документів!'
                />
            </>
        );
    };

    renderComment = () => {
        return (
            <>
                <br/>
                <br/>
                <Form>
                    <Form.Field>
                        <Form.TextArea
                            name='comment'
                            placeholder='Коментар'
                            onChange={this.handleCommentChange}
                        />
                    </Form.Field>
                </Form>
            </>
        );
    };

    getConfirmMessage = (enrollment, newStatus) => {

        const { rejectionCommentRequired, confirmationMode } = enrollment;

        const isDistributedRejectionRequest = newStatus === REJECT_REQUEST && confirmationMode === CONFIRMATION_DISTRIBUTED;
        const isSoloRejectionRequest = newStatus === REJECTED && confirmationMode === CONFIRMATION_SOLO;
        const shouldProcessRejectionComment = rejectionCommentRequired && (isDistributedRejectionRequest || isSoloRejectionRequest);

        return (
            <Segment basic>
                {this.renderConfirmMessageTitle(enrollment, newStatus)}
                {newStatus === CONFIRMED && !isGurtok() && this.renderConfirmEnrollmentWarning()}
                {shouldProcessRejectionComment && this.renderComment()}
            </Segment>);
    };

    render() {

        const { enrollment, enrollmentStatus, size } = this.props;

        const { isEnrollmentStatusUpdateModalOpen, isConfirmDialogOpen, comment } = this.state;

        const isNegative = [CANCELLED, REJECTED, REJECT_REQUEST].includes(enrollmentStatus);

        const buttonTitle = getEnrollmentStatusAction(enrollmentStatus);

        return (
            <>
                <Button
                    compact
                    size={size || 'mini'}
                    onClick={this.openConfirmation}
                    positive={!isNegative}
                    negative={isNegative}>
                    {buttonTitle}
                </Button>

                <Confirm
                    content={this.getConfirmMessage(enrollment, enrollmentStatus)}
                    open={isConfirmDialogOpen}
                    cancelButton='Назад'
                    confirmButton='Так, змінити статус'
                    onCancel={this.closeConfirmation}
                    onConfirm={this.openEnrollmentStatusUpdateModal}
                />

                <EnrollmentMessageModal
                    onClose={this.closeEnrollmentStatusUpdateModal}
                    isOpen={isEnrollmentStatusUpdateModalOpen}
                    enrollment={enrollment}
                    enrollmentStatus={enrollmentStatus}
                    comment={comment}
                />
            </>
        );
    }
}

EnrollmentStatusUpdate.propTypes = {
    enrollment: PropTypes.object.isRequired,
    enrollmentStatus: PropTypes.string.isRequired,
    onDone: PropTypes.func.isRequired,
    size: PropTypes.string
};

export default EnrollmentStatusUpdate;