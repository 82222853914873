import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';

export const START_LIST_LOCATIONS = 'START_LIST_LOCATIONS';
export const COMPLETE_LIST_LOCATIONS = 'COMPLETE_LIST_LOCATIONS';
export const FAIL_LIST_LOCATIONS = 'FAIL_LIST_LOCATIONS';

function startListLocations() {
    return { type: START_LIST_LOCATIONS };
}

function completeListLocations(res) {
    return { type: COMPLETE_LIST_LOCATIONS, locations: res.data };
}

function failListLocations(res) {
    const { errorCode } = getResponseData(res);
    return { type: FAIL_LIST_LOCATIONS, errorCode };
}

/**
 * Fetch list of locations current 'USER' can view according to his 'region'
 */
export function doListLocations() {

    return async function (dispatch) {

        dispatch(startListLocations());

        return axios
            .get('/api/locations', createGetParams())
            .then(res => dispatch(completeListLocations(res)))
            .catch(res => dispatch(failListLocations(res)));
    };
}