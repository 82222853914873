import axios from 'axios/index';
import { createGetParams, getResponseData } from '../utils';

export const FETCH_RELATED_ENROLLMENTS_REQUEST = 'FETCH_RELATED_ENROLLMENTS_REQUEST';
export const FETCH_RELATED_ENROLLMENTS_SUCCESS = 'FETCH_RELATED_ENROLLMENTS_SUCCESS';
export const FETCH_RELATED_ENROLLMENTS_FAILURE = 'FETCH_RELATED_ENROLLMENTS_FAILURE';

export function fetchRelatedEnrollments(enrollmentId) {

    return async function (dispatch) {

        dispatch(fetchRelatedEnrollmentsRequest());

        return axios
            .get(`/api/enrollments/${enrollmentId}/related`, createGetParams())
            .then(res => dispatch(fetchRelatedEnrollmentsSuccess(res)))
            .catch(error => dispatch(fetchRelatedEnrollmentsFailure(error)));
    };

    function fetchRelatedEnrollmentsRequest() {
        return { type: FETCH_RELATED_ENROLLMENTS_REQUEST };
    }

    function fetchRelatedEnrollmentsSuccess(res) {

        const enrollments = res.data;

        return { type: FETCH_RELATED_ENROLLMENTS_SUCCESS, enrollments };
    }

    function fetchRelatedEnrollmentsFailure(error) {

        const { errorCode } = getResponseData(error);

        return { type: FETCH_RELATED_ENROLLMENTS_FAILURE, errorCode };
    }
}