import {
    CREATE_GROUP_REQUEST,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILURE,
    CREATE_GROUP_RESET,
} from '../../actions/group/createGroup';

const initialState = {
    isCreating: false,
    isCreateFailed: false,
    isCreated: false,
    errorCode: null,
    fieldErrors: null,
};

export default function createGroup(state = initialState, action) {

    switch (action.type) {
    case CREATE_GROUP_REQUEST:
        return {
            ...state,
            isCreating: true,
            isCreateFailed: false,
            isCreated: false,
            errorCode: null,
            fieldErrors: null,
        };
    case CREATE_GROUP_SUCCESS:
        return {
            ...state,
            isCreating: false,
            isCreateFailed: false,
            isCreated: true,
            errorCode: null,
            fieldErrors: null,
        };
    case CREATE_GROUP_FAILURE:
        return {
            ...state,
            isCreating: false,
            isCreateFailed: true,
            isCreated: false,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
        };
    case CREATE_GROUP_RESET:
        return initialState;
    default:
        return state;
    }
}