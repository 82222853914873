import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AddFoodPrivilegeDialog from '../food/AddFoodPrivilegeDialog';
import FoodPrivilegeTable from '../food/FoodPrivilegeTable';
import foodPrivilegeEnrollmentListActions from '../../../actions/food-privilege-enrollment/list';
import { isLocationAdmin } from '../../../services/AuthService';

class FoodPrivilegesTab extends Component {

    state = {
        isCreatePrivilegeModalOpened: false,
    };

    onAddPrivilege = () => {
        this.setState({ isCreatePrivilegeModalOpened: true });
    };

    onCloseAddPrivilegeDialog = () => {
        this.setState({ isCreatePrivilegeModalOpened: false });
    };

    onCompleteAddPrivilegeDialog = () => {
        const { enrollment } = this.props;
        this.props.dispatch(foodPrivilegeEnrollmentListActions.fetchByEnrollmentId(enrollment.id));
        this.setState({ isCreatePrivilegeModalOpened: false });
    }

    render() {

        const { enrollment } = this.props;

        if (!enrollment) {
            return null;
        }

        const { isCreatePrivilegeModalOpened } = this.state;

        return (
            <>
                <FoodPrivilegeTable enrollment={enrollment}/>
                <Button
                    labelPosition='right'
                    size='small'
                    content='Додати пільгу'
                    icon='plus'
                    color='green'
                    floated='right'
                    disabled={!isLocationAdmin()}
                    onClick={this.onAddPrivilege}
                />
                <AddFoodPrivilegeDialog
                    enrollment={enrollment}
                    onClose={this.onCloseAddPrivilegeDialog}
                    onComplete={this.onCompleteAddPrivilegeDialog}
                    isOpened={isCreatePrivilegeModalOpened}
                />
            </>
        );
    }
}

FoodPrivilegesTab.propTypes = {
    dispatch: PropTypes.func.isRequired,
    enrollment: PropTypes.object.isRequired,
};

export default connect()(FoodPrivilegesTab);