import { COMPLETE_ADD_CHILD, FAIL_ADD_CHILD, RESET_ADD_CHILD, START_ADD_CHILD, } from '../../actions/child/createChild';

const initialState = {
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errorCode: null,
    fieldErrors: null,
};

export default function createChild(state = initialState, action) {

    switch (action.type) {
    case RESET_ADD_CHILD:
        return initialState;
    case START_ADD_CHILD:
        return {
            ...state,
            isCreated: false,
            isCreating: true,
            isFailed: false,
            errorCode: null,
            fieldErrors: null,
        };
    case COMPLETE_ADD_CHILD:
        return {
            ...state,
            isCreated: true,
            isCreating: false,
            isFailed: false,
            errorCode: null,
            fieldErrors: null,
            child: action.child,
        };
    case FAIL_ADD_CHILD:
        return {
            ...state,
            isCreated: false,
            isCreating: false,
            isFailed: true,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
        };
    default:
        return state;
    }
}