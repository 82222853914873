import { Child, Sex } from './child.model';
import { Group } from './group.model';
import { LocationBase } from './location.model';
import { AccountInfo } from './account-info.model';
import { Account, RoleType } from './account.model';
import { Privilege } from './privilege.model';

export enum EnrollmentStatus {
    RECEIVED = 'RECEIVED',
    IN_PROGRESS = 'IN_PROGRESS',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
    CONFIRM_REQUEST = 'CONFIRM_REQUEST',
    REJECT_REQUEST = 'REJECT_REQUEST',
}

export interface EnrollmentDetails {
    readonly id: number,
    readonly uuid: string,
    readonly created: number,
    readonly modified: number,
    readonly statusModified: number,
    readonly enrollmentStatus: EnrollmentStatus,
    readonly child: Child,
    readonly group: Group,
    readonly location: LocationBase,
    readonly accountInfo: AccountInfo,
    readonly account: Account,
    readonly firstName: string,
    readonly middleName: string,
    readonly lastName: string,
    readonly birthDate: string,
    readonly sex: Sex,
    readonly documentId: string,
    readonly documentInfo: string,
    readonly otherDocument: string,
    readonly address: string,
    readonly registrationAddress: string,
    readonly parentName: string,
    readonly parentEmail: string,
    readonly parentPhone: string,
    readonly additionalInfo: string,
    readonly privilegeIds: number[],
    readonly privileges: Privilege[],
    readonly statusComment: string,
    readonly priorityEnabled: boolean,
    readonly prioritized: boolean,
    readonly createdBy: RoleType,
}

export interface Enrollment {
    readonly id: number,
    readonly uuid: string,
    readonly firstName: string,
    readonly middleName: string,
    readonly lastName: string,
    readonly documentId: string,
    readonly birthDate: string,
    readonly created: number,
    readonly modified: number,
    readonly statusModified: number,
    readonly priority: number,
    readonly enrollmentStatus: EnrollmentStatus,
    readonly locationId: number,
    readonly locationName: string,
    readonly groupId: number,
    readonly groupName: string,
    readonly groupPeriod: number,
    readonly order: number,
    readonly statusComment: string,
    createdBy: RoleType,
}