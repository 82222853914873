import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { getResponseData } from '../utils';
import { loadAccountDetails } from '../accountDetails';

export const START_APPLY_TO_GROUP = 'START_APPLY_TO_GROUP';
export const COMPLETE_APPLY_TO_GROUP = 'COMPLETE_APPLY_TO_GROUP';
export const FAIL_APPLY_TO_GROUP = 'FAIL_APPLY_TO_GROUP';
export const RESET_APPLY_TO_GROUP = 'RESET_APPLY_TO_GROUP';

function startApplyToGroup() {
    return { type: START_APPLY_TO_GROUP };
}

function completeApplyToGroup() {
    return { type: COMPLETE_APPLY_TO_GROUP };
}

function failApplyToGroup(error) {

    const { errorCode } = getResponseData(error);

    return { type: FAIL_APPLY_TO_GROUP, errorCode };
}

export function doApplyToGroup(enrollment) {

    return async function (dispatch) {

        dispatch(startApplyToGroup());

        return axios
            .post('/api/enrollments', enrollment, REQUEST_SETTINGS)
            .then(() => {
                dispatch(completeApplyToGroup());
                dispatch(loadAccountDetails());
            })
            .catch(error => dispatch(failApplyToGroup(error)));
    };
}

export function resetApplyToGroup() {
    return { type: RESET_APPLY_TO_GROUP };
}