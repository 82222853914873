import {
    DELETE_ENTITY_FAILURE,
    DELETE_ENTITY_REQUEST,
    DELETE_ENTITY_SUCCESS,
    FETCH_ENTITY_FAILURE,
    FETCH_ENTITY_REQUEST,
    FETCH_ENTITY_SUCCESS,
    RESET,
    SAVE_ENTITY_FAILURE,
    SAVE_ENTITY_REQUEST,
    SAVE_ENTITY_SUCCESS,
    UPDATE_ENTITY_FAILURE,
    UPDATE_ENTITY_REQUEST,
    UPDATE_ENTITY_SUCCESS,
} from '../../actions/generic/entityAction';


const initialState = {
    isFetching: false,
    isFetched: false,
    isFetchFailed: false,
    isUpdating: false,
    isUpdated: false,
    isUpdateFailed: false,
    isSaving: false,
    isSaved: false,
    isSaveFailed: false,
    isDeleting: false,
    isDeleted: false,
    isDeleteFailed: false,
    errorCode: null,
    errorMessage: null,
    fieldErrors: null,
    payload: null,
};

export default function createEntityReducer(entityName) {
    return (state = initialState, action) => {
        switch (action.type) {
        case FETCH_ENTITY_REQUEST + entityName:
            return {
                ...state,
                ...initialState,
                isFetching: true,
            };
        case FETCH_ENTITY_SUCCESS + entityName:
            return {
                ...state,
                ...initialState,
                isFetched: true,
                payload: action.payload
            };
        case FETCH_ENTITY_FAILURE + entityName:
            return {
                ...state,
                ...initialState,
                isFetchFailed: true,
                errorCode: action.errorCode,
                errorMessage: action.errorMessage,
            };
        case SAVE_ENTITY_REQUEST + entityName:
            return {
                ...state,
                ...initialState,
                payload: state.payload,
                isSaving: true,
            };
        case SAVE_ENTITY_SUCCESS + entityName:
            return {
                ...state,
                ...initialState,
                isSaved: true,
                payload: action.payload
            };
        case SAVE_ENTITY_FAILURE + entityName:
            return {
                ...state,
                ...initialState,
                isSaveFailed: true,
                errorCode: action.errorCode,
                errorMessage: action.errorMessage,
                fieldErrors: action.fieldErrors,
            };
        case UPDATE_ENTITY_REQUEST + entityName:
            return {
                ...state,
                ...initialState,
                payload: state.payload,
                isUpdating: true,
            };
        case UPDATE_ENTITY_SUCCESS + entityName:
            return {
                ...state,
                ...initialState,
                isUpdated: true,
                payload: action.payload
            };
        case UPDATE_ENTITY_FAILURE + entityName:
            return {
                ...state,
                ...initialState,
                isUpdateFailed: true,
                errorCode: action.errorCode,
                errorMessage: action.errorMessage,
                fieldErrors: action.fieldErrors,
            };
        case DELETE_ENTITY_REQUEST + entityName:
            return {
                ...state,
                ...initialState,
                payload: state.payload,
                isDeleting: true,
            };
        case DELETE_ENTITY_SUCCESS + entityName:
            return {
                ...state,
                ...initialState,
                isDeleted: true,
                payload: action.payload,
            };
        case DELETE_ENTITY_FAILURE + entityName:
            return {
                ...state,
                ...initialState,
                isDeleteFailed: true,
                errorCode: action.errorCode,
                errorMessage: action.errorMessage,
            };
        case RESET + entityName:
            return { ...initialState };
        default:
            return state;
        }
    };
}