import React, { Component } from 'react';
import { connect } from 'react-redux';
import LargeDimmer from '../helpers/Dimmer';
import { Header, Pagination, Table } from 'semantic-ui-react';
import t from '../../services/Translate';
import PropTypes from 'prop-types';

class PresenceFactReportTable extends Component {

    handlePageChange = (event, { activePage }) => {
        if (activePage > 0) {
            this.props.onPageChanged(activePage);
        }
    };

    /**
     * @property presentCount
     * @property absentCount
     * @property sickCount
     * @property totalCount
     */
    renderRow = row => {
        return (
            <Table.Row key={`${row.groupId}-${row.presenceDate}`}>
                <Table.Cell>
                    {row.locationName}
                </Table.Cell>
                <Table.Cell>
                    {row.presenceDate}
                </Table.Cell>
                <Table.Cell>
                    {row.groupName}
                </Table.Cell>
                <Table.Cell>
                    {row.presentCount}
                </Table.Cell>
                <Table.Cell>
                    {row.absentCount}
                </Table.Cell>
                <Table.Cell>
                    {row.sickCount}
                </Table.Cell>
                <Table.Cell>
                    {row.totalCount}
                </Table.Cell>
            </Table.Row>
        );
    };

    renderTable = payload => {

        const rows = payload.rows;

        if (!rows || rows.length === 0) {
            return <Header as='h5' content='Наразі даних немає.'/>;
        }

        return (
            <>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('location')}</Table.HeaderCell>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            <Table.HeaderCell>{t('group')}</Table.HeaderCell>
                            <Table.HeaderCell>Присутні</Table.HeaderCell>
                            <Table.HeaderCell>Відсутні</Table.HeaderCell>
                            <Table.HeaderCell>Хворіють</Table.HeaderCell>
                            <Table.HeaderCell>Взагалі</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rows.map(this.renderRow)}
                    </Table.Body>
                </Table>
                <Pagination
                    onPageChange={this.handlePageChange}
                    activePage={payload.page}
                    totalPages={payload.pages}
                />
            </>

        );
    };

    render() {

        const { payload, isUpdating, isUpdated } = this.props;

        if (isUpdating || !isUpdated) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        return this.renderTable(payload);
    }
}

function mapStateToProps(state) {
    return state.presenceFactsReport;
}

PresenceFactReportTable.propTypes = {
    onPageChanged: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(PresenceFactReportTable);