import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';


export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';

/**
 * Fetch list of locations current 'LOCATION_ADMIN' can view
 */
export function fetchLocationsForAdmin() {

    return async function (dispatch) {

        dispatch(fetchLocationsRequest());

        return axios
            .get('/api/admin/locations', createGetParams())
            .then(res => dispatch(fetchLocationsSuccess(res)))
            .catch(error => dispatch(fetchLocationsFailure(error)));
    };

    function fetchLocationsRequest() {
        return { type: FETCH_LOCATIONS_REQUEST };
    }

    function fetchLocationsSuccess(res) {
        const locations = res.data;
        return { type: FETCH_LOCATIONS_SUCCESS, locations };
    }

    function fetchLocationsFailure(error) {

        const { errorCode } = getResponseData(error);

        return { type: FETCH_LOCATIONS_FAILURE, errorCode };
    }
}
