// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// semantic
import { Breadcrumb, Divider, Grid, Header, Tab } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';
// actions
import { fetchRegion } from '../../actions/region';
// services
// components
import LargeDimmer from '../helpers/Dimmer';
import Notification from '../helpers/Notification';
import LocationsTab from './LocationsTab';
import t from '../../services/Translate';
import PresenceFactReportView from '../presence-report/PresenceFactReportView';
import FoodFactReportView from '../presence-report/FoodFactReportView';

class RegionView extends Component {

    componentDidMount() {
        this.props.dispatch(fetchRegion(this.props.match.params.regionId));
    }

    render() {

        const { payload, isFetching, isFetched, errorMessage } = this.props;

        if (!isFetched) {
            return null;
        }

        const { id, name, description, foodReportAvailable } = payload;

        if (isFetching) {
            return (<LargeDimmer text='Завантаження даних'/>);
        }

        if (errorMessage) {
            return <Notification.Error content={errorMessage}/>;
        }

        const panes = [
            {
                menuItem: t('locations'), render: () => {
                    return (
                        <Tab.Pane>
                            <LocationsTab regionId={id}/>
                        </Tab.Pane>
                    );
                }
            }
        ];

        if (foodReportAvailable) {
            panes.push({
                menuItem: 'Звіт з відвідування', render: () => {
                    return (
                        <Tab.Pane>
                            <PresenceFactReportView regionId={id}/>
                        </Tab.Pane>
                    );
                }
            });
            panes.push({
                menuItem: 'Звіт з харчування', render: () => {
                    return (
                        <Tab.Pane>
                            <FoodFactReportView regionId={id}/>
                        </Tab.Pane>
                    );
                }
            });
        }

        return (
            <Grid centered>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                    <Breadcrumb>
                        <Breadcrumb.Section active>
                            <Header as='h5'>{name}, {description}</Header>
                        </Breadcrumb.Section>
                    </Breadcrumb>
                    <Divider/>
                    <Tab panes={panes}/>
                </Grid.Column>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return state.region;
}

RegionView.propTypes = {
    dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(RegionView);