import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { doLogin, prefetchLogin } from '../actions/login';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '../utils/settings';
import { Button, Divider, Form, Grid, Header, Label, List, Message, Segment } from 'semantic-ui-react';
import LargeDimmer from './helpers/Dimmer';
import _ from 'lodash';
import { isRecaptchaError, recaptchaError } from '../utils/validation';

const recaptchaRef = React.createRef();

class Login extends Component {

    state = {
        username: '',
        password: ''
    };

    componentDidMount() {
        this.props.dispatch(prefetchLogin());
    }

    componentDidUpdate(prevProps) {
        const { isLoginFailed } = this.props;

        if (isLoginFailed !== prevProps.isLoginFailed &&
            isRecaptchaError(this.props) && recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }

    handleLoginClick = (event) => {
        event.preventDefault();

        const { username, password, captchaResponse } = this.state;

        this.props.dispatch(doLogin(_.trim(username), password, captchaResponse, this.props.history));
    };

    onRecaptchaResolved = (captchaResponse) => {
        this.setState({ captchaResponse });
    };

    handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    render() {

        const { isLoginFailed, isFetching, isPreFetched, blocked } = this.props;

        if (isFetching) {
            return <LargeDimmer text='Авторизація'/>;
        }

        if (!isPreFetched) {
            return null;
        }

        return (
            <Grid centered>
                <Grid.Column computer={8} tablet={8} mobile={16}>

                    <Segment>
                        <Header as='h2'>Логін</Header>

                        {isLoginFailed && !isRecaptchaError(this.props) &&
                        <Message
                            error
                            header='Помилка'
                            content='Помилка входу'/>}

                        <Form>
                            <Form.Field>
                                <label>Електронна пошта</label>
                                <Form.Input
                                    name='username'
                                    value={this.state.username}
                                    error={isLoginFailed}
                                    onChange={this.handleChange}/>
                            </Form.Field>

                            <Form.Field>
                                <label>Пароль</label>
                                <Form.Input
                                    name='password'
                                    value={this.state.password}
                                    type='password'
                                    error={isLoginFailed}
                                    onChange={this.handleChange}/>
                            </Form.Field>

                            {blocked &&
                            <Form.Field>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={RECAPTCHA_KEY}
                                    onChange={this.onRecaptchaResolved}
                                    hl='uk'
                                />

                                {isRecaptchaError(this.props) &&
                                <Label color='red' pointing>
                                    {recaptchaError(this.props)}
                                </Label>}
                            </Form.Field>
                            }

                            <Button
                                name='login'
                                type='button'
                                primary
                                onClick={this.handleLoginClick}>Увійти</Button>

                        </Form>

                        <Divider/>

                        <List>
                            <List.Item>
                                <Link to='/register'>Я не маю облікового запису</Link>
                            </List.Item>
                            <List.Item>
                                <Link to='/send-password-reset-email'>Я не пам'ятаю свій пароль</Link>
                            </List.Item>
                            <List.Item>
                                <Link to='/resend-confirmation-email'>Я не отримав листа з підтвердженням реєстрації</Link>
                            </List.Item>
                        </List>

                    </Segment>
                </Grid.Column>
            </Grid>

        );
    }
}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.authentication;
}

export default connect(mapStateToProps)(Login);