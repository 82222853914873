import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logout from './Logout';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, Icon, Menu, Popup, Responsive } from 'semantic-ui-react';
import {
    isAuthenticated,
    isEditor,
    isGroupAdmin,
    isLocationAdmin,
    isRegionAdmin,
    isUser
} from '../services/AuthService';
import t from '../services/Translate';

class Header extends Component {

    renderLogin = () => {
        const { username } = this.props;
        return (
            <>
                {isAuthenticated() &&
                <Menu.Menu position='right'>
                    {username.indexOf('@') > 0 &&
                    <Menu.Item position='right'>
                        {username}
                    </Menu.Item>
                    }
                    <Logout/>
                </Menu.Menu>
                }

                {!isAuthenticated() &&
                <Menu.Menu position='right'>
                    <Menu.Item position='right' style={{display: 'none'}}>
                        <Popup
                            content='Увійти за допомогою облікового запису киянина'
                            trigger={<a href='/oauth/fdi'>Я маю KyivID</a>}/>
                    </Menu.Item>
                    <Menu.Item id='login' as={Link} to='/login' content='Увійти'/>
                </Menu.Menu>
                }
            </>
        );
    };

    renderLogo = () => {
        return (
            <Menu.Item exact as={NavLink} to='/' disabled>
                <img src='/logo-blue.svg' alt='logo' width='24' height='24'/>
            </Menu.Item>
        );
    };

    render() {

        const { regionName, childrenCount, enrollmentsCount } = this.props;

        return (
            <>
                <Responsive as={Menu} pointing attached='top' minWidth={Responsive.onlyMobile.maxWidth}>
                    {this.renderLogo()}

                    {isUser() &&
                    <Menu.Item as={NavLink} to='/children'>
                        Діти
                        {regionName && childrenCount === 0 &&
                        <Icon name='exclamation circle' color='red'/>}
                    </Menu.Item>
                    }

                    {isUser() &&
                    <Menu.Item as={NavLink} to='/enrollments'>
                        Заявки
                    </Menu.Item>
                    }

                    {isUser() &&
                    <Menu.Item as={NavLink} to='/locations'>
                        {t('locations')}
                        {regionName && childrenCount !== 0 && enrollmentsCount === 0 &&
                        <Icon name='exclamation circle' color='red'/>}
                    </Menu.Item>
                    }

                    {(isLocationAdmin() || isEditor() || isGroupAdmin()) &&
                    <Menu.Item as={NavLink} to='/admin/locations' content={t('locations')}/>}

                    {isRegionAdmin() &&
                    <Menu.Item as={NavLink} to='/regions' content='Регіони'/>}

                    {(isRegionAdmin() || isLocationAdmin()) &&
                    <Menu.Item as={NavLink} to='/enrollments-for-attention' content='Заявки'/>}

                    {isAuthenticated() &&
                    <Menu.Item as={NavLink} to='/user-info'>
                        Користувач
                        {isUser() && !regionName &&
                        <Icon name='exclamation circle' color='red'/>}
                    </Menu.Item>
                    }

                    {this.renderLogin()}
                </Responsive>

                <Responsive as={Menu} pointing attached='top' {...Responsive.onlyMobile}>

                    {!isAuthenticated() && this.renderLogo()}

                    {isAuthenticated() &&
                    <Dropdown item icon='bars' simple>
                        <Dropdown.Menu>
                            {isUser() &&
                            <Dropdown.Item as={NavLink} to='/children' content='Діти'/>}

                            {isUser() &&
                            <Dropdown.Item as={NavLink} to='/enrollments' content='Заявки'/>}

                            {isUser() &&
                            <Dropdown.Item as={NavLink} to='/locations' content={t('locations')}/>}

                            {(isLocationAdmin() || isEditor()) &&
                            <Dropdown.Item as={NavLink} to='/admin/locations' content={t('locations')}/>}

                            {isRegionAdmin() &&
                            <Dropdown.Item as={NavLink} to='/regions' content='Регіони'/>}

                            {(isRegionAdmin() || isLocationAdmin()) &&
                            <Dropdown.Item as={NavLink} to='/enrollments-for-attention' content='Заявки'/>}

                            {isAuthenticated() &&
                            <Dropdown.Item as={NavLink} to='/user-info' content='Користувач'/>}
                        </Dropdown.Menu>
                    </Dropdown>}

                    {this.renderLogin()}
                </Responsive>
            </>
        );
    }
}


function mapStateToProps(state) {
    return { ...state.authentication, ...state.accountDetails };
}

export default connect(mapStateToProps)(Header);