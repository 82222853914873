import { FAILURE, REQUEST, SUCCESS } from '../../shared/action-type';
import { Action, State } from '../../shared/store.model';
import { Enrollment } from '../../shared/enrollment.model';
import { FETCH_USER_ENROLLMENTS } from './enrollments-user-action';

const initialState: State<Enrollment> = {
    completed: false,
    loading: false,
    entities: [],
};

const userEnrollments = (state: State<Enrollment> = initialState,
                         action: Action<Enrollment>): State<Enrollment> => {
    switch (action.type) {
        case REQUEST(FETCH_USER_ENROLLMENTS):
            return {
                ...state,
                completed: false,
                loading: true,
                errorCode: null,
            };
        case SUCCESS(FETCH_USER_ENROLLMENTS):
            return {
                ...state,
                loading: false,
                completed: true,
                entities: action.entities,
                errorCode: null,
            };
        case FAILURE(FETCH_USER_ENROLLMENTS):
            return {
                ...state,
                loading: false,
                completed: false,
                errorCode: action.errorCode,
            };
        default:
            return state;
    }
};

export default userEnrollments;