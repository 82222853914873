import React from 'react';
import { isRegionAdmin } from '../../services/AuthService';
import { Breadcrumb, Divider, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function LocationAdminBreadcrumb({ location }) {
    return (
        <>
            <Breadcrumb>
                {isRegionAdmin() &&
                <>
                    <Breadcrumb.Section>
                        <Header as='h5'>
                            <Link to={`/regions/${location.regionId}`}>
                                {location.regionName}
                            </Link>
                        </Header>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right chevron'/>
                </>
                }
                <Breadcrumb.Section active>
                    <Header as='h5'>
                        {location.name}
                    </Header>
                </Breadcrumb.Section>
            </Breadcrumb>
            <Divider/>
        </>
    );
}