import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_KEY } from '../utils/settings';
import { Label } from 'semantic-ui-react';
import { isRecaptchaError, recaptchaError } from '../utils/validation';
import PropTypes from 'prop-types';

class RecaptchaWrapper extends Component {

    render() {
        const props = this.props;
        return (
            <>
                <ReCAPTCHA
                    sitekey={RECAPTCHA_KEY}
                    onChange={props.onRecaptchaResolved}
                    hl='uk'
                />

                {isRecaptchaError(props) &&
                <Label
                    color='red'
                    pointing
                    content={recaptchaError(props)}
                />
                }
            </>
        );
    }
}

RecaptchaWrapper.propTypes = {
    onRecaptchaResolved: PropTypes.func.isRequired
};

export default RecaptchaWrapper;