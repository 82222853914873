import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setStep } from '../../actions/tour';
import { Button, Container, Header, Message, Modal, Segment } from 'semantic-ui-react';
import { isAuthenticated, isUser } from '../../services/AuthService';
import Toast from '../helpers/Toasts';
import { Link } from 'react-router-dom';

const messageStyle = { marginTop: 5, backgroundColor: '#03a84e', color: '#ffffff' };
const messageHeaderStyle = { color: '#ffffff' };

class TourController extends Component {

    componentDidUpdate(prevProps) {

        if (!isAuthenticated() || !isUser()) {
            return;
        }

        const { regionName, childrenCount, enrollmentsCount } = this.props;

        const { isFetched } = prevProps;

        if (isFetched && regionName && !prevProps.regionName) {
            Toast('info', 'Чудово! 😀 Наступним кроком ви можете додати дитину.');
        }

        if (isFetched && childrenCount !== 0 && prevProps.childrenCount === 0) {
            Toast('info', 'Відмінно 👍, теперь можна подавати заявку! ');
        }

        if (isFetched && enrollmentsCount !== 0 && prevProps.enrollmentsCount === 0) {
            Toast('info', 'Супер, ви молодець, заявка подана! 😍');
        }
    }

    render() {

        if (!isAuthenticated() || !isUser()) {
            return null;
        }

        const { step, regionName, childrenCount, enrollmentsCount } = this.props;

        if (step === 0) {
            return (
                <Modal open>
                    <Modal.Header>
                        <Header as='h2'>Ласкаво просимо! <span role='img' aria-label='smile'>🤗</span></Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Segment>
                            <Header as='h2'>
                                Дякуємо за реєстрацію в системі електронних черг!
                                Для подачi заявки у навчальний заклад потрібно виконати наступні дії:
                                <ol>
                                    <li>Заповнити інформацію про користувача</li>
                                    <li>Додати інформацію про одного або декількох дітей</li>
                                    <li>Вибрати заклад та подати заявку</li>
                                </ol>
                            </Header>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Segment textAlign='center' basic>
                            <Button
                                size='huge'
                                positive
                                icon='thumbs up outline'
                                labelPosition='right'
                                content='Зрозуміло!'
                                onClick={() => this.props.dispatch(setStep(1))}
                            />
                        </Segment>
                    </Modal.Actions>
                </Modal>
            );
        }

        if (!regionName) {
            return (
                <Container>
                    <Message positive style={messageStyle} size='huge'>
                        <Message.Header content='Крок 1: інформація про користувача' style={messageHeaderStyle}/>
                        <p>Будь ласка заповніть інформацію про користувача</p>
                    </Message>
                </Container>
            );
        }

        if (childrenCount === 0) {
            return (
                <Container>
                    <Message positive style={messageStyle} size='huge'>
                        <Message.Header content='Крок 2: додати дитину' style={messageHeaderStyle}/>
                        <p>Перед поданням заявки спершу треба додати дитину</p>
                        <p><Button as={Link} to='/children'>Перейти до довавання дитини</Button></p>
                    </Message>
                </Container>
            );
        }

        if (enrollmentsCount === 0) {
            return (
                <Container>
                    <Message positive style={messageStyle} size='huge'>
                        <Message.Header content='Крок 3: подати заявку' style={messageHeaderStyle}/>
                        <p>Майже все, залишилося лише вибрати заклад та подати заявку!</p>
                        <p><Button as={Link} to='/locations'>Обрати навчальний заклад</Button></p>
                    </Message>
                </Container>
            );
        }

        return null;
    }
}

TourController.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return { ...state.accountDetails, ...state.tour };
}

export default connect(mapStateToProps)(TourController);