import { createUpdateEntityAction } from '../generic/entityAction';
import { createDownloadAction } from '../generic/downloadAction';
import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';

export const PRESENCE_FACTS_REPORT = 'PRESENCE_FACTS_REPORT';
export const FACTS_REPORT_FILTER = 'FACTS_REPORT_FILTER';
export const PRESENCE_FACTS_REPORT_XLS = 'PRESENCE_FACTS_REPORT_XLS';

export default {
    fetchAll: createUpdateEntityAction(() => '/api/presence-facts/report', PRESENCE_FACTS_REPORT),
    fetchFilters: createUpdateEntityAction(() => '/api/presence-facts/report/filter', FACTS_REPORT_FILTER),
    download: createDownloadAction(args => {
        return axios.put('/api/presence-facts/report/xls', args, { ...REQUEST_SETTINGS, responseType: 'blob' });
    }, PRESENCE_FACTS_REPORT_XLS, 'presence_report.xls')
};