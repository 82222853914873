import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import locationAdminActions from '../../../actions/location/locationAdmin';
import t from '../../../services/Translate';
import DeleteDialogBase from '../../common/DeleteDialogBase';

class RemoveLocationAdminDialog extends DeleteDialogBase {

    getCompletedMessage = () => t('locationAdminRemoved')(this.props);

    onSubmit = () => {
        const { targetId, username } = this.props;
        this.props.dispatch(locationAdminActions.delete({ locationId: targetId, username }));
    };

    resetDialog = () =>
        this.props.dispatch(locationAdminActions.reset());

    getContent = () => {
        return t('locationAdminRemoveConfirm')(this.props);
    };

}

RemoveLocationAdminDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
    targetId: PropTypes.number.isRequired,
    targetName: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return state.locationAdmin;
}

export default connect(mapStateToProps)(RemoveLocationAdminDialog);