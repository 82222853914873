import {
    FETCH_RELATED_ENROLLMENTS_FAILURE,
    FETCH_RELATED_ENROLLMENTS_REQUEST,
    FETCH_RELATED_ENROLLMENTS_SUCCESS,
} from '../../actions/enrollment/fetchRelatedEnrollments';

const initialState = {
    isFetching: false,
    isFetchFailed: false,
    isFetched: false,
    enrollments: [],
    errorCode: null,
};

export default function fetchRelatedEnrollments(state = initialState, action) {

    switch (action.type) {
    case FETCH_RELATED_ENROLLMENTS_REQUEST:
        return {
            ...state,
            isFetching: true,
            isFetchFailed: false,
            isFetched: false,
            enrollments: [],
            errorCode: null,
        };
    case FETCH_RELATED_ENROLLMENTS_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: false,
            isFetched: true,
            enrollments: action.enrollments,
            errorCode: null,
        };
    case FETCH_RELATED_ENROLLMENTS_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: true,
            isFetched: false,
            enrollments: [],
            errorCode: action.errorCode,
        };
    default:
        return state;
    }
}