import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import AddLocationAdminDialog from './dialog/AddLocationAdminDialog';
import AddAdminFormBase from '../common/AddAdminFormBase';

class AddLocationAdminForm extends AddAdminFormBase {

    renderDialog = () => {
        return (
            <AddLocationAdminDialog
                username={this.state.email}
                targetId={this.props.targetId}
                targetName={this.props.targetName}
                isDialogOpen={this.state.isAddAdminDialogOpen}
                onClose={this.onCompleted}
            />
        );
    }
}

AddLocationAdminForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onAdminAdded: PropTypes.func.isRequired,
    targetId: PropTypes.number.isRequired,
    targetName: PropTypes.string.isRequired
};

function mapStateToProps(store) {
    return store.locationAdmin;
}

export default connect(mapStateToProps)(AddLocationAdminForm);
