import {
    COMPLETE_LIST_LOCATIONS_DETAILS,
    FAIL_LIST_LOCATIONS_DETAILS,
    START_LIST_LOCATIONS_DETAILS,
} from '../../actions/location/listLocationsDetails';

const initialState = {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    locations: [],
};

export default function listLocationsDetails(state = initialState, action) {

    switch (action.type) {
    case START_LIST_LOCATIONS_DETAILS:
        return {
            ...state,
            isFetching: true,
            isFetched: false,
            isFailed: false,
        };
    case COMPLETE_LIST_LOCATIONS_DETAILS:
        return {
            ...action,
            isFetching: false,
            isFetched: true,
            isFailed: false,
            locations: action.locations,
        };
    case FAIL_LIST_LOCATIONS_DETAILS:
        return {
            ...action,
            isFetching: false,
            isFetched: false,
            isFailed: true,
            locations: [],
        };
    default:
        return state;
    }
}