// react
import connect from 'react-redux/es/connect/connect';

// libs
import PropTypes from 'prop-types';

// actions
import groupAdminActions from '../../../actions/group/groupAdmin';

// components
import AdminListSectionBase from '../../common/AdminListSectionBase';
import { Header, Segment } from 'semantic-ui-react';
import t from '../../../services/Translate';
import React from 'react';
import AddGroupAdminForm from './AddGroupAdminForm';
import RemoveGroupAdminDialog from '../dialog/RemoveGroupAdminDialog';

class GroupAdminListSection extends AdminListSectionBase {

    constructor(props) {
        super(props);

        this.fetchAdmins = () => {
            this.props.dispatch(groupAdminActions.fetchAll(this.props.targetId));
        };
    }

    render() {

        const { targetId, targetName } = this.props;

        return (
            <Segment>
                <RemoveGroupAdminDialog
                    email={this.state.email}
                    targetId={targetId}
                    targetName={targetName}
                    isDialogOpen={this.state.isRemoveAdminDialogOpen}
                    onClose={this.onRefresh}
                />

                <Header as='h5' content={t('groupAdmins')}/>

                <AddGroupAdminForm
                    targetId={targetId}
                    targetName={targetName}
                    onAdminAdded={this.onRefresh}
                />

                {this.renderAdminsTable()}

            </Segment>
        );
    }
}

GroupAdminListSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    targetId: PropTypes.number.isRequired,
    targetName: PropTypes.string.isRequired,
};

function mapStateToProps(store) {
    return store.groupAdminList;
}

export default connect(mapStateToProps)(GroupAdminListSection);
