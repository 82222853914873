import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchEnrollmentForAttention } from '../../actions/enrollment/fetchEnrollmentsForAttention';
import { Header, Pagination } from 'semantic-ui-react';
import EnrollmentsTableSection from './EnrollmentsTableSection';
import _ from 'lodash';
import { ENROLLMENTS_ON_PAGE } from '../../const/variables';
import LargeDimmer from '../helpers/Dimmer';

class EnrollmentsForAttentionView extends Component {

    state = { page: 1 };

    componentDidMount() {
        this.fetchEnrollments();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.page, this.state.page)) {
            this.fetchEnrollments();
        }
    }

    fetchEnrollments = () => {
        this.props.dispatch(fetchEnrollmentForAttention(this.state.page));
    };

    handlePageChange = (event, { activePage }) => {
        if (activePage > 0) { // cannot be less than 1
            this.setState({ page: activePage });
        }
    };

    /**
     * @property enrollmentsCount
     */
    render() {

        const { isFetching } = this.props;

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        const { payload } = this.props;
        const enrollments = payload ? payload.enrollments : [];
        const enrollmentsCount = payload ? payload.enrollmentsCount : 0;

        const pagesTotalCount = Math.ceil(enrollmentsCount / ENROLLMENTS_ON_PAGE);

        return (
            <>
                <Header as='h2' content='Заявки, що чекають на ваш розгляд'/>

                {enrollments.length === 0 &&
                <Header as='h3' content='Таких заявок немає'/>
                }

                {enrollments.length > 0 &&
                <>
                    <EnrollmentsTableSection
                        enrollments={enrollments}
                        handleEnrollmentClick={this.handleEnrollmentClick}
                        onReload={this.fetchEnrollments}
                        isAttention={true}
                    />
                    <Pagination
                        onPageChange={this.handlePageChange}
                        activePage={this.state.page}
                        totalPages={pagesTotalCount}
                    />
                </>
                }
            </>
        );
    }
}

EnrollmentsForAttentionView.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.fetchEnrollmentsForAttention;
}

export default connect(mapStateToProps)(EnrollmentsForAttentionView);