import React, { Component } from 'react';
import { Dropdown, Grid, Icon, Popup, Responsive, Segment, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { fetchGroupsByLocationId } from '../../actions/group/fetchGroupsByLocationId';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import LargeDimmer from '../helpers/Dimmer';
import Stats from '../Stats';
import Notification from '../helpers/Notification';
import lo from 'lodash';
import EnrollmentSearch from '../enrollment/EnrollmentSearch';

// noinspection JSCheckFunctionSignatures
class GroupListSection extends Component {

    state = {
        isGroupsPresent: false,
        groups: [],
        presentedPeriods: [],
        selectedPeriod: null,
        selectedGroup: {},
        currentYear: (new Date()).getFullYear(),
    };

    componentDidUpdate(prevProps, prevState) {
        if (!lo.isEqual(prevState.selectedPeriod, this.state.selectedPeriod)) {
            this.fetchGroups();
        }
    }

    componentDidMount() {
        this.fetchGroups();
    }

    fetchGroups() {
        const { locationId } = this.props;
        this.props.dispatch(fetchGroupsByLocationId(locationId));
    }

    static getDerivedStateFromProps(props, state) {

        const { isFetched, groups } = props;

        if ((groups && isFetched) || !state.isGroupsPresent) {

            const yearsFromGroups = Array.from(new Set(groups.map(group => group.period))).sort();

            let { presentedPeriods, selectedPeriod, currentYear } = state;

            presentedPeriods = [];

            if (!selectedPeriod) {
                if (yearsFromGroups.includes(currentYear)) {
                    selectedPeriod = currentYear;
                } else {
                    selectedPeriod = yearsFromGroups[yearsFromGroups.length - 1];
                }
            }

            yearsFromGroups.forEach((year) => {
                presentedPeriods.push({ text: `${year} — ${year + 1}`, value: year, key: year });
            });

            return ({
                groups,
                presentedPeriods,
                selectedPeriod,
                isGroupsPresent: true,
            });
        }

        return null;
    }

    handleChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    /**
     * @property needsUpdating
     */

    render() {

        const { isFetchFailed, isFetching, locationId } = this.props;

        if (isFetchFailed) {
            return (<Segment><Notification.Error/></Segment>);
        }

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        const { presentedPeriods, selectedPeriod } = this.state;

        if (!presentedPeriods.map(period => period.key).includes(selectedPeriod)) {
            return null;
        }

        return (
            <>
                <Grid columns={2}>
                    <Grid.Column>
                        <Dropdown
                            name='selectedPeriod'
                            value={selectedPeriod}
                            placeholder='Рік'
                            selection
                            options={presentedPeriods}
                            onChange={this.handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <EnrollmentSearch locationId={locationId}/>
                    </Grid.Column>
                </Grid>

                <Table sortable celled selectable striped structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Назва</Table.HeaderCell>
                            <Table.HeaderCell>Період</Table.HeaderCell>
                            <Responsive as={Table.HeaderCell} {...Responsive.onlyComputer}>В черзі</Responsive>
                            <Responsive as={Table.HeaderCell} {...Responsive.onlyComputer}>Підтверджено</Responsive>
                            <Table.HeaderCell>Операції</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.groups.map(group => {
                            if (group.period === selectedPeriod) {
                                const stats = group.stats;
                                return (
                                    <Table.Row key={group.id} warning={group.needsUpdating}>
                                        <Table.Cell>
                                            <Link to={`/admin/locations/${this.props.locationId}/groups/${group.id}`}>
                                                {group.name}
                                            </Link>
                                            &nbsp;
                                            {group.needsUpdating &&
                                            <Popup
                                                trigger={<Icon name='exclamation circle' color='orange'/>}
                                                content='Є зміни'/>
                                            }
                                        </Table.Cell>
                                        <Table.Cell>{group.period} — {group.period + 1}</Table.Cell>
                                        <Responsive as={Table.Cell} {...Responsive.onlyComputer}>
                                            <Stats
                                                regularCount={stats.activeRegularEnrollmentsCount}
                                                regularText='Всього дітей в черзі, які очікують зарахування'
                                                privilegedCount={stats.activePrivilegedEnrollmentsCount}
                                                privilegedText='Всього дітей в черзі, які очікують зарахування та мають пільгу'
                                            />
                                        </Responsive>
                                        <Responsive as={Table.Cell} {...Responsive.onlyComputer}>
                                            <Stats
                                                regularCount={stats.confirmedRegularEnrollmentsCount}
                                                regularText='Всього дітей прийнято'
                                                privilegedCount={stats.confirmedPrivilegedEnrollmentsCount}
                                                privilegedText='Всього дітей прийнято за пільгою'
                                            />
                                        </Responsive>
                                        <Table.Cell>
                                            <Link to={`/admin/locations/${this.props.locationId}/groups/${group.id}`}>
                                                <Icon name='edit'/> Редагувати
                                            </Link>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            }
                            return null;
                        })}
                    </Table.Body>
                </Table>
            </>
        );
    }
}

GroupListSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
};

function mapStateToProps(store) {
    return store.fetchGroupsByLocationId;
}

export default connect(mapStateToProps)(GroupListSection);