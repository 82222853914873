import {
    FETCH_GROUPS_BY_LOCATION_ID_REQUEST,
    FETCH_GROUPS_BY_LOCATION_ID_SUCCESS,
    FETCH_GROUPS_BY_LOCATION_ID_FAILURE,
} from '../../actions/group/fetchGroupsByLocationId';

const initialState = {
    isFetching: false,
    isFetchFailed: false,
    isFetched: false,
    groups: [],
    fetchGroupsErrorCode: null,
};
export default function fetchGroupsByLocationId(state = initialState, action) {

    switch (action.type) {
    case FETCH_GROUPS_BY_LOCATION_ID_REQUEST:
        return {
            ...state,
            isFetching: true,
            isFetchFailed: false,
            isFetched: false,
            groups: [],
            fetchGroupsErrorCode: null,
        };
    case FETCH_GROUPS_BY_LOCATION_ID_SUCCESS:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: false,
            isFetched: true,
            groups: action.groups,
            fetchGroupsErrorCode: null,
        };
    case FETCH_GROUPS_BY_LOCATION_ID_FAILURE:
        return {
            ...state,
            isFetching: false,
            isFetchFailed: true,
            isFetched: false,
            groups: [],
            fetchGroupsErrorCode: action.errorCode,
        };
    default:
        return state;
    }
}