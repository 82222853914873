import {
    START_RESET_PASSWORD,
    COMPLETE_RESET_PASSWORD,
    FAIL_RESET_PASSWORD
} from '../actions/resetPassword';

export default function resetPassword(state = {
    isPasswordSent: false,
    isFetching: false,
    isSentPasswordFailed: false,
    errorCode: null
}, action) {

    switch (action.type) {
    case START_RESET_PASSWORD:
        return {
            ...state,
            isPasswordSent: false,
            isFetching: true,
            isSentPasswordFailed: false,
            errorCode: null
        };
    case COMPLETE_RESET_PASSWORD:
        return {
            ...state,
            isPasswordSent: true,
            isFetching: false,
            isSentPasswordFailed: false,
            errorCode: null
        };
    case FAIL_RESET_PASSWORD:
        return {
            ...state,
            isPasswordSent: false,
            isFetching: false,
            isSentPasswordFailed: true,
            errorCode: action.errorCode
        };
    default:
        return state;
    }
}