import { COMPLETE_REGISTER, FAIL_REGISTER, REGISTER_RESET, START_REGISTER, } from '../actions/register';

const initialState = {
    isRegistered: false,
    isFetching: false,
    isRegistrationFailed: false,
};

export default function registration(state = initialState, action) {

    switch (action.type) {
    case REGISTER_RESET:
        return initialState;
    case START_REGISTER:
        return {
            ...state,
            isRegistered: false,
            isFetching: true,
            isRegistrationFailed: false,
            errorCode: null,
        };
    case COMPLETE_REGISTER:
        return {
            ...state,
            isRegistered: true,
            isFetching: false,
            isRegistrationFailed: false,
            errorCode: null,
        };
    case FAIL_REGISTER:
        return {
            ...state,
            isRegistered: false,
            isFetching: false,
            isRegistrationFailed: true,
            errorCode: action.errorCode,
        };
    default:
        return state;
    }
}