// react
import React, { Component } from 'react';
// libs
import Toast from '../helpers/Toasts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Checkbox, Form, Header, Icon, Label, Popup, Segment } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
// actions
import { doEditEnrollment, editEnrollmentReset } from '../../actions/enrollment/enrollmentEdit';
// components
import ChildFormComponent from '../child/ChildFormComponent';
import LargeDimmer from '../helpers/Dimmer';
import { fetchEnrollment, fetchEnrollmentReset } from '../../actions/enrollment/fetchEnrollment';
import { Link } from 'react-router-dom';
import { isAllowableToChangeGroup } from '../../utils/enrollmentUtils';
import {
    enrollmentUpdateError,
    getConflictingEnrollmentDetailsFromResponse,
    getFieldError,
    isEnrollmentUpdateError,
    isFieldError
} from '../../utils/validation';
import GroupDropdownSection from '../group/GroupDropdownSection';
import Notification from '../helpers/Notification';
import { DATE_PICKER_FORMAT } from '../../utils/settings';
import { getParentEmailField } from '.';
import { DEFAULT_CHILD_STATE } from '../child';

class EnrollmentUpdate extends Component {

    state = {
        id: null,
        created: null,
        groupId: null,
        prioritized: null,
        ...DEFAULT_CHILD_STATE,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(fetchEnrollmentReset());
        dispatch(editEnrollmentReset());
    }

    componentDidUpdate(prevProps) {

        const { isUpdated, isFetched, payload } = this.props;

        if ((isFetched && !prevProps.isFetched)) {
            this.setState({ ...payload });
        }

        if (isUpdated && !prevProps.isUpdated) {
            this.setState({ ...payload });
            Toast('success', 'Дані успішно оновлені');
        }
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch(fetchEnrollment(match.params.enrollmentId));
    }

    onCreatedDateChange = created => {
        this.setState({ created });
    };

    onDateChange = birthDate => {
        this.setState({ birthDate });
    };

    onChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    onUpdate = () => {
        this.props.dispatch(doEditEnrollment({ ...this.state }));
    };

    togglePrioritized = () => {
        this.setState({ prioritized: !this.state.prioritized });
    };

    /**
     * @property priorityEnabled
     */
    render() {
        const props = this.props;
        const { isUpdating, isFetching, match } = props;

        if (isFetching) {
            return (<LargeDimmer text='Завантаження даних'/>);
        }

        if (isUpdating) {
            return (<LargeDimmer text='Збереження даних'/>);
        }

        return (
            <>
                {isEnrollmentUpdateError(props) &&
                <Notification.Error content={
                    <>
                        <div>{enrollmentUpdateError(props)}</div>
                        <div>{getConflictingEnrollmentDetailsFromResponse(props)}</div>
                    </>
                }/>
                }
                <Segment>
                    <ChildFormComponent
                        submitButtonText='Зберегти'
                        onSubmit={this.onUpdate}
                        onDateChange={this.onDateChange}
                        onChange={this.onChange}
                        parentEmail={getParentEmailField(this)}
                        value={{ ...this.state }}
                        {...props}>
                        <Button
                            as={Link}
                            to={`/admin/locations/${match.params.locationId}/enrollments/${this.state.id}`}
                            floated='right'
                            size='tiny'
                            icon="undo"
                            labelPosition='right'
                            content='Назад до заявки'
                        />

                        <Header floated='left' as='h2'>Редагувати дані заявки</Header>

                        <Form.Field>
                            <label>Дата створення заявки</label>
                            <DatePicker
                                dateFormat={DATE_PICKER_FORMAT}
                                selected={this.state.created}
                                onChange={this.onCreatedDateChange}
                                locale={uk}/>

                            {isFieldError(props, 'created') &&
                            <p>
                                <Label color='red' pointing>
                                    {getFieldError(props, 'created')}
                                </Label>
                            </p>
                            }
                        </Form.Field>

                        {this.state.priorityEnabled &&
                        <Form.Field>
                            <Checkbox
                                label='Заявка має пріоритет'
                                checked={this.state.prioritized}
                                onChange={this.togglePrioritized}/>
                            &nbsp;
                            <Popup content='Дитина належить до громади, територіальна приналежність або інше' trigger={
                                <Icon name='question circle' color='blue'/>}/>
                        </Form.Field>
                        }

                        <GroupDropdownSection
                            onChange={this.onChange}
                            locationId={match.params.locationId}
                            groupId={this.state.groupId}
                            disabled={!isAllowableToChangeGroup(this.state)}/>

                    </ChildFormComponent>
                </Segment>
            </>
        );

    }
}

EnrollmentUpdate.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        ...state.fetchEnrollment,
        ...state.enrollmentEdit
    };
}

export default connect(mapStateToProps)(EnrollmentUpdate);