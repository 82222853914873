import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Header, Table } from 'semantic-ui-react';
import { doListLocations } from '../../actions/location/listLocations';
import { Link } from 'react-router-dom';
import t from '../../services/Translate';
import LargeDimmer from '../helpers/Dimmer';
import _ from 'lodash';

class LocationListUser extends Component {

    componentDidMount() {
        this.props.dispatch(doListLocations());
    }

    render() {

        const { isFetching, locations } = this.props;

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (_.isEmpty(locations)) {
            return (
                <>
                    <Header as='h3'>{t('locations')} не знайдено</Header>
                    <Header as='h4'>Перевірте що ви вказали регіон в інформації про користувача</Header>
                </>
            );
        }

        return (
            <>
                <Header as='h2'>{t('locations')}</Header>

                <Table sortable celled selectable striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Назва</Table.HeaderCell>
                            <Table.HeaderCell>Опис</Table.HeaderCell>
                            <Table.HeaderCell>Операції</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.props.locations.map(({ id, name, description }) => (
                            <Table.Row key={id}>
                                <Table.Cell>{name}</Table.Cell>
                                <Table.Cell>{description}</Table.Cell>
                                <Table.Cell>
                                    <Button as={Link} to={`/locations/${id}`}>Вибрати</Button>
                                </Table.Cell>
                            </Table.Row>
                        ))
                        }
                    </Table.Body>
                </Table>
            </>
        );
    }
}


LocationListUser.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(store) {
    return store.listLocations;
}

export default connect(mapStateToProps)(LocationListUser);