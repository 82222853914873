import axios from 'axios';
import { createGetParams } from './utils';

export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS';
export const COMPLETE_LOAD_ACCOUNT_DETAILS = 'COMPLETE_LOAD_ACCOUNT_DETAILS';
export const CLEAR_ACCOUNT_DETAILS = 'CLEAR_ACCOUNT_DETAILS';

export function setAccountDetails(accountDetails) {
    return { type: SET_ACCOUNT_DETAILS, accountDetails };
}

export function clearAccountDetails() {
    return { type: CLEAR_ACCOUNT_DETAILS };
}

function completeLoadAccountDetails(res) {
    const accountDetails = res.data;

    accountDetails.authenticated = true;

    return { type: COMPLETE_LOAD_ACCOUNT_DETAILS, accountDetails };
}

export function loadAccountDetails() {

    return async function (dispatch) {
        return axios
            .get('/api/account/authorized', createGetParams())
            .then(res => dispatch(completeLoadAccountDetails(res)));
    };
}