import { UPDATE_GROUP_FAILURE, UPDATE_GROUP_REQUEST, UPDATE_GROUP_SUCCESS } from '../../actions/group/updateGroup';
import { GROUP_RESET } from '../../actions/group/group';

const initialState = {
    isUpdating: false,
    isUpdateFailed: false,
    isUpdated: false,
    errorCode: null,
    fieldErrors: null,
};

export default function updateGroup(state = initialState, action) {

    switch (action.type) {
    case UPDATE_GROUP_REQUEST:
        return {
            ...state,
            isUpdating: true,
            isUpdateFailed: false,
            isUpdated: false,
            errorCode: null,
            fieldErrors: null,
        };
    case UPDATE_GROUP_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            isUpdateFailed: false,
            isUpdated: true,
            errorCode: null,
            fieldErrors: null,
        };
    case UPDATE_GROUP_FAILURE:
        return {
            ...state,
            isUpdating: false,
            isUpdateFailed: true,
            isUpdated: false,
            errorCode: action.errorCode,
            fieldErrors: action.fieldErrors,
        };
    case GROUP_RESET:
        return initialState;
    default:
        return { ...state };
    }
}