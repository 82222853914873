import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import Confirm from './Confirm';
import Welcome from './Welcome';
import SendPasswordResetEmail from './email/SendPasswordResetEmail';
import ResetPassword from './ResetPassword';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import UserInfo from './UserInfo';
import Children from './child/Children';
import Header from './Header';
import LocationListUser from './location/LocationListUser';
import RegionsAdminView from './region/RegionsAdminView';
import LocationViewUser from './location/LocationViewUser';
import RegionView from './region/RegionView';
import LocationListAdmin from './location/LocationListAdmin';
import LocationViewAdmin from './location/LocationViewAdmin';
import GroupDetailsAdminView from './group/GroupDetailsAdminView';
import RegistrationSuccess from './RegistrationSuccess';
import EnrollmentDetailsViewAdmin from './enrollment/EnrollmentDetailsViewAdmin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SERVICE_TYPE } from '../utils/settings';
import EnrollmentUpdate from './enrollment/EnrollmentUpdate';
import ChildCreate from './child/ChildCreate';
import ChildUpdate from './child/ChildUpdate';
import EnrollmentCreate from './enrollment/EnrollmentCreate';
import EnrollmentsForAttentionView from './enrollment/EnrollmentsForAttentionView';
import AuthController from './AuthController';
import TourController from './tour/TourController';
import ResendConfirmationEmail from './email/ResendConfirmationEmail';
import UserEnrollments from './enrollments-user/enrollments-user';

const backgrounds = {
    school: 'url(img/bg-school.svg)',
    kindergarten: 'url(img/bg-kindergarten.svg)',
    gurtok: 'url(img/bg-gurtok.svg)'
};

const background = backgrounds[SERVICE_TYPE];

const style = {
    backgroundImage: background,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    backgroundAttachment: 'fixed',
    minHeight: '100%',
    marginTop: 0,
    paddingTop: '14px'
};

class App extends Component {

    render() {
        return (
            <HashRouter>
                <>
                    <AuthController/>
                    <Header/>
                    <TourController/>
                    <Container style={style} fluid>
                        <Switch>
                            <Route exact path='/'>
                                <Welcome/>
                            </Route>
                        </Switch>
                        <Container>
                            <Switch>
                                <Route exact path='/login' component={Login}/>
                                <Route exact path='/register' component={Register}/>
                                <Route exact path='/user-info' component={UserInfo}/>
                                <Route exact path='/children' component={Children}/>
                                <Route exact path='/confirm' component={Confirm}/>
                                <Route exact path='/register-success' component={RegistrationSuccess}/>
                                <Route exact path='/children/create' component={ChildCreate}/>
                                <Route exact path='/children/:childUuid' component={ChildUpdate}/>
                                <Route exact path='/send-password-reset-email' component={SendPasswordResetEmail}/>
                                <Route exact path='/resend-confirmation-email' component={ResendConfirmationEmail}/>
                                <Route exact path='/reset-password' component={ResetPassword}/>
                                <Route exact path='/regions' component={RegionsAdminView}/>
                                <Route exact path='/regions/:regionId' component={RegionView}/>
                                <Route exact path='/locations' component={LocationListUser}/>
                                <Route exact path='/locations/:id' component={LocationViewUser}/>
                                <Route exact path='/admin/locations' component={LocationListAdmin}/>
                                <Route
                                    exact path='/admin/locations/:locationId'
                                    component={LocationViewAdmin}/>
                                <Route
                                    exact
                                    path='/admin/locations/:locationId/groups/:groupId'
                                    component={GroupDetailsAdminView}/>

                                <Route
                                    exact
                                    path='/admin/locations/:locationId/enrollments/:enrollmentId/edit'
                                    component={EnrollmentUpdate}/>

                                <Route
                                    exact
                                    path='/admin/locations/:locationId/groups/:groupId/enrollments/create'
                                    component={EnrollmentCreate}/>

                                <Route
                                    exact
                                    path='/admin/locations/:locationId/enrollments/:enrollmentId'
                                    component={EnrollmentDetailsViewAdmin}/>

                                <Route
                                    exact
                                    path='/enrollments-for-attention'
                                    component={EnrollmentsForAttentionView}/>

                                <Route
                                    exact
                                    path='/enrollments'
                                    component={UserEnrollments}/>
                            </Switch>
                        </Container>
                        <ToastContainer/>
                    </Container>
                </>
            </HashRouter>
        );
    }
}

function mapStateToProps(state) {
    return state.authentication;
}

export default connect(mapStateToProps)(App);