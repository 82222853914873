export const MAX_ADDED_NUM_FOR_GROUP_PERIOD = 5;

export const RECEIVED = 'RECEIVED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CONFIRMED = 'CONFIRMED';
export const CANCELLED = 'CANCELLED';
export const REJECTED = 'REJECTED';
export const CONFIRM_REQUEST = 'CONFIRM_REQUEST';
export const REJECT_REQUEST = 'REJECT_REQUEST';

export const CONFIRMATION_SOLO = 'SOLO';
export const CONFIRMATION_DISTRIBUTED = 'DISTRIBUTED';

export const EVENTS_ON_PAGE = 10;
export const ENROLLMENTS_ON_PAGE = 10;

export const REGION_TERNOPOL='тернопіль';
export const REGION_FRANKOVSK='івано-франківськ';

export const PRIVILEGES = [
    {
        key: 1,
        value: 1,
        text: 'Діти з інвалідністю, які не мають протипоказань до відвідування ЗДО'
    },
    {
        key: 2,
        value: 2,
        text: 'Діти батьків – з інвалідністю I групи'
    },
    {
        key: 3,
        value: 3,
        text: 'Діти учасників ліквідації наслідків катастрофи на Чорнобильській АЕС, потерпілих від екологічного та іншого стихійного лиха, аварій, катастроф'
    },
    {
        key: 4,
        value: 4,
        text: 'Діти військовослужбовців Збройних сил України, Національної гвардії, учасників АТО.'
    },
    {
        key: 5,
        value: 5,
        text: 'Діти військовослужбовців Служби безпеки України'
    },
    {
        key: 6,
        value: 6,
        text: 'Діти військовослужбовців військової прокуратури'
    },
    {
        key: 7,
        value: 7,
        text: 'Діти внутрішньо переміщених осіб'
    },
    {
        key: 8,
        value: 8,
        text: 'Діти, які перебувають під опікою'
    },
    {
        key: 9,
        value: 9,
        text: 'Діти працівників, які працюють у закладі дошкільної освіти'
    },
    {
        key: 10,
        value: 10,
        text: 'Діти з багатодітних сімей'
    },
    {
        key: 11,
        value: 11,
        text: 'Iнше (вкажіть в додатковій  інформації)'
    },
    {
        key: 12,
        value: 12,
        text: 'Iнвалід дитинства'
    }

];