import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

import { Button, Checkbox, Dropdown, Form, Header, Icon, Label, Message, Segment, Tab } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';
import Toast from '../helpers/Toasts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
// services
import { getFieldError, isFieldError, } from '../../utils/validation';
import { doFetchGroup, } from '../../actions/group/fetchGroup';
import { doUpdateGroup, } from '../../actions/group/updateGroup';
import { canCreateGroup, isLocationAdmin, isRegionAdmin } from '../../services/AuthService';
import t from '../../services/Translate';
// components
import DeleteGroup from './DeleteGroup';
import EnrollmentsTable from '../enrollment/EnrollmentsTable';
import LargeDimmer from '../helpers/Dimmer';
import { GROUP_RESET } from '../../actions/group/group';
import { GroupDetailsBreadcrumb } from './GroupDetailsBreadcrumb';
import { DATE_PICKER_FORMAT } from '../../utils/settings';
import GroupAgeDropDown from '../group-age/GroupAgeDropdown';
import GroupAdminListSection from './admin/GroupAdminListSection';

const periods = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025].map(value => {
    return {
        key: value,
        value: value,
        text: value + '-' + (value + 1),
    };
});

const initialState = {
    group: {
        id: '',
        name: '',
        period: '',
        restricted: false,
        startDate: null,
        endDate: null,
        dateStart: null,
        dateEnd: null,
        locationId: '',
        maxChildren: '',
        maxPrivilegedChildren: '',
        enrollments: [],
        enabled: false,
        groupAgeId: null,
    },
    activeIndex: 0,
};

class GroupDetailsAdminView extends Component {

    state = { ...initialState };

    getGroupId = () => this.props.match.params.groupId;

    fetchGroup = () => {
        this.props.dispatch(doFetchGroup(this.getGroupId()));
    };

    componentWillUnmount() {
        this.props.dispatch({ type: GROUP_RESET });
    }

    componentDidMount() {
        this.fetchGroup();
    }

    componentDidUpdate(prevProps) {
        const { isFetched } = this.props;
        const { isUpdated, group } = this.props;

        if (isFetched && !prevProps.isFetched) {
            this.setState({ group });
        }

        if (isUpdated && !prevProps.isUpdated) {
            Toast('success', t('groupSaved'));
            this.fetchGroup();
        }
    }

    handleSubmitClick = (event) => {
        event.preventDefault();

        const { group } = this.state;

        if (isLocationAdmin()) {

            this.sendGroupUpdateRequest(group);

        } else if (isRegionAdmin()) {

            const { groupUpdateRequest } = group;

            const { maxChildren, maxPrivilegedChildren } = groupUpdateRequest;

            this.sendGroupUpdateRequest({ ...group, maxChildren, maxPrivilegedChildren });
        }
    };

    /**
     * If region admin sends the same maxChildren and maxPrivilegedChildren it is considered as rejection
     */
    handleRejectClick = (event) => {
        event.preventDefault();
        this.sendGroupUpdateRequest(this.state.group);
    };

    sendGroupUpdateRequest = (group) => {
        this.props.dispatch(doUpdateGroup(group));
    };

    handleChange = (_, { name, value }) => {
        const { group } = this.state;
        this.setState({ group: { ...group, [name]: value } });
    };

    toggleRestricted = () => {
        this.setState({
            group: { ...this.state.group, restricted: !this.state.group.restricted }
        });
    };

    toggleEnabled = () => {
        this.setState({
            group: { ...this.state.group, enabled: !this.state.group.enabled }
        });
    };

    isMaxChildrenUpdateRequest = () => {
        const { groupUpdateRequest } = this.state.group;

        return groupUpdateRequest && groupUpdateRequest.maxChildren !== this.props.group.maxChildren;
    };

    isMaxPrivilegedChildrenUpdateRequest = () => {
        const { groupUpdateRequest } = this.state.group;

        return groupUpdateRequest && groupUpdateRequest.maxPrivilegedChildren !== this.props.group.maxPrivilegedChildren;
    };

    isUpdateRequest = () =>
        this.isMaxChildrenUpdateRequest() || this.isMaxPrivilegedChildrenUpdateRequest();

    handleDateStartChange = dateStart => {
        this.setState({
            group: { ...this.state.group, dateStart }
        });
    };

    handleDateEndChange = dateEnd => {
        this.setState({
            group: { ...this.state.group, dateEnd }
        });
    };

    handleTabChange = (e, { activeIndex }) => {
        this.setState({ activeIndex });
    };

    render() {

        const {
            isUpdating,
            isFetching,
            isFetched,
            isUpdated,
            isUpdateFailed,
            group
        } = this.props;

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        if (isUpdating) {
            return <LargeDimmer text='Збереження даних'/>;
        }

        if (!isFetched && !isUpdated && !isUpdateFailed) {
            return null;
        }

        if (!group) {
            return null;
        }

        const { id, locationId } = group;

        if (!id) {
            return null;
        }

        const panes = [
            {
                menuItem: 'Заявки', render: () => {
                    return (
                        <Tab.Pane>
                            {isLocationAdmin() &&
                            <Segment basic>
                                <Button
                                    as={Link}
                                    to={{ pathname: `/admin/locations/${locationId}/groups/${group.id}/enrollments/create` }}
                                    icon='add'
                                    labelPosition='right'
                                    content='Додати заявку'
                                />
                            </Segment>
                            }

                            <EnrollmentsTable
                                enrollments={group.enrollments}
                                stats={group.stats}
                                onReload={this.fetchGroup}
                            />
                        </Tab.Pane>
                    );
                }
            },
        ];

        if(isLocationAdmin() || isRegionAdmin()) {
            panes.push({
                menuItem: t('groupDetails'), render: () => {
                    return (
                        <Tab.Pane>
                            {this.renderGroupDetailsForm()}

                            {canCreateGroup(group.createGroupPermission) &&
                            <DeleteGroup group={group}/>
                            }
                        </Tab.Pane>
                    );
                }
            });
        }

        if(isLocationAdmin()) {
            panes.push({
                menuItem: 'Адміністратори', render: () => {
                    return (
                        <Tab.Pane>
                            <GroupAdminListSection targetId={group.id} targetName={group.name}/>
                        </Tab.Pane>
                    );
                }
            });
        }

        return (
            <>
                <GroupDetailsBreadcrumb group={group}/>
                {!group.enabled &&
                <Message negative>
                    <Message.Header>Увага!</Message.Header>
                    <p>Подача заявок батьками не активована</p>
                </Message>
                }
                <Tab
                    activeIndex={this.state.activeIndex}
                    onTabChange={this.handleTabChange}
                    panes={panes}/>
            </>
        );
    }

    renderGroupDetailsForm = () => {

        const {
            groupUpdateRequest,
            maxChildren,
            maxPrivilegedChildren,
            name,
            groupAgeId,
            locationId,
            period,
        } = this.state.group;

        return (
            <>
                <Header as='h3' content={t('groupDetails')}/>
                <Form>
                    <Form.Field>
                        <label>Назва</label>
                        <Form.Input
                            readOnly={!isLocationAdmin()}
                            name='name'
                            value={name}
                            error={isFieldError(this.props, 'name')}
                            onChange={this.handleChange}
                        />

                        {isFieldError(this.props, 'name') &&
                        <Label color='red' pointing>
                            {getFieldError(this.props, 'name')}
                        </Label>}
                    </Form.Field>

                    <GroupAgeDropDown
                        locationId={locationId}
                        value={groupAgeId}
                        onChange={this.handleChange}/>

                    <Form.Field>
                        <label>Період</label>
                        <Dropdown
                            name='period'
                            options={periods}
                            selection
                            fluid
                            value={period}
                            onChange={this.handleChange}
                        />

                        {isFieldError(this.props, 'period') &&
                        <Label color='red' pointing>{getFieldError(this.props, 'period')}</Label>}
                    </Form.Field>

                    <Form.Field>
                        <Checkbox
                            toggle
                            disabled={!isLocationAdmin()}
                            label='Набір обмежений за часом'
                            checked={this.state.group.restricted}
                            onChange={this.toggleRestricted}
                        />
                    </Form.Field>

                    <Form.Group>
                        <Form.Field
                            disabled={!this.state.group.restricted || !isLocationAdmin()}>
                            <label>Початок набору</label>
                            <DatePicker
                                dateFormat={DATE_PICKER_FORMAT}
                                selected={this.state.group.dateStart}
                                onChange={this.handleDateStartChange}
                                locale={uk}
                            />
                            {isFieldError(this.props, 'dateStart') &&
                            <>
                                <br/>
                                <Label color='red' pointing>{getFieldError(this.props, 'dateStart')}</Label>
                            </>
                            }
                        </Form.Field>
                        <Form.Field disabled={!this.state.group.restricted || !isLocationAdmin()}>
                            <label>Кінець набору</label>
                            <DatePicker
                                dateFormat={DATE_PICKER_FORMAT}
                                selected={this.state.group.dateEnd}
                                onChange={this.handleDateEndChange}
                                locale={uk}
                            />
                            {isFieldError(this.props, 'dateEnd') &&
                            <>
                                <br/>
                                <Label color='red' pointing>{getFieldError(this.props, 'dateEnd')}</Label>
                            </>
                            }
                        </Form.Field>
                    </Form.Group>

                    <Form.Field>
                        <label>Максимальна кількість дітей</label>
                        <Form.Input
                            readOnly={!isLocationAdmin()}
                            type='number'
                            name='maxChildren'
                            value={maxChildren}
                            error={isFieldError(this.props, 'maxChildren')}
                            onChange={this.handleChange}
                        />

                        {isFieldError(this.props, 'maxChildren') &&
                        <Label color='red' pointing>{getFieldError(this.props, 'maxChildren')}</Label>}

                        {this.isMaxChildrenUpdateRequest() &&
                        <Label color='orange' pointing>
                            Запропонована зміна значення на {groupUpdateRequest.maxChildren}
                        </Label>}
                    </Form.Field>

                    <Form.Field>
                        <label>Зарезервовано пільгових місць</label>
                        <Form.Input
                            readOnly={!isLocationAdmin()}
                            type='number'
                            name='maxPrivilegedChildren'
                            value={maxPrivilegedChildren}
                            error={isFieldError(this.props, 'maxPrivilegedChildren')}
                            onChange={this.handleChange}
                        />

                        {isFieldError(this.props, 'maxPrivilegedChildren') &&
                        <Label color='red' pointing>
                            {getFieldError(this.props, 'maxPrivilegedChildren')}
                        </Label>}

                        {this.isMaxPrivilegedChildrenUpdateRequest() &&
                        <Label color='orange' pointing>
                            Запропонована зміна значення на {groupUpdateRequest.maxPrivilegedChildren}
                        </Label>}
                    </Form.Field>

                    <Form.Field>
                        <Checkbox
                            toggle
                            label='Відкрито для подачі заявок батьками'
                            checked={this.state.group.enabled}
                            onChange={this.toggleEnabled}
                            disabled={!isLocationAdmin()}
                        />
                    </Form.Field>

                    <Button
                        type='button'
                        positive onClick={this.handleSubmitClick}
                        disabled={!isLocationAdmin()}>Зберегти</Button>

                    {isRegionAdmin() && this.isUpdateRequest() &&
                    <>
                        <Button type='button' size='mini' positive onClick={this.handleSubmitClick}>
                            <Icon name='check circle'/>
                            Підтвердити запропоновані зміни
                        </Button>
                        <Button type='button' size='mini' negative onClick={this.handleRejectClick}>
                            <Icon name='times circle'/>
                            Відхилити запропоновані зміни
                        </Button>
                    </>
                    }
                </Form>
            </>);
    };
}

GroupDetailsAdminView.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
    return {
        ...store.updateGroup,
        ...store.fetchGroup,
        errorCode: store.updateGroup.errorCode || store.fetchGroup.errorCode
    };
}

export default connect(mapStateToProps)(GroupDetailsAdminView);