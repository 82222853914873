// react
import React, { Component } from 'react';

// semantic
import { Button, Header, Table } from 'semantic-ui-react';

// libs
import _ from 'lodash';

// services
import LargeDimmer from '../helpers/Dimmer';

const initialState = {
    email: '',
    isRemoveAdminDialogOpen: false
};

class AdminListSectionBase extends Component {

    state = { ...initialState };


    componentDidMount() {
        this.fetchAdmins();
    }

    onRefresh = () => {
        this.setState({ ...initialState });
        this.fetchAdmins();
    };

    onRemoveAdmin = (email) =>
        this.setState({
            email,
            isRemoveAdminDialogOpen: true
        });

    handleChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    renderAdminsTable() {

        const { isFetched, isFetching, payload } = this.props;

        const hasNoData = isFetched && _.isEmpty(payload);

        if (hasNoData) {
            return (
                <Header
                    as='h5'
                    content='Поки що не додано жодного адміністратора'
                />
            );
        } else if (isFetching || !payload) {
            return <LargeDimmer text='Завантаження данних'/>;
        } else {
            return (
                <Table selectable striped celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing content='ПІБ адміністратора'/>
                            <Table.HeaderCell collapsing content='Електронна поштова скринька'/>
                            <Table.HeaderCell collapsing content='Дії'/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {payload.map(admin => {
                            const { fullName } = admin;
                            return (
                                <Table.Row key={admin.id}>
                                    <Table.Cell collapsing content={fullName}/>
                                    <Table.Cell collapsing content={admin.email}/>
                                    <Table.Cell collapsing>
                                        <Button
                                            size='mini'
                                            onClick={() => this.onRemoveAdmin(admin.email)}
                                            icon='remove'
                                            content='Видалити'
                                            color='red'
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            );
        }
    }
}

export default AdminListSectionBase;
