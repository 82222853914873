export const REQUEST_SETTINGS = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    data: {},
    withCredentials: true
};

export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_PICKER_FORMAT = 'dd-MM-yyyy';

export const ZONE = 'Europe/Kiev';

export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

export const EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL;

export const SERVICE_TYPE = process.env.REACT_APP_SERVICE_TYPE;

const SERVICE_TYPE_SCHOOL = 'school';
const SERVICE_TYPE_KINDERGARTEN = 'kindergarten';
const SERVICE_TYPE_GURTOK = 'gurtok';

export const isSchool = () => SERVICE_TYPE === SERVICE_TYPE_SCHOOL;
export const isKindergarten = () => SERVICE_TYPE === SERVICE_TYPE_KINDERGARTEN;
export const isGurtok = () => SERVICE_TYPE === SERVICE_TYPE_GURTOK;

export const CLIENT_VERSION = process.env.REACT_APP_VERSION;
export const CLIENT_VERSION_HEADER = 'x-client-app-version';

export const GA = process.env.REACT_APP_GA;
