import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import AddAdminFormBase from '../../common/AddAdminFormBase';
import AddGroupAdminDialog from '../dialog/AddGroupAdminDialog';

class AddGroupAdminForm extends AddAdminFormBase {

    renderDialog = () => {
        return (
            <AddGroupAdminDialog
                email={this.state.email}
                targetId={this.props.targetId}
                targetName={this.props.targetName}
                isDialogOpen={this.state.isAddAdminDialogOpen}
                onClose={this.onCompleted}
            />
        );
    };
}

AddGroupAdminForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    onAdminAdded: PropTypes.func.isRequired,
    targetId: PropTypes.number.isRequired,
    targetName: PropTypes.string.isRequired
};

function mapStateToProps(store) {
    return store.groupAdmin;
}

export default connect(mapStateToProps)(AddGroupAdminForm);
