import { getResponseData } from '../utils';
import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { formatDateToLocalDateString } from '../../utils/dateTime';
import { transform } from '.';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

function updateGroupRequest() {
    return { type: UPDATE_GROUP_REQUEST };
}

function updateGroupSuccess(res) {
    return { type: UPDATE_GROUP_SUCCESS, group: transform(res.data) };
}

function updateGroupFailure(error) {
    const { errorCode, fieldErrors } = getResponseData(error);
    return { type: UPDATE_GROUP_FAILURE, errorCode, fieldErrors };
}

export function doUpdateGroup({
    id,
    name,
    restricted,
    dateStart,
    dateEnd,
    groupUpdateRequest,
    maxChildren,
    maxPrivilegedChildren,
    enabled,
    groupAgeId,
    period,
}) {

    const updatedGroup = {
        id,
        name,
        restricted,
        groupUpdateRequest,
        maxChildren,
        maxPrivilegedChildren,
        enabled,
        dateStart: formatDateToLocalDateString(dateStart),
        dateEnd: formatDateToLocalDateString(dateEnd),
        groupAgeId,
        period
    };

    return async function (dispatch) {

        dispatch(updateGroupRequest());

        return axios
            .put('/api/groups', updatedGroup, REQUEST_SETTINGS)
            .then(res => dispatch(updateGroupSuccess(res)))
            .catch(error => dispatch(updateGroupFailure(error)));
    };
}