import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';
import { getResponseData } from '../utils';

export const SEND_CONFIRMATION_EMAIL_REQUEST = 'SEND_CONFIRMATION_EMAIL_REQUEST';
export const SEND_CONFIRMATION_EMAIL_SUCCESS = 'SEND_CONFIRMATION_EMAIL_SUCCESS';
export const SEND_CONFIRMATION_EMAIL_FAIL = 'SEND_CONFIRMATION_EMAIL_FAIL';
export const SEND_CONFIRMATION_EMAIL_INIT = 'SEND_CONFIRMATION_EMAIL_INIT';

export function sendConfirmationEmail(email) {

    return async function (dispatch) {

        dispatch(sendSendConfirmationEmailRequest());

        return axios
            .post('/api/resend-confirmation', email, REQUEST_SETTINGS)
            .then(res => dispatch(sendConfirmationEmailSuccess(res)))
            .catch(res => dispatch(sendConfirmationEmailFail(res)));
    };
}

function sendSendConfirmationEmailRequest() {
    return { type: SEND_CONFIRMATION_EMAIL_REQUEST };
}

function sendConfirmationEmailSuccess() {
    return { type: SEND_CONFIRMATION_EMAIL_SUCCESS };
}

export function sendConfirmationEmailFail(res) {

    const { errorCode, fieldErrors } = getResponseData(res);

    return { type: SEND_CONFIRMATION_EMAIL_FAIL, errorCode, fieldErrors };
}

export function sendConfirmationEmailInit() {
    return { type: SEND_CONFIRMATION_EMAIL_INIT };
}
