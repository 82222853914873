import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { completeRedirect } from '../actions/logout';
import { loadAccountDetails } from '../actions/accountDetails';

class AuthController extends Component {

    componentDidUpdate() {
        if (this.props.shouldRedirect) {
            this.props.dispatch(completeRedirect(this.props.history));
        }
    }

    componentDidMount() {

        // TODO this probably should be refactored somehow
        const ignorePaths = [
            '/reset-password',
            '/register',
            '/login',
            '/resend-confirmation-email',
            '/send-password-reset-email',
            '/confirm',
            '/login',
            '/'
        ];

        const { pathname } = this.props.location;

        if (!ignorePaths.includes(pathname)) {
            this.props.dispatch(loadAccountDetails());
        }
    }

    render() {
        return null;
    }
}

AuthController.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return { ...state.authentication, ...state.accountDetails };
}

export default withRouter(connect(mapStateToProps)(AuthController));