import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getToken } from '../utils/token';
import { Message, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { doConfirmation } from '../actions/confirm';

class Confirm extends Component {

    componentDidMount() {
        const token = getToken(this.props.location);
        this.props.dispatch(doConfirmation(token));
    }

    render() {

        const { isConfirmed, isConfirmationFailed } = this.props;

        if (isConfirmed) {
            return (
                <Segment>
                    <Message success id='confirm-success'>
                        <Message.Header>Адресу електронної пошти підтверджено!</Message.Header>
                        <p>Будь ласка, використовуйте свій логін і пароль для <Link to='/login'>входу на сайт</Link>.</p>
                    </Message>
                </Segment>
            );
        }

        if (isConfirmationFailed) {
            return (
                <Segment>
                    <Message error>
                        <Message.Header>Помилка підтвердження електронної пошти.</Message.Header>
                        <p>Перевірте будь ласка правильність посилання</p>
                    </Message>
                </Segment>
            );
        }

        return null;
    }
}

Confirm.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.confirmation;
}

export default connect(mapStateToProps)(Confirm);