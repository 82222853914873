import axios from 'axios';
import { REQUEST_SETTINGS } from '../utils/settings';
import { clearAccountDetails, loadAccountDetails, setAccountDetails } from './accountDetails';
import { createGetParams, getResponseData } from './utils';

export const START_LOGIN = 'START_LOGIN';
export const COMPLETE_LOGIN = 'COMPLETE_LOGIN';
export const FAIL_LOGIN = 'FAIL_LOGIN';
export const RESTORE_LOGIN = 'RESTORE_LOGIN';

export const START_PREFETCH = 'START_PREFETCH';
export const COMPLETE_PREFETCH = 'COMPLETE_PREFETCH';

function startPrefetch() {
    return { type: START_PREFETCH };
}

function completePrefetch(res) {
    return { type: COMPLETE_PREFETCH, ...res.data };
}

export function prefetchLogin() {

    return async function (dispatch) {

        dispatch(startPrefetch());

        return axios
            .get('/login', createGetParams())
            .then(res => dispatch(completePrefetch(res)));
    };
}

function startLogin() {
    return { type: START_LOGIN };
}

function completeLogin() {
    return { type: COMPLETE_LOGIN };
}

function failLogin(error) {
    const { errorCode } = getResponseData(error);
    return { type: FAIL_LOGIN, errorCode };
}

export function doLogin(username, password, captchaResponse, history) {

    return async function (dispatch) {

        dispatch(startLogin());

        return axios
            .post('/login', { username, password, captchaResponse }, REQUEST_SETTINGS)
            .then(res => {
                dispatch(setAccountDetails(res.data));
                dispatch(completeLogin(res));
                dispatch(loadAccountDetails());

                history.push('/user-info');
            })
            .catch((err) => {
                console.error(err);
                dispatch(clearAccountDetails());
                dispatch(failLogin(err));
                dispatch(prefetchLogin());
            });
    };
}