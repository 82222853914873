import React from 'react';
import { Button, List, Table } from 'semantic-ui-react';
import { getEnrollmentStatusName } from '../../../utils/enrollmentUtils';
import { formatDateToLocalDateString, formatTimestampToDateTimeString } from '../../../utils/dateTime';
import _ from 'lodash';
import { isLocationAdmin } from '../../../services/AuthService';
import { CANCELLED, REJECTED } from '../../../const/variables';
import { Link } from 'react-router-dom';

const ChildInfoTab = ({ enrollment }) => {
    const { enrollmentStatus, created, accountInfo, location } = enrollment;

    return (
        <>
            <Table basic="very" celled collapsing>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell collapsing content="ПІБ:"/>
                        <Table.Cell
                            content={enrollment.lastName + ' ' + enrollment.firstName + ' ' + enrollment.middleName}/>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell collapsing content="Статус заявки"/>
                        <Table.Cell content={getEnrollmentStatusName(enrollmentStatus)}/>
                    </Table.Row>
                    {enrollment.statusComment &&
                        <Table.Row>
                            <Table.Cell collapsing content="Коментар"/>
                            <Table.Cell content={enrollment.statusComment}/>
                        </Table.Row>
                    }
                    <Table.Row>
                        <Table.Cell collapsing content="Дата подачі"/>
                        <Table.Cell content={formatTimestampToDateTimeString(created)}/>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell collapsing content="Телефон:"/>
                        <Table.Cell>
                            <List>
                                <List.Item
                                    key={enrollment.parentPhone}
                                    content={`+380 ${enrollment.parentPhone}`}/>
                                {accountInfo && accountInfo.phoneNumber !== enrollment.parentPhone &&
                                    <List.Item
                                        key={accountInfo.phoneNumber}
                                        content={`+380 ${accountInfo.phoneNumber}`}/>
                                }
                            </List>
                        </Table.Cell>
                    </Table.Row>
                    {!_.isEmpty(enrollment.parentEmail) &&
                        <Table.Row>
                            <Table.Cell collapsing content="Email:"/>
                            <Table.Cell content={enrollment.parentEmail}/>
                        </Table.Row>
                    }
                    <Table.Row>
                        <Table.Cell collapsing content="Дата народження:"/>
                        <Table.Cell content={formatDateToLocalDateString(enrollment.birthDate)}/>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell collapsing content="Стать:"/>
                        <Table.Cell content={enrollment.sex === 'MALE' ? 'хлопчик' : 'дівчинка'}/>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell collapsing content="Документ, що засвідчує дитину:"/>
                        <Table.Cell>
                            {enrollment.documentId !== '' &&
                                <>
                                    <strong>Серія та номер:</strong> {enrollment.documentId}
                                    <br/>
                                    <strong>Опис:</strong> {enrollment.documentInfo}
                                </>
                            }
                        </Table.Cell>
                    </Table.Row>
                    {!_.isEmpty(enrollment.otherDocument) &&
                        <Table.Row>
                            <Table.Cell collapsing content="Додатковий документ:"/>
                            <Table.Cell>{enrollment.otherDocument}</Table.Cell>
                        </Table.Row>
                    }
                    <Table.Row>
                        <Table.Cell collapsing content="Адреса:"/>
                        <Table.Cell content={enrollment.address}/>
                    </Table.Row>
                    {!_.isEmpty(enrollment.additionalInfo) &&
                        <Table.Row>
                            <Table.Cell collapsing content="Додаткова інформація:"/>
                            <Table.Cell content={enrollment.additionalInfo}/>
                        </Table.Row>
                    }
                    <Table.Row>
                        <Table.Cell collapsing content={'Ім\'я одного з батьків:'}/>
                        <Table.Cell content={enrollment.parentName}/>
                    </Table.Row>
                    {!_.isEmpty(enrollment.privileges) &&
                        <Table.Row>
                            <Table.Cell collapsing content="Пільги:"/>
                            <Table.Cell>
                                <List bulleted>
                                    {
                                        enrollment.privileges.map(privilege => {
                                            return <List.Item
                                                key={privilege.id}>{privilege.description}</List.Item>;
                                        })
                                    }
                                </List>
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
            </Table>

            <Button
                as={Link}
                to={`/admin/locations/${location.id}/enrollments/${enrollment.id}/edit`}
                disabled={!isLocationAdmin() || enrollment.enrollmentStatus === REJECTED || enrollment.enrollmentStatus === CANCELLED}>Редагувати</Button>
        </>
    );
};

export default ChildInfoTab;