import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { fetchLocationsForAdmin } from '../../actions/location/locationsForAdmin';
import LocationListAdminSection from './LocationListAdminSection';
import LargeDimmer from '../helpers/Dimmer';

class LocationListAdmin extends Component {

    componentDidMount() {
        this.props.dispatch(fetchLocationsForAdmin());
    }

    render() {

        const { isFetching, locations } = this.props;

        if (isFetching) {
            return <LargeDimmer text='Завантаження даних'/>;
        }

        return (<LocationListAdminSection locations={locations}/>);
    }
}

LocationListAdmin.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
    return store.locationsForAdmin;
}

export default connect(mapStateToProps)(LocationListAdmin);