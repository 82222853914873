import _ from 'lodash';
import { formatDateToLocalDateString } from '../utils/dateTime';
import { REQUEST_SETTINGS } from '../utils/settings';

export function getResponseData(res) {
    return (res && res.response && res.response.data) || {};
}

export function createGetParams(params = {}) {
    return {
        ...REQUEST_SETTINGS,
        params: { ...params, ts: new Date().getTime() }
    };
}

export function getChildFromState(state) {
    const child = {
        uuid: state.uuid,
        firstName: state.firstName,
        lastName: state.lastName,
        middleName: state.middleName,
        birthDate: formatDateToLocalDateString(state.birthDate),
        sex: state.sex,
        documentType: state.documentType,
        documentId: _.trim(state.documentId).replace(/[ №]/g, ''),
        documentInfo: state.documentInfo,
        otherDocument: state.otherDocument,
        address: state.address,
        registrationAddress: state.registrationAddress,
        parentName: state.parentName,
        parentPhone: _.replace(state.parentPhone, /\D/g, ''),
        additionalInfo: state.additionalInfo,
        privilegeIds: state.privilegeIds,
    };

    return _.pickBy(child, _.identity);
}