import {
    START_CONFIRMATION,
    COMPLETE_CONFIRMATION,
    FAIL_CONFIRMATION
} from '../actions/confirm';

export default function confirmation(state = {
    isRegistered: false,
    isFetching: false,
    isRegistrationFailed: false
}, action) {

    switch (action.type) {
    case START_CONFIRMATION:
        return {
            ...state,
            isConfirmed: false,
            isFetching: true,
            isConfirmationFailed: false,
            errorCode: null
        };
    case COMPLETE_CONFIRMATION:
        return {
            ...state,
            isConfirmed: action.status === 200,
            isFetching: false,
            isConfirmationFailed: false,
            errorCode: null
        };
    case FAIL_CONFIRMATION:
        return {
            ...state,
            isConfirmed: false,
            isFetching: false,
            isConfirmationFailed: true,
            errorCode: action.errorCode
        };
    default:
        return state;
    }
}