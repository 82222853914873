// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// semantic
import { Accordion, Button, Dropdown, Header, Icon, Label, Message, Modal } from 'semantic-ui-react';
// libs
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';
// actions
import { doApplyToGroup, resetApplyToGroup } from '../../actions/enrollment/applyToGroup';
import { doListChildren } from '../../actions/child/listChildren';
// services
import t from '../../services/Translate';
import { isSchool, RECAPTCHA_KEY } from '../../utils/settings';
import {
    isLimitForEnrollmentError,
    isRecaptchaError,
    limitForEnrollmentError,
    recaptchaError
} from '../../utils/validation';
import Notification from '../helpers/Notification';

const recaptchaRef = React.createRef();

const initialState = {
    childUuid: null,
    open: true,
    captchaResponse: '',
};

const getChildren = (props) =>
    props.children.map(child => (
        {
            key: child.uuid,
            value: child.uuid,
            text: `${child.lastName} ${child.firstName} ${child.middleName}`
        }
    ));

class ApplyToGroupModal extends Component {

    state = { ...initialState };

    componentWillUnmount() {
        this.props.dispatch(resetApplyToGroup());
    }

    componentDidMount() {
        this.props.dispatch(doListChildren());
    }

    componentDidUpdate(prevProps) {
        const { errorCode } = this.props;

        if (errorCode !== prevProps.errorCode
            && isRecaptchaError(this.props)
            && recaptchaRef.current) {
            recaptchaRef.current.reset();
        }

        const children = getChildren(this.props);

        if (children.length === 1 && !this.state.childUuid) {
            this.setState({ childUuid: children[0].key });
        }
    }

    getSelectedChild = () => {
        const children = getChildren(this.props);
        if (children && children.length === 1) {
            return children[0].value;
        } else {
            return this.state.childUuid;
        }
    };

    close = () => {
        this.props.dispatch(resetApplyToGroup());
        this.props.onCancelEnroll();
        this.setState({ ...initialState });
    };

    isChildSelected = () =>
        !!this.state.childUuid;

    handleSubmitClick = (event) => {
        event.preventDefault();

        if (!this.state.childUuid) {
            return;
        }

        this.props.dispatch(doApplyToGroup({
            childUuid: this.state.childUuid,
            groupId: this.props.group.id,
            captchaResponse: this.state.captchaResponse,
        }));

    };

    onDropdownChange = (event, { value }) => {
        event.preventDefault();

        this.setState({ childUuid: value });
    };

    onRecaptchaResolved = (captchaResponse) => {
        this.setState({ captchaResponse });
    };

    getAdditionalInfo = (content) => {
        return [
            {
                key: 'additional-info',
                title: 'Переглянути додаткову інформацію',
                content: {
                    content: (
                        <div style={{whiteSpace: 'pre-wrap'}}>
                            {content}
                        </div>
                    )
                }
            }
        ];
    };

    render() {

        const { group, location, isDialogOpen } = this.props;

        return (

            <Modal open={isDialogOpen} centered={false}>
                <Modal.Header>Подати заявку</Modal.Header>
                <Modal.Content>

                    {isSchool() && !_.isEmpty(location.longDescription) &&
                    <Message info>
                        <Message.Header>
                            <Icon name='exclamation'/>Увага!
                        </Message.Header>
                        <p>Діти повинні мати реєстрацію відповідно до округу школи</p>

                        <Accordion panels={this.getAdditionalInfo(location.longDescription)}/>
                    </Message>
                    }

                    {isLimitForEnrollmentError(this.props) &&
                    <Notification.Error content={limitForEnrollmentError(this.props)}/>}

                    {this.props.isAppliedToGroup &&
                    <Notification.Success
                        header='Заявка була успішно додана до черги'
                        content={t('enrollmentConfirm')(group.name)}/>
                    }

                    {!this.props.isAppliedToGroup &&
                    <>
                        <Header as='h4'>{group.name}</Header>
                        <Header as='h4'>
                            <Icon name='calendar alternate outline'/> {group.period} — {group.period + 1}
                        </Header>
                        <Header as='h4'>
                            <Header.Content>
                                <Dropdown
                                    selection
                                    placeholder='Виберіть дитину'
                                    onChange={this.onDropdownChange}
                                    options={getChildren(this.props)}
                                    defaultValue={this.getSelectedChild()}
                                />
                            </Header.Content>
                        </Header>

                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={RECAPTCHA_KEY}
                            onChange={this.onRecaptchaResolved}
                            hl='uk'
                        />
                        {isRecaptchaError(this.props) &&
                        <Label color='red' pointing>
                            {recaptchaError(this.props)}
                        </Label>}
                    </>
                    }

                </Modal.Content>
                {this.props.isAppliedToGroup &&
                <Modal.Actions>
                    <Button primary onClick={this.close}>OK</Button>
                </Modal.Actions>
                }

                {!this.props.isAppliedToGroup &&
                <Modal.Actions>

                    <Button
                        type='button' primary
                        disabled={!this.isChildSelected()}
                        onClick={this.handleSubmitClick}
                        animated='fade'>
                        <Button.Content visible>Подати заявку</Button.Content>
                        <Button.Content hidden><Icon name='add user'/> Подати заявку</Button.Content>
                    </Button>

                    <Button secondary animated onClick={this.close}>
                        <Button.Content visible>Відміна</Button.Content>
                        <Button.Content hidden>
                            <Icon name='arrow left'/>
                        </Button.Content>
                    </Button>

                </Modal.Actions>
                }
            </Modal>
        );
    }
}

ApplyToGroupModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    group: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    onCancelEnroll: PropTypes.func.isRequired

};

function mapStateToProps(state) {
    return {
        ...state.applyToGroup,
        ...state.listChildren
    };
}

export default connect(mapStateToProps)(ApplyToGroupModal);