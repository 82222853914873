import { Message } from 'semantic-ui-react';
import React from 'react';

export const ternopolSchoolInfo = () => (
    <Message>
        <Message.Header>Довідкова інформація</Message.Header>
        <Message.Content>
            Будь ласка, ознайомтеся з
            <a
                href='https://bloqly-public.s3.fr-par.scw.cloud/pdfs/1583328825370272.pdf'
                target='_blank' rel="noopener noreferrer"> додатковою інформацією</a>
        </Message.Content>
    </Message>
);

export const ternopolKindergartenInfo = () => (
    <Message>
        <Message.Header>Довідкова інформація</Message.Header>
        <Message.Content>
            <p>
                Для внесення до Реєстру відомостей про дитину, яка має відвідувати заклад дошкільної
                освіти Тернопільської міської територіальної громади батьки або особи, які їх замінюють,
                окрім заяви, повинні надати у ЦНАП наступні документи (оригінали):
            </p>

            <ol>
                <li>свідоцтво про народження дитини;</li>
                <li>один із документів, що підтверджує реєстрацію місця проживання дитини або
                    батьків (одного з батьків) в Тернопільській міській територіальній громаді, а саме:
                    витяг з Єдиного державного демографічного реєстру щодо реєстрації місця
                    проживання/перебування особи (дитини або одного з її батьків); один із
                    документів, до яких вносяться відомості про місце проживання (паспорт
                    громадянина України, тимчасове посвідчення громадянина України, посвідка на
                    постійне проживання, посвідка на тимчасове проживання, посвідчення біженця,
                    посвідчення особи, яка потребує додаткового захисту, посвідчення особи, якій
                    надано тимчасовий захист) одного з батьків дитини з відміткою про реєстрацію
                    місця проживання (під час подання документів пред&#39;являється оригінал
                    відповідного документа);
                </li>

                <li>довідку про реєстрацію місця проживання/перебування особи (дитини або одного з
                    її батьків), що видається відповідно до Правил реєстрації місця проживання та
                    Порядку передачі органами реєстрації інформації до Єдиного державного
                    демографічного реєстру, затверджених постановою Кабінету Міністрів України від
                    2 березня 2016 року № 207. документ, що підтверджує право на пільгу (пільгові
                    категорії дітей визначені п. 3.10. даного Положення).
                </li>
            </ol>
            <p>
                У разі неподання батьками, чи особами, які їх замінюють протягом встановленого
                терміну (5 робочих днів з дня заповнення електронної форми) вказаних документів у
                ЦНАП діти не вважаються зареєстрованими в загальному міському електронному
                реєстрі та заявка батьків буде відхилена.
            </p>
        </Message.Content>
    </Message>
);