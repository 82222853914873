import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import axios from 'axios';
import { startRedirect } from './actions/logout';
import { loadState, saveState } from './state';
import Toasts from './components/helpers/Toasts';
import { CLIENT_VERSION, CLIENT_VERSION_HEADER } from './utils/settings';

let middleware = [thunkMiddleware];

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    middleware = [...middleware];
}

const store = createStore(
    rootReducer,
    loadState(),
    applyMiddleware(
        ...middleware
    )
);

axios.interceptors.response.use(
    (response) => {
        const clientVersion = response.headers[CLIENT_VERSION_HEADER];
        if (clientVersion && clientVersion !== CLIENT_VERSION) {
            console.log(`New version available ${CLIENT_VERSION} != ${clientVersion}, reloading...`);
            window.location.reload(true);
        }
        return Promise.resolve(response);
    },
    (error) => {
        if (error.response) {
            const res = error.response.data || error.response;
            const { status, errorCode } = res;

            if (status === 403 || status === 401) {
                if (window.location.hash !== '#/login') {
                    store.dispatch(startRedirect());
                }
            } else if (!errorCode) {
                Toasts('error', 'Помилка серверу');
            }
        }

        return Promise.reject(error);
    });

store.subscribe(() => {
    const { accountDetails, tour } = store.getState();
    saveState({
        accountDetails,
        tour,
    });
});

export default store;