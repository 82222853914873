// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
// libs
import PropTypes from 'prop-types';
// semantic
import { Button, Header, Modal } from 'semantic-ui-react';
// actions
import foodPrivilegeEnrollmentActions from '../../../actions/food-privilege-enrollment';
import LargeDimmer from '../../helpers/Dimmer';

class DeleteFoodPrivilegeDialog extends Component {

    componentDidUpdate(prevProps) {
        const { isDeleted } = this.props;

        if (isDeleted && !prevProps.isDeleted) {
            this.onComplete();
        }
    }

    reset = () => {
        this.props.dispatch(foodPrivilegeEnrollmentActions.reset());
    };

    onComplete = () => {
        this.reset();
        this.props.onComplete();
    };

    onCancel = () => {
        this.reset();
        this.props.onClose();
    };

    handleSubmitClick = () => {
        const { enrollmentPrivilege } = this.props;
        this.props.dispatch(foodPrivilegeEnrollmentActions.delete(enrollmentPrivilege.id));
    };

    render() {

        const { isOpened, enrollmentPrivilege, isDeleting } = this.props;

        if (!enrollmentPrivilege) {
            return null;
        }

        const { enrollment, privilege } = enrollmentPrivilege;

        return (
            <Modal size='small' open={isOpened} centered={false}>
                {isDeleting && <LargeDimmer text='Збереження даних'/>}
                <Modal.Header content='Видалити пільгу на харчування'/>
                <Modal.Content>
                    <Header as='h5'>
                        {enrollment.lastName + ' ' + enrollment.firstName + ' ' + enrollment.middleName}
                    </Header>
                    <Header as='h5'>
                        {privilege.name}
                    </Header>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.handleSubmitClick}
                        content='Видалити'
                        icon='plus'
                        labelPosition='left'
                        primary
                    />
                    <Button
                        onClick={this.onCancel}
                        content='Відміна'
                        icon='plus'
                        labelPosition='left'
                        secondary
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}

DeleteFoodPrivilegeDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    enrollmentPrivilege: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return state.foodPrivilegeEnrollment;
}

export default connect(mapStateToProps)(DeleteFoodPrivilegeDialog);