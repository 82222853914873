import {
    COMPLETE_GET_PRIVILEGES,
    FAIL_GET_PRIVILEGES,
    START_GET_PRIVILEGES,
} from '../../actions/child/fetchPrivileges';

const initialState = {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    privileges : [],
};

export default function fetchPrivileges(state = initialState, action) {

    switch (action.type) {
    case START_GET_PRIVILEGES:
        return {
            ...state,
            isFetching: true,
            isFetched: false,
            isFailed: false,
            privileges : [],
        };
    case COMPLETE_GET_PRIVILEGES:
        return {
            ...state,
            isFetching: false,
            isFetched: true,
            isFailed: false,
            privileges : action.privileges,
        };
    case FAIL_GET_PRIVILEGES:
        return {
            ...state,
            isFetching: false,
            isFetched: false,
            isFailed: true,
            privileges : [],
        };
    default:
        return state;
    }
}