import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Container, Header, Icon, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import EventListComponent from './event/EventListComponent';
import t from '../services/Translate';

import { SERVICE_TYPE } from '../utils/settings';

const backgrounds = {
    school: 'url(img/bg-main-school.png)',
    kindergarten: 'url(img/bg-main-kindergarten.jpg)',
    gurtok: 'url(img/bg-main-gurtok.jpg)',
};

const background = backgrounds[SERVICE_TYPE];

const welcomeStyle = {
    backgroundImage: background,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'scroll',
    margin: '-14px',
};

class Welcome extends Component {

    render() {
        return (
            <>
                <Container fluid style={welcomeStyle} textAlign='center'>
                    <Header
                        inverted
                        as='h1'
                        style={{ fontSize: '7vh' }}>{t('landingTitle')}</Header>
                    <Header
                        inverted
                        as='h1'>З використанням технології блокчейн</Header>
                    <Segment basic style={{ height: '300px', paddingTop: '100px' }}>
                        <Button
                            id='register'
                            primary
                            as={Link}
                            to='/register'
                            size='huge'>Зареєструватися</Button>
                        <Header as='h6' inverted content='Beta-версія (дослідна експлуатація)'/>
                    </Segment>
                </Container>
                <Container>
                    <Segment textAlign='left'>
                        <Header as='h2'>Чесна черга, яку не можна підробити - як це працює?</Header>
                        <List>
                            <List.Item>
                                <Icon name='star outline'/>
                                <List.Content>
                                    <List.Header>
                                        Що таке блокчейн?
                                    </List.Header>
                                    <List.Description>
                                        Блокчейн - це технологія, яка унеможливлює фальсифікацію даних
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <Icon name='star outline'/>
                                <List.Content>
                                    <List.Header>
                                        Для чого використовується технологія?
                                    </List.Header>
                                    <List.Description>
                                        Ми використовуємо технологію блокчейн для виключення можливості маніпуляцій
                                        чергою дітей
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <Icon name='star outline'/>
                                <List.Content>
                                    <List.Header>
                                        Як це працює?
                                    </List.Header>
                                    <List.Description>
                                        Всі дані про заявки на вступ зберігаються в розподіленому
                                        <a
                                            href='http://node1.bloqly.com' target='_blank'
                                            rel='noopener noreferrer'> реєстрі</a>
                                        , який не може бути підроблений
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                        <br/>
                    </Segment>
                    <EventListComponent/>
                </Container>
            </>
        );
    }
}

Welcome.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state;
}

export default connect(mapStateToProps)(Welcome);