export enum RoleType {
    USER= 'USER',
    REGION_ADMIN = 'REGION_ADMIN',
    LOCATION_ADMIN = 'LOCATION_ADMIN',
    EDITOR = 'EDITOR',
    GROUP_ADMIN = 'GROUP_ADMIN',
}

export interface Account {
    readonly uuid: string,
    readonly username: string,
    readonly created: number,
    readonly enabled: boolean,
    readonly role: RoleType,
}