import { getResponseData } from '../utils';
import axios from 'axios';
import { REQUEST_SETTINGS } from '../../utils/settings';

export const UPDATE_ENROLLMENT_REQUEST = 'UPDATE_ENROLLMENT_REQUEST';
export const UPDATE_ENROLLMENT_SUCCESS = 'UPDATE_ENROLLMENT_SUCCESS';
export const UPDATE_ENROLLMENT_FAILURE = 'UPDATE_ENROLLMENT_FAILURE';
export const UPDATE_ENROLLMENT_RESET = 'UPDATE_ENROLLMENT_RESET';

function updateEnrollmentRequest() {
    return { type: UPDATE_ENROLLMENT_REQUEST };
}

function updateEnrollmentSuccess() {
    return { type: UPDATE_ENROLLMENT_SUCCESS };
}

function updateEnrollmentFailure(error) {

    const { errorCode } = getResponseData(error);

    return { type: UPDATE_ENROLLMENT_FAILURE, errorCode };
}

export function doUpdateEnrollment(enrollment) {

    return async function (dispatch) {

        dispatch(updateEnrollmentRequest(enrollment));

        return axios
            .put('/api/enrollments/status', enrollment, REQUEST_SETTINGS)
            .then(() => dispatch(updateEnrollmentSuccess()))
            .catch(error => dispatch(updateEnrollmentFailure(error)));
    };
}

export function doResetEnrollment() {
    return { type: UPDATE_ENROLLMENT_RESET };
}