// react
import React, { Component } from 'react';
// libs
import Toast from '../helpers/Toasts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// actions
import { doAddChild, resetAddChild } from '../../actions/child/createChild';
// components
import ChildFormComponent from './ChildFormComponent';
import LargeDimmer from '../helpers/Dimmer';
import { Redirect } from 'react-router-dom';
import { DEFAULT_CHILD_STATE } from '.';

class ChildCreate extends Component {

    state = { ...DEFAULT_CHILD_STATE };

    componentWillUnmount() {
        this.props.dispatch(resetAddChild());
    }

    componentDidUpdate(prevProps) {

        const { isCreated } = this.props;

        if (isCreated && !prevProps.isCreated) {
            Toast('success', 'Дитина успішно додана! Наступним кроком додайте, будь ласка, документи дитини.');
        }
    }

    onChildCreate = () => {
        this.props.dispatch(doAddChild({ ...this.state }));
    };

    onDateChange = birthDate => {
        this.setState({ birthDate });
    };

    onChange = (event, { name, value }) => {
        event.preventDefault();
        this.setState({ [name]: value });
    };

    render() {
        const { isCreated, isCreating, child } = this.props;

        if (isCreated) {
            return (<Redirect to={`/children/${child.uuid}`}/>);
        }

        if (isCreating) {
            return (<LargeDimmer text='Збереження даних'/>);
        }

        return (
            <ChildFormComponent
                headerText='Додати дитину'
                submitButtonText='Додати'
                onSubmit={this.onChildCreate}
                onDateChange={this.onDateChange}
                onChange={this.onChange}
                requirePersonalData={true}
                value={{ ...this.state }}
                {...this.props}
            />
        );
    }
}

ChildCreate.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return state.createChild;
}

export default connect(mapStateToProps)(ChildCreate);