import axios from 'axios';
import { createGetParams, getResponseData } from '../utils';


export const FETCH_REGIONS_PAGINATED_REQUEST = 'FETCH_REGIONS_PAGINATED_REQUEST';
export const FETCH_REGIONS_PAGINATED_SUCCESS = 'FETCH_REGIONS_PAGINATED_SUCCESS';
export const FETCH_REGIONS_PAGINATED_FAILURE = 'FETCH_REGIONS_PAGINATED_FAILURE';

export function fetchRegionsPaginated() {

    return async function (dispatch) {

        dispatch(fetchRegionsPaginatedRequest());

        const request = { page: 1, limit: 100, query: '' };

        return axios
            .get('/api/regions', createGetParams(request))
            .then(res => dispatch(fetchRegionsPaginatedSuccess(res)))
            .catch(res => dispatch(fetchRegionsPaginatedFailure(res)));
    };

    function fetchRegionsPaginatedRequest() {
        return { type: FETCH_REGIONS_PAGINATED_REQUEST };
    }

    function fetchRegionsPaginatedSuccess(res) {
        const payload = res.data;
        return {
            type: FETCH_REGIONS_PAGINATED_SUCCESS, payload
        };
    }

    function fetchRegionsPaginatedFailure(res) {

        const { errorCode } = getResponseData(res);

        return { type: FETCH_REGIONS_PAGINATED_FAILURE, errorCode };
    }
}
