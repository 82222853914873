import { Label, Popup } from 'semantic-ui-react';
import React from 'react';

const Stats = ({ regularCount, privilegedCount, regularText, privilegedText }) => {

    const regularCountNormalized = regularCount || 0;
    const privilegedCountNormalized = privilegedCount || 0;

    return (
        (
            <Label.Group>

                <Popup trigger={
                    <Label as='a' size='medium'>{regularCountNormalized + privilegedCountNormalized}</Label>
                } content={regularText}/>

                {!!privilegedCount &&
                <Popup trigger={
                    <Label as='a' color='green'>{privilegedCountNormalized}</Label>
                } content={privilegedText}/>
                }

            </Label.Group>
        )
    );
};

export default Stats;