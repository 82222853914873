import axios from 'axios';
import { createGetParams, getResponseData } from './utils';
import _ from 'lodash';
import { loadAccountDetails } from './accountDetails';

export const START_UPDATE_USER_INFO = 'START_UPDATE_USER_INFO';
export const COMPLETE_UPDATE_USER_INFO = 'COMPLETE_UPDATE_USER_INFO';
export const FAIL_UPDATE_USER_INFO = 'FAIL_UPDATE_USER_INFO';
export const START_GET_USER_INFO = 'START_GET_USER_INFO';
export const COMPLETE_GET_USER_INFO = 'COMPLETE_GET_USER_INFO';
export const FAIL_GET_USER_INFO = 'FAIL_GET_USER_INFO';

function startUpdateUserInfo() {
    return { type: START_UPDATE_USER_INFO };
}

function completeUpdateUserInfo(res) {
    return { type: COMPLETE_UPDATE_USER_INFO, userInfoData: res.data };
}

function failUpdateUserInfo(res) {

    const { errorCode, fieldErrors } = getResponseData(res);

    return { type: FAIL_UPDATE_USER_INFO, errorCode, fieldErrors };
}

export function doUpdateUserInfo({ regionId, firstName, middleName, lastName, address, phoneNumber, additionalPhoneNumber }) {

    const userInfo = { regionId, firstName, middleName, lastName, address, phoneNumber, additionalPhoneNumber };

    return async function (dispatch) {

        dispatch(startUpdateUserInfo());

        return axios
            .post('/api/account/info', _.pickBy(userInfo, _.identity), createGetParams())
            .then((res) => {
                dispatch(completeUpdateUserInfo(res));
                dispatch(loadAccountDetails());
            })
            .catch(res => dispatch(failUpdateUserInfo(res)));
    };
}

function startGetUserInfo() {
    return { type: START_GET_USER_INFO };
}

function completeGetUserInfo(res) {

    return { type: COMPLETE_GET_USER_INFO, userInfoData: res.data };
}

function failGetUserInfo(res) {

    const { errorCode } = getResponseData(res);

    return { type: FAIL_GET_USER_INFO, errorCode };
}

export function doGetUserInfo() {

    return async function (dispatch) {

        dispatch(startGetUserInfo());

        return axios
            .get('/api/account/info', createGetParams())
            .then(res => dispatch(completeGetUserInfo(res)))
            .catch(res => dispatch(failGetUserInfo(res)));
    };
}