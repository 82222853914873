import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Divider, Grid, Header, Icon, } from 'semantic-ui-react';
import { doListChildren } from '../../actions/child/listChildren';
import LargeDimmer from '../helpers/Dimmer';

class Children extends Component {

    fetchChildren = () => {
        this.props.dispatch(doListChildren());
    };

    componentDidMount() {
        this.fetchChildren();
    }

    // TODO what?
    isCopy = child => child.documentId && child.documentId.includes('—bx');

    renderChild = child => {
        return (
            <Card fluid key={child.uuid}>
                <Card.Content>
                    <Card.Header>
                        <Header as='h2'>
                            {child.lastName} {child.firstName} {child.middleName}
                            {this.isCopy(child) && ' (копія запису)'}
                        </Header>
                    </Card.Header>
                    <Card.Meta>
                        <span className='date'>{child.birthDate}</span>
                    </Card.Meta>
                    <Card.Description>{child.address}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <div>
                        <Button
                            id={child.documentId}
                            icon
                            size='small'
                            labelPosition='right'
                            as={Link}
                            to={`/children/${child.uuid}`}>
                            Редагувати
                            <Icon name='edit'/>
                        </Button>
                    </div>
                </Card.Content>
            </Card>
        );
    };

    render() {

        const { children, maxEnrollments, isFetching } = this.props;

        return (
            <>
                {isFetching &&
                <LargeDimmer text='Завантаження даних'/>
                }

                <Grid centered>
                    <Grid.Column computer={12} tablet={12} mobile={16}>

                        <Header as='h1'>Діти</Header>

                        <Button
                            as={Link}
                            to='/children/create'
                            color='green'
                            content='Додати дитину'
                            icon='add'
                            labelPosition='left'
                        />

                        <p><Icon name='info circle' color='blue'/>Максимальна кількість заявок для однієї
                            дитини: <strong>{maxEnrollments}</strong></p>

                        <Divider clearing/>

                        {children
                            .filter(child => !this.isCopy(child))
                            .map(this.renderChild)}

                        {children
                            .filter(child => this.isCopy(child))
                            .map(this.renderChild)}

                    </Grid.Column>
                </Grid>
            </>
        );
    }
}

Children.propTypes = {
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        ...state.listChildren,
        ...state.accountDetails,
    };
}

export default connect(mapStateToProps)(Children);